import { Box } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  Record,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import { GenericFilterComponent } from '../../../lib/custom-useful-components/GenericFilterComponent';
import { Empty } from '../../../lib/mui_lib/empty';
import { ButtonField } from '../../../lib/react-admin_lib/button-field';
import { EditableStatusView } from '../../../resources/grid-connection-points/status-management-components/EditableStatusView';
enum PqTestsEditableStatus {
  ReadyForTests = 'ReadyForTests',
}
export const PqTests = () => {
  const translate = useTranslate();
  const tFP = 'resources.uvam.tabs.pq_tests';
  const history = useHistory();

  const record = useRecordContext();
  const handleOnClickAddNewPqtest = (record: Record) => {
    history.push({
      pathname: '/pq-tests/create',
      state: {
        UvamId: record.id,
        maximumQualifiedPower: record.maximumQualifiedPower,
        minimumQualifiedPower: record.minimumQualifiedPower,
      },
    });
  };

  return (
    <>
      <Box mb={2}>
        <Box display="flex" justifyContent="right">
          <EditableStatusView editableStatus={PqTestsEditableStatus}>
            <Box display="flex" flexDirection="row">
              <ButtonField
                label={translate(`${tFP}.create`)}
                onClick={handleOnClickAddNewPqtest}
                startIcon={<Add />}
              />
              <Box pl={1} />
            </Box>
          </EditableStatusView>
        </Box>

        <GenericFilterComponent
          filter={() => record.pqTests?.length > 0}
          empty={<Empty resource={translate(`${tFP}.name`)} />}
        >
          <ArrayField
            source="pqTests"
            sortBy="testStartDate"
            sortByOrder="ASC"
            sortable
          >
            <Datagrid>
              <FunctionField
                source="testType"
                label={translate(`${tFP}.fields.testType.name`)}
                render={(r: Record | undefined) =>
                  translate(`${tFP}.testType.${r!.testType.toUpperCase()}`)
                }
              />
              <FunctionField
                source="testDirection"
                label={translate(`${tFP}.fields.testDirection.name`)}
                render={(r: Record | undefined) =>
                  translate(
                    `${tFP}.testDirection.${r!.testDirection.toUpperCase()}`
                  )
                }
              />
              <TextField
                source="testPower"
                label={translate(`${tFP}.fields.testPower`)}
              />
              <TextField
                source="testActivationTime"
                label={translate(`${tFP}.fields.testActivationTime`)}
              />
              <DateField
                source="testStartDate"
                showTime
                label={translate(`${tFP}.fields.testStartDate`)}
              />
              <FunctionField
                source="currentStatus"
                render={(r: Record | undefined) =>
                  translate(`${tFP}.status.${r!.currentStatus}`)
                }
              />
            </Datagrid>
          </ArrayField>
        </GenericFilterComponent>
      </Box>
    </>
  );
};
