
import { Button } from '@material-ui/core';
import ContentAdd from '@material-ui/icons/Add';
import {
  BooleanField,
  Datagrid,
  DateField,
  Link,
  List,
  ReferenceField,
  ReferenceInput,
  ResourceComponentProps,
  SelectInput,
  TextField,
  useTranslate
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { EditDeleteButtons } from 'src/lib/react-admin_lib/EditDeleteButtons';

const ActionButtons = () => {
  const translate = useTranslate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const filter = JSON.parse(queryParams.get('filter') || '{}');
  const gcpId = filter.gridConnectionPoint;
  const tFP = 'resources.grid-connection-point-force-availabilities';
  return(
    <Button
        style={{
          marginTop: "20px",
          marginLeft: "8px"
        }}
        color="primary"
        component={Link}
        startIcon={<ContentAdd />}
        to={{
            pathname: '/grid-connection-point-force-availabilities/create',
            state: { record: { gridConnectionPoint: gcpId } },
        }}
    >
        {translate(`${tFP}.create`)}
    </Button>
  );
};

export const GridConnectionPointForceAvailabilityList = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.grid-connection-point-force-availabilities';
  const filters = [
    <ReferenceInput
      reference="products"
      source="product"
      link="show"
      alwaysOn
      variant="standard"
    >
      <SelectInput
        optionText={(record) => `${record.id} - ${record.productCode} - ${record.type}`}
        optionValue="id"
        style={{ width: '200px' }}
      />
    </ReferenceInput>
  ];
  return (
    <List {...props} exporter={false} bulkActionButtons={false} actions={<ActionButtons />} filters={filters}>
        <Datagrid rowClick="show">
          <ReferenceField
            label={translate(`${tFP}.fields.productCode`)}
            source="product"
            reference="products"
            link={false}
          >
            <TextField
              source="productCode"
              emptyText="-"
              label={translate(`${tFP}.fields.productCode`)}
            />
          </ReferenceField>
          <DateField source="startDate" />
          <DateField source="endDate" />
          <BooleanField source="availability" />
          <EditDeleteButtons remove={true}/>
        </Datagrid>
    </List>
  );
};
