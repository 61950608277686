import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Button as MuiButton,
  Typography,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DownloadIcon from '@material-ui/icons/Download';
import EditIcon from '@material-ui/icons/Edit';
import fileDownload from 'js-file-download';
import React from 'react';
import {
  FunctionField,
  Labeled,
  Record,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { useAxios } from '../../../lib/custom-hooks/useAxios';
import { FileUploader } from '../../../lib/mui_lib/file-uploader';
import Modal from '../../../lib/mui_lib/modal';
import { EditableStatusView } from '../../../resources/grid-connection-points/status-management-components/EditableStatusView';

interface IAttachmentPopUp {
  label: string;
  type?: 0 | 1;
  edit?: boolean;
  startIcon?: React.ReactNode;
  notify?: ReturnType<typeof useNotify>
}

const AttachmentPopUp = (props: IAttachmentPopUp) => {

  const record = useRecordContext();
  const translate = useTranslate();
  const { refetch } = useAxios();
  const refresh = useRefresh();
  const [excelFile, setExelFile] = React.useState('');
  const [uploadLoading, setUploadLoading] = React.useState(false);
  const tFP = 'resources.uvam.tabs.attachments.upload_pop_up';
  const staticSelectDocumentFileType = [
    {
      id: 'technical_report_document',
      name: translate(`${tFP}.technical_report_document`),
    },
  ];
  const [uploadModal, setUploadModal] = React.useState(false);
  const handleFileChange = (file: string) => {
    setExelFile(file);
  };

  const handleUploadFile = async () => {
    const formData = new FormData();
    formData.append('file', excelFile);

    formData.append(
      'documentType',
      staticSelectDocumentFileType[props.type ? props.type : 0].id
    );
    setUploadLoading(true);
    const res = await refetch({
      url: `/uvam/${record.id}/set-document`,
      method: 'post',
      headers: { 'content-type': 'multipart/form-data' },
      data: formData,
    });

    if (res) {
      const { result, error } = res;
      if (result) {
        setUploadModal(false);
      }
      if (error){ 
        props.notify && props.notify((error as any).message, 'error', null, false, 30000)
      };
      setUploadLoading(false);
      refresh();
   
    }
  };
  return (
    <Modal
      startIcon={props.startIcon ? props.startIcon : <CloudUploadIcon />}
      name={props.label}
      open={uploadModal}
      setOpen={setUploadModal}
      variant="text"
      onCloseIcon={() => setExelFile('')}
      buttonSize="small"
    >
      <>
        <CloudUploadIcon fontSize="large" color="disabled" />
        <Typography variant="h5" color="textPrimary">
          {translate(`${tFP}.upload_file`)}
        </Typography>
        <Typography variant="caption">{props.label}</Typography>
        <Box pt={3}>
          <Divider />
          <Box pt={1}>
            <Typography variant="caption" color="textSecondary">
              <b>{translate(`${tFP}.instructions`)}</b>:{' '}
              {translate(`${tFP}.instructions_des`, {
                documentName: props.label,
              })}
            </Typography>
          </Box>
          <Box pt={1} pb={2}>
            <FileUploader
              onChange={handleFileChange}
              value={excelFile}
              label={translate(`${tFP}.sel_file`)}
              accept={'file_extension'}
            />
          </Box>

          <Divider />
        </Box>
        <Box mt={2} justifyContent="right">
          <MuiButton
            variant="contained"
            color="primary"
            startIcon={
              uploadLoading ? (
                <CircularProgress size={20} />
              ) : (
                <CloudUploadIcon />
              )
            }
            onClick={handleUploadFile}
            disabled={!excelFile || uploadLoading}
          >
            {translate(`${tFP}.upload_file`)}
          </MuiButton>
        </Box>
      </>
    </Modal>
  );
};

export const Attachments = () => {
  const translate = useTranslate();
  const tFP = 'resources.uvam.tabs.attachments';
  const { refetch } = useAxios();
  const notify = useNotify();
  const handleDownloadFile = async (record: Record) => {
    const res = await refetch({
      url: `/uvam/${record.id}/technical-report-document`,
      method: 'get',
      responseType: 'arraybuffer',
    });
    if (res) {
      const { result, error } = res;
      if (result) {
        fileDownload(
          result.data,
          result.headers['content-disposition'].split('=')[1]
        );
      }
      if (error) {
        notify('error', 'error');
      }
    }
  };
  return (
    <>
      <Box mt="2" mb={2}>
        <Labeled
          label={translate(`${tFP}.upload_pop_up.technical_report_document`)}
          fullWidth={true}
        >
          <>
            <FunctionField
              source="currentStatus"
              render={(r: Record | undefined) =>
                r!.technicalReportDocument ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexDirection="row"
                  >
                    <Button
                      color="primary"
                      endIcon={<DownloadIcon />}
                      size="small"
                      variant="text"
                      onClick={() => handleDownloadFile(r!)}
                    >
                      {r!.technicalReportDocument.filename}
                    </Button>
                    <Box pl={4} alignItems="right">
                      <EditableStatusView>
                        <AttachmentPopUp
                          label={translate(
                            `${tFP}.upload_pop_up.technical_report_document`
                          )}
                          startIcon={<EditIcon fontSize="small" />}
                          type={0}
                          notify={notify}
                          edit
                        />
                      </EditableStatusView>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexDirection="row"
                  >
                    <Typography color="textPrimary" variant="body2">
                      {translate(`${tFP}.no_file_uploaded`)}
                    </Typography>
                    <EditableStatusView>
                      <AttachmentPopUp
                        label={translate(
                          `${tFP}.upload_pop_up.technical_report_document`
                        )}
                        type={0}
                        notify={notify}
                      />
                    </EditableStatusView>
                  </Box>
                )
              }
            />
          </>
        </Labeled>
      </Box>
    </>
  );
};
