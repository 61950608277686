import { makeStyles } from '@material-ui/core';
import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  Record,
  ReferenceField,
  ReferenceInput,
  ResourceComponentProps,
  SelectInput,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin';
import { ShowEditButtons } from '../../lib/react-admin_lib/ShowEditButtons';
import { EditableStatus } from '../grid-connection-points/status-management-components/EditableStatusView';

const useStyles = makeStyles({
  chipfieldColor: {
    color: '#ffffff',
  },
  fieldHeaderAlign: {
    textAlign: 'right',
  },
  fieldHeaderAlignLeft: {
    textAlign: 'left',
  },
});

export const UvamList = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.uvam';
  const classes = useStyles();

  const filters = [
    <TextInput source="upCode" alwaysOn resettable variant="standard" />,
    <TextInput
      placeholder={translate(`${tFP}.fields.exergyId`)}
      source="exergyId"
      style={{ width: '200px' }}
      alwaysOn
      resettable
      variant="standard"
    />,
    <SelectInput
      label={translate(`${tFP}.fields.currentStatus`)}
      source="currentStatus"
      variant="standard"
      style={{ width: '230px' }}
      alwaysOn
      choices={[
        'None',
        'Registered',
        'Open',
        'Closed',
        'Rejected',
        'UnderReview',
        'PreValidated',
        'Configuration',
        'Validated',
        'ReadyForTests',
        'Qualifiable',
        'Qualified',
      ].map((status) => {
        return { id: status, name: translate(`${tFP}.status.${status}`) };
      })}
    />,
    <ReferenceInput
      label={translate(`${tFP}.fields.scWebOperatorName`)}
      source="scWebOperator"
      reference="sc-web-operator"
      alwaysOn
      variant="standard"
    >
      <SelectInput
        optionText="name"
        optionValue="id"
        style={{ width: '230px' }}
      />
    </ReferenceInput>,
    <ReferenceInput
      reference="bsp"
      source="bsp"
      link="show"
      alwaysOn
      variant="standard"
    >
      <SelectInput
        optionText="name"
        optionValue="id"
        style={{ width: '230px' }}
      />
    </ReferenceInput>,
  ];

  return (
    <List
      {...props}
      filters={filters}
      exporter={false}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="show">
        <TextField source="upCode" />
        <TextField source="name" />
        <TextField source="exergyId" />
        <TextField source="minimumQualifiedPower" />
        <TextField source="maximumQualifiedPower" />
        <TextField source="scWebOperatorName" />
        <DateField source="createdAt" />
        <FunctionField
          source="currentStatus"
          render={(r: Record | undefined) =>
            translate(`${tFP}.status.${r!.currentStatus}`)
          }
          sortable={false}
        />

        <ReferenceField reference="bsp" source="bsp" link="show">
          <ChipField
            source="name"
            size="small"
            color="primary"
            className={classes.chipfieldColor}
            clickable={true}
          />
        </ReferenceField>
        <ShowEditButtons editableStatus={Object.values(EditableStatus)} />
      </Datagrid>
    </List>
  );
};
