import { config } from '@config/config';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import {
  Title,
  useGetIdentity,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import { useAxios } from '../../lib/custom-hooks/useAxios';
import { useUserHasPermissions } from '../../lib/custom-hooks/useUserHasPermissions';
import Modal from '../../lib/mui_lib/modal';
import { TextFieldValdation } from '../../lib/mui_lib/text-field';
import { httpClient } from '../../providers/dataProvider';

const useStyles = makeStyles((theme: any) => ({
  table: {
    minWidth: 650,
  },
  formControl: {
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  linearProgress: {
    width: 180,
  },
  deleteIcon: {
    color: theme.palette.error.light,
    fontSize: 20,
  },
  deleteIconButton: {
    padding: 5,
    margin: 0,
  },
  deleteButton: {
    backgroundColor: theme.palette.error.light,
  },
}));

const DeleteUserModal = (props: {
  row: any;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { row, modalOpen, setModalOpen } = props;
  const { loaded, identity } = useGetIdentity();
  const history = useHistory();
  const classes = useStyles();
  const record = useRecordContext();
  const translate = useTranslate();
  const tFP = 'resources.companies.tabs.users_tab';
  const [loading, setLoading] = React.useState(false);

  const notify = useNotify();
  const refresh = useRefresh();
  const handelModalCloseIcon = () => {
    setModalOpen(false);
  };
  const handleUserDelete = () => {
    setLoading(true);
    httpClient(`${config.dataConfig.api}/companies/${record.id}/members/`, {
      method: 'DELETE',
      body: JSON.stringify({
        email: row.email,
      }),
    })
      .then((data) => {
        setLoading(false);
        refresh();
        notify(`${tFP}.user_removed`, 'success');
        handelModalCloseIcon();
        if (loaded && identity?.email === row.email) {
          history.push('/companies');
          window.location.reload();
        }
      })
      .catch((error) => {
        setLoading(false);
        notify(`error: ${error.message}`, 'error');
        handelModalCloseIcon();
      });
  };

  return (
    <Modal
      size="small"
      color="primary"
      name={translate(`${tFP}.remove`)}
      open={modalOpen}
      setOpen={setModalOpen}
      onCloseIcon={handelModalCloseIcon}
      withOutButton={true}
    >
      <>
        <Box mb={2} mt={2}>
          {translate(`${tFP}.remove_sure`, { email: row.email })}
        </Box>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <Button
            variant="contained"
            onClick={handleUserDelete}
            size="small"
            className={classes.deleteButton}
          >
            {translate(`${tFP}.remove`)}
          </Button>
        )}
      </>
    </Modal>
  );
};

export const AddUserModal = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const { refetch } = useAxios();
  const tFP = 'resources.companies.tabs.users_tab';
  const staticSelectFileType: { id: string; name: string }[] = [
    { id: 'manager', name: translate(`${tFP}.manager`) },
    { id: 'production-unit', name: translate(`${tFP}.production_unit`) },
    { id: 'read-unit', name: translate(`${tFP}.read_unit`) },
    { id: 'invoice', name: translate(`${tFP}.invoice`) },
  ];
  const classes = useStyles();
  const [selectFileType, setSelectFileType] = React.useState(
    staticSelectFileType[2].id
  );
  const [loading, setLoading] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const permissions = useUserHasPermissions('admin');
  const [email, setEmail] = React.useState<{ value: string; error: boolean }>({
    value: '',
    error: false,
  });

  const notify = useNotify();
  const refresh = useRefresh();
  const handleSelectFileTypeChange = (e: any) => {
    setSelectFileType(e.target.value);
  };
  const handelModalCloseIcon = () => {};
  const handleAddUser = async () => {
    setLoading(true);
    const res = await refetch({
      url: `${config.dataConfig.api}/companies/${record.id}/members/${selectFileType}`,
      method: 'post',
      data: { email: email.value },
    });
    if (res) {
      const { result, error, loading } = res;
      if (result?.data) {
        setLoading(loading);
        refresh();
        notify(`${tFP}.user_added`, 'success');
        setModalOpen(false);
      }
      if (error) {
        setLoading(loading);
        notify('error', 'error');
        setModalOpen(false);
      }
    }
  };

  return (
    <Modal
      size="small"
      color="primary"
      name={translate(`${tFP}.add_new_user`)}
      startIcon={<AddIcon />}
      open={modalOpen}
      setOpen={setModalOpen}
      onCloseIcon={handelModalCloseIcon}
    >
      <>
        <Box mb={2} mt={2}>
          <TextFieldValdation
            name={translate(`${tFP}.email`)}
            type="email"
            label={translate(`${tFP}.email`)}
            onChange={setEmail}
            requried
          />
        </Box>

        <Box mb={2}>
          <FormControl className={classes.formControl}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectFileType}
              onChange={handleSelectFileTypeChange}
            >
              {staticSelectFileType.map((value, index) => {
                return !permissions &&
                  value.id === staticSelectFileType[0].id ? (
                  <MenuItem key={index} value={value.id}>
                    {value.name}
                  </MenuItem>
                ) : (
                  <MenuItem key={index} value={value.id}>
                    {value.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <Button
            variant="contained"
            onClick={handleAddUser}
            size="small"
            color="primary"
            disabled={email.value ? email.error : true}
          >
            {translate(`${tFP}.add_user`)}
          </Button>
        )}
      </>
    </Modal>
  );
};

const UserRow = (props: any) => {
  const { row, record, refresh } = props;
  const classes = useStyles();
  const [selectFileType, setSelectFileType] = React.useState(row.type);
  const permissions = useUserHasPermissions('admin');
  const [loading, setLoading] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const translate = useTranslate();
  const tFP = 'resources.companies.tabs.users_tab';
  const staticSelectFileType: { id: string; name: string }[] = [
    { id: 'manager', name: translate(`${tFP}.manager`) },
    { id: 'production-unit', name: translate(`${tFP}.production_unit`) },
    { id: 'read-unit', name: translate(`${tFP}.read_unit`) },
    { id: 'invoice', name: translate(`${tFP}.invoice`) },
  ];
  const notify = useNotify();
  const handleSelectFileTypeChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setSelectFileType(e.target.value);
    setLoading(true);
    httpClient(
      `${config.dataConfig.api}/companies/${record.id}/members/${e.target.value}`,
      {
        method: 'POST',
        body: JSON.stringify({
          email: row.email,
        }),
      }
    )
      .then((data) => {
        setLoading(false);
        refresh();
        notify(`${tFP}.user_updated`, 'success');
      })
      .catch((error) => {
        setLoading(false);
        notify(`error: ${error.message}`, 'error');
      });
  };

  const handleUserDelete = () => {
    setDeleteModalOpen(true);
  };

  React.useEffect(() => {
    setSelectFileType(row.type);
  }, [record, row, selectFileType]);
  return (
    <>
      <TableRow>
        <TableCell align="left">{row.username}</TableCell>

        <TableCell align="left">
          {loading ? (
            <Box p={1.5}>
              <LinearProgress className={classes.linearProgress} />
            </Box>
          ) : (
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectFileType}
                onChange={handleSelectFileTypeChange}
                // defaultValue={defaultValue}
              >
                {staticSelectFileType.map((value, index) => {
                  return !permissions &&
                    value.id === staticSelectFileType[0].id ? (
                    <MenuItem key={index} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ) : (
                    <MenuItem key={index} value={value.id}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </TableCell>
        <TableCell align="left">
          <IconButton
            className={classes.deleteIconButton}
            onClick={handleUserDelete}
          >
            <DeleteIcon className={classes.deleteIcon} fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
      <DeleteUserModal
        row={row}
        modalOpen={deleteModalOpen}
        setModalOpen={setDeleteModalOpen}
      />
    </>
  );
};

export const UsersTab = ({ source }: any) => {
  const classes = useStyles();
  const record = useRecordContext();
  const [users, setUsers] = React.useState([]);
  const refresh = useRefresh();
  const translate = useTranslate();
  const tFP = 'resources.companies.tabs.users_tab';

  React.useEffect(() => {
    const newUsersArray = () => {
      if (record[source].manager) {
        const allUsersValues: any = Object.values(record[source]);
        const allUserTypes: string[] = Object.keys(record[source]);
        const allUsersArrays = allUsersValues.map(
          (array: any[], index: number) => {
            const newarray = array.map((user: any) => {
              return { type: allUserTypes[index], ...user };
            });
            return newarray;
          }
        );
        const allUsersArray = allUsersArrays.reduce((arr: any, e: any) => {
          return arr.concat(e);
        });
        setUsers(allUsersArray);
      }
    };
    newUsersArray();
  }, [source, record]);
  return (
    <>
      <Title title={translate(`${tFP}.name`)} />
      <Box pt={2} mb={2}>
        <TableContainer component={Paper} elevation={0}>
          {users.length > 0 && (
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    {translate(`${tFP}.email`)}
                  </TableCell>
                  <TableCell align="left">{translate(`${tFP}.role`)}</TableCell>
                  <TableCell align="left">
                    {translate(`${tFP}.remove`)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((row: any, index: number) => (
                  <UserRow
                    row={row}
                    index={index}
                    record={record}
                    refresh={refresh}
                    key={index}
                  />
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Box>
    </>
  );
};
