import { makeStyles } from '@material-ui/core';
import {
  ChipField,
  Datagrid,
  FunctionField,
  List,
  ListProps,
  Record,
  ReferenceField,
  TextField,
  TextInput,
  useLocale,
  useTranslate,
} from 'react-admin';

const useStyles = makeStyles({
  chipfieldColor: {
    color: '#ffffff',
  },
  fieldHeaderAlign: {
    textAlign: 'right',
  },
  fieldHeaderAlignLeft: {
    textAlign: 'left',
  },
});

export const PlantList = (props: ListProps) => {
  const classes = useStyles();
  const locale = useLocale();
  const translate = useTranslate();
  const tFP = 'resources.plants';
  const filters = [
    <TextInput source="upCode" alwaysOn resettable variant="standard" />,
    <TextInput source="name" alwaysOn resettable variant="standard" />,
    <TextInput
      source="currentOwner.businessName"
      alwaysOn
      resettable
      variant="standard"
    />,
  ];
  return (
    <List
      {...props}
      filters={filters}
      bulkActionButtons={false}
      exporter={false}
    >
      <Datagrid rowClick="show">
        <TextField source="upCode" />
        <TextField source="currentValues.pod" sortable={false} />
        <TextField source="name" />
        <TextField source="type" />
        <TextField source="address.address" sortable={false} />
        <TextField source="address.city.name" sortable={false} />
        <TextField source="address.city.province.name" sortable={false} />
        <TextField source="currentValues.voltageType" sortable={false} />
        <FunctionField
          textAlign="right"
          label={translate(`${tFP}.fields.currentValues.power`)}
          headerClassName={classes.fieldHeaderAlign}
          render={(record: Record | undefined) =>
            `${new Intl.NumberFormat(locale).format(
              record?.currentValues.power
            )}`
          }
        />
        <ReferenceField
          reference="companies"
          source="currentOwner"
          linkType="show"
        >
          <ChipField
            source="businessName"
            size="small"
            color="primary"
            className={classes.chipfieldColor}
            clickable={true}
          />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
