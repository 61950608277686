import { Box, Grid } from '@material-ui/core';
import {
  CheckboxGroupInput,
  Edit,
  FunctionField,
  NumberInput,
  Record,
  ReferenceInput,
  ResourceComponentProps,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  maxValue,
  minValue,
  number,
  required,
  useTranslate,
} from 'react-admin';
import { TextFieldsCard } from '../../lib/mui_lib/text-fields-card';
import { TextInput } from '../../lib/react-admin_lib/labeled-field';
import { UvamStatus } from '../../models/uvam-status';
import { EditableStatusViewNew } from '../grid-connection-points/status-management-components/EditableStatusViewNew';
import { CreateScWebOperator } from './uvam-resource-tabs/CreateScWebOperator';

const PostTitle = ({ record }: Record) => {
  const translate = useTranslate();
  const tFP = 'resources.uvam';
  return (
    <span>
      {translate(`${tFP}.name`, 0)}
      {record && record.upCode ? ` -  ${record.upCode}` : ''}
    </span>
  );
};

const UserEditToolbar = (props: ToolbarProps) => {
  const transform = (data: any) => ({
    ...data,
  });
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} transform={transform} />
    </Toolbar>
  );
};

export const UvamEdit = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.uvam';

  return (
    <Edit {...props} title={<PostTitle id={''} />} mutationMode="pessimistic">
      <SimpleForm
        redirect="show"
        toolbar={<UserEditToolbar />}
        variant="standard"
      >
        <Box display="flex" flexDirection="row" minWidth="100%">
          <Grid container spacing={9}>
            <TextFieldsCard title={translate(`${tFP}.fieldGroups.uvam`)}>
              <TextInput
                source="upCode"
                label={translate(`${tFP}.fields.upCode`)}
                emptyText={translate(`empty`)}
                variant="standard"
                disabled
              />
              <TextInput
                source="name"
                label={translate(`${tFP}.fields.name`)}
                emptyText={translate(`empty`)}
                variant="standard"
              />
              <SelectInput
                variant="standard"
                label={translate(`${tFP}.fields.perimeter`)}
                style={{ width: '305px' }}
                source="perimeter"
                choices={[
                  'NORD OVEST TO',
                  'NORD OVEST GE',
                  'NORD OVEST BS',
                  'NORD OVEST MI',
                  'NORD EST VE',
                  'NORD EST BO',
                  'DORSALE TIRRENICA RM',
                  'DORSALE TIRRENICA NA',
                  'DORSALE ADRIATICA PE',
                  'DORSALE ADRIATICA BA',
                  'SICILIA',
                  'SARDEGNA',
                  'DORSALE ADRIATICA AN',
                  'DORSALE TIRRENICA FI',
                  'DORSALE UMBRA PG',
                  'DORSALE UMBRA TR',
                  'DORSALE ADRIATICA TA',
                  'DORSALE TIRRENICA CZ',
                ].map((perimeter) => {
                  return {
                    id: perimeter,
                    name: perimeter,
                  };
                })}
                validate={required()}
              />
            </TextFieldsCard>
            <TextFieldsCard title={translate(`${tFP}.fieldGroups.gridVoltage`)}>
              <NumberInput
                source="minimumQualifiedPower"
                validate={[required(), number(), maxValue(0)]}
                style={{ width: '305px' }}
                variant="standard"
              />
              <NumberInput
                source="maximumQualifiedPower"
                validate={[required(), number(), minValue(0)]}
                variant="standard"
                style={{ width: '305px' }}
              />
            </TextFieldsCard>

            <TextFieldsCard
              title={translate(`${tFP}.fieldGroups.scWebOperator`)}
            >
              <ReferenceInput
                validate={required()}
                label="ScWebOperator"
                source="scWebOperator"
                allowEmpty={false}
                filterToQuery={(q) => ({ filter: { name: q } })}
                reference="sc-web-operator"
              >
                <SelectInput
                  optionText="name"
                  create={<CreateScWebOperator />}
                  variant="standard"
                  style={{ width: '305px' }}
                />
              </ReferenceInput>
            </TextFieldsCard>

            <TextFieldsCard title={translate(`${tFP}.fieldGroups.marketTypes`)}>
              <FunctionField
                source="marketTypes"
                render={(r: Record | undefined) => (
                  // TODO: put the name into translations
                  <CheckboxGroupInput
                    label={translate(`${tFP}.fields.marketTypes`)}
                    source="marketTypes"
                    choices={[
                      {
                        marketType: 'A',
                        name: translate(`${tFP}.marketTypes.A`),
                      },
                      {
                        marketType: 'B',
                        name: translate(`${tFP}.marketTypes.B`),
                      },
                      {
                        marketType: 'C',
                        name: translate(`${tFP}.marketTypes.C`),
                      },
                      {
                        marketType: 'D',
                        name: translate(`${tFP}.marketTypes.D`),
                      },
                      {
                        marketType: 'E',
                        name: translate(`${tFP}.marketTypes.E`),
                      },
                      {
                        marketType: 'F',
                        name: translate(`${tFP}.marketTypes.F`),
                      },
                      {
                        marketType: 'G',
                        name: translate(`${tFP}.marketTypes.G`),
                      },
                      {
                        marketType: 'H',
                        name: translate(`${tFP}.marketTypes.H`),
                      },
                    ]}
                    optionValue="marketType"
                    format={(values: any) => {
                      return values.map((v: any) =>
                        v.marketType ? v.marketType : v
                      );
                    }}
                    parse={(values: any) =>
                      values.map((v: any) => ({
                        marketType: v,
                        startDate: new Date(),
                        uvam: r?.id,
                      }))
                    }
                  />
                )}
              />
            </TextFieldsCard>
            <EditableStatusViewNew
              field="currentStatus"
              editableStatus={[UvamStatus.None]}
            >
              <TextFieldsCard title={translate(`${tFP}.fieldGroups.other`)}>
                <ReferenceInput
                  validate={required()}
                  label="Bsp"
                  source="bsp"
                  allowEmpty={false}
                  filterToQuery={(q) => ({ filter: { name: q } })}
                  reference="bsp"
                >
                  <SelectInput
                    optionText="name"
                    variant="standard"
                    style={{ width: '305px' }}
                  />
                </ReferenceInput>
              </TextFieldsCard>
            </EditableStatusViewNew>
          </Grid>
        </Box>
      </SimpleForm>
    </Edit>
  );
};
