import React from 'react';
import { getResources, MenuItemLink, useGetResourceLabel } from 'react-admin';
import { useSelector, shallowEqual } from 'react-redux';
import { MenuItemIcon } from '../mui_lib/menu-item-icon';
import DefaultIcon from '@material-ui/icons/ViewList';

interface IResourceMenuItem {
  name: string;
  dense?: boolean;
}

export const ResourceMenuItem = (props: IResourceMenuItem) => {
  const { name, dense } = props;
  const resources = useSelector(getResources, shallowEqual) as Array<any>;
  const getResourceLabel = useGetResourceLabel();

  return (
    <>
      {resources
        .filter((r) => r.hasList)
        .map(
          (resource) =>
            resource.name === name && (
              <MenuItemLink
                key={resource.name}
                to={{
                  pathname: `/${resource.name}`,
                  state: { _scrollToTop: true },
                }}
                primaryText={getResourceLabel(resource.name, 2)}
                leftIcon={
                  <MenuItemIcon
                    icon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                    to={`/${resource.name}`}
                  />
                }
                dense={dense}
              />
            )
        )}
    </>
  );
};
