import React from 'react';
import { StoreContext, IStore } from './store';
import { useLocalStorage } from './useLocalStorage';

export const useDarkTheme = () => {
  const [store, storeSet] = React.useContext(StoreContext);
  const [darkmode, setDarkmode] = useLocalStorage('darkmode', store.darkMode);
  const handleModeChange = () => {
    setDarkmode(!darkmode);
    storeSet((prevState: IStore) => ({
      ...prevState,
      darkMode: !prevState.darkMode,
    }));
  };
  return {
    theme: store.darkMode ? store.themeDark : store.theme,
    handleModeChange,
    toggleDark: store.darkMode,
  };
};
