import {
  Create,
  required,
  ResourceComponentProps,
  SimpleForm,
  TextInput,
  useTranslate,
  Toolbar,
  SaveButton,
  ToolbarProps,
  DateInput,
  BooleanInput,
  NumberInput,
  number,
  SelectArrayInput,
} from 'react-admin';
import TimeSelectInput from 'src/lib/custom-useful-components/TimeSelectInput';

const PostCreateToolbar = (props: ToolbarProps) => {
  const handleTransform = (data: any) => {
    const startTime = new Date(`2023-10-10T${data.startTimeHour}:${data.startTimeMinute}:00`).getTime() + 7200000;
    const endTime = new Date(`2023-10-10T${data.endTimeHour}:${data.endTimeMinute}:00`).getTime() + 7200000;
    const weekDaysIds = data.selectedDays.map((day: number) => day);
    return {...data,startTime,endTime,weekDaysIds};
  };
  return (
    <Toolbar {...props}>
      <SaveButton transform={handleTransform} />
    </Toolbar>
  );
};

export const ProductCreate = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.products';
  return (
    <Create {...props}>
      <SimpleForm
        redirect="show"
        variant="standard"
        toolbar={<PostCreateToolbar />}
      >
        <DateInput
          source="startDate"
          label={translate(`${tFP}.fields.startDate`)}
          validate={[required()]}
          className="editCreateForm"
        />
        <TextInput
          source="type"
          label={translate(`${tFP}.fields.type`)}
          validate={required()}
          className="editCreateForm"
        />
        <TimeSelectInput
          label="startTime"
          sourceHour="startTimeHour"
          sourceMinute="startTimeMinute"
        />
        <TimeSelectInput
          label="endTime"
          sourceHour="endTimeHour"
          sourceMinute="endTimeMinute"
        />
        <BooleanInput
          source="holiday"
          label={translate(`${tFP}.fields.holiday`)}
          defaultValue={false}
          style={{ marginTop: '20px' }}
        />
        <NumberInput
          source="strikePrice"
          label={translate(`${tFP}.fields.strikePrice`)}
          validate={[required(), number()]}
          className="editCreateForm"
        />
        <DateInput
          source="endDate"
          label={translate(`${tFP}.fields.endDate`)}
          validate={[required()]}
          className="editCreateForm"
        />
        <TextInput
          source="productCode"
          label={translate(`${tFP}.fields.productCode`)}
          validate={required()}
          className="editCreateForm"
        />
        <SelectArrayInput
          variant="standard"
          label={translate(`${tFP}.fields.activationDays`)}
          source="selectedDays"
          choices={[
            {weekDay: 1, name: translate(`resources.weekDays.monday`) },
            {weekDay: 2, name: translate(`resources.weekDays.tuesday`) },
            {weekDay: 3, name: translate(`resources.weekDays.wednesday`) },
            {weekDay: 4, name: translate(`resources.weekDays.thursday`) },
            {weekDay: 5, name: translate(`resources.weekDays.friday`) },
            {weekDay: 6, name: translate(`resources.weekDays.saturday`) },
            {weekDay: 7, name: translate(`resources.weekDays.sunday`) },
          ]}
          validate={required()}
          optionValue='weekDay'
          className="editCreateForm"
        />
      </SimpleForm>
    </Create>
  );
};
