import {
  AutocompleteInput,
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceInput,
  ResourceComponentProps,
  SelectInput,
  TextField,
  useTranslate
} from 'react-admin';
import { EditDeleteButtons } from 'src/lib/react-admin_lib/EditDeleteButtons';

export const CustomContractList = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.custom-contracts';
  const filters = [
    <ReferenceInput
      key="legalEntity"
      reference="companies"
      source="legalEntity"
      link="show"
      alwaysOn
      resettable
      variant="standard"
    >
      <AutocompleteInput
        optionText="businessName"
        optionValue="id"
        style={{ width: '200px' }}
      />
    </ReferenceInput>,
    <ReferenceInput
      key="counterpart"
      reference="companies"
      source="counterpart"
      link="show"
      alwaysOn
      resettable
      variant="standard"
    >
      <AutocompleteInput
        optionText="businessName"
        optionValue="id"
        style={{ width: '200px' }}
      />
    </ReferenceInput>,
    <SelectInput
      key="mark"
      label={translate(`${tFP}.fields.mark`)}
      source="mark"
      style={{ width: '200px' }}
      alwaysOn
      resettable
      choices={['B'].map((mark) => {
        return {
          id: mark,
          name: mark,
        };
      })}
      variant="standard"
    />,
    <SelectInput
      key="paymentId"
      label={translate(`${tFP}.fields.paymentId`)}
      source="paymentId"
      style={{ width: '200px' }}
      alwaysOn
      resettable
      choices={['FMF'].map((paymentId) => {
        return {
          id: paymentId,
          name: paymentId,
        };
      })}
      variant="standard"
    />,
    <SelectInput
      key="renewalId"
      label={translate(`${tFP}.fields.renewalId`)}
      source="renewalId"
      style={{ width: '200px' }}
      alwaysOn
      resettable
      choices={['ESC', 'RIPF'].map((renewalId) => {
        return {
          id: renewalId,
          name: renewalId,
        };
      })}
      variant="standard"
    />,
    <SelectInput
      key="upmLoan"
      label={translate(`${tFP}.fields.upmLoan`)}
      source="upmLoan"
      style={{ width: '200px' }}
      alwaysOn
      resettable
      choices={['GRA'].map((upmLoan) => {
        return {
          id: upmLoan,
          name: upmLoan,
        };
      })}
      variant="standard"
    />,
    <SelectInput
      key="penalty"
      label={translate(`${tFP}.fields.penalty`)}
      source="penalty"
      style={{ width: '200px' }}
      alwaysOn
      resettable
      choices={['PAS', 'RIM'].map((penalty) => {
        return {
          id: penalty,
          name: penalty,
        };
      })}
      variant="standard"
    />,
    <ReferenceInput
      key="mediator"
      reference="companies"
      source="mediator"
      link="show"
      alwaysOn
      resettable
      variant="standard"
    >
      <AutocompleteInput
        optionText="businessName"
        optionValue="id"
        style={{ width: '200px' }}
      />
    </ReferenceInput>
  ];
  return (
    <List {...props} exporter={false} bulkActionButtons={false} filters={filters}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <ReferenceField
            label={translate(`${tFP}.fields.legalEntity`)}
            source="legalEntity"
            reference="companies"
            link={false}
          >
            <TextField
              source="businessName"
              emptyText="-"
              label={translate(`${tFP}.fields.legalEntity`)}
            />
        </ReferenceField>
        <ReferenceField
            label={translate(`${tFP}.fields.counterpart`)}
            source="counterpart"
            reference="companies"
            link={false}
          >
            <TextField
              source="businessName"
              emptyText="-"
              label={translate(`${tFP}.fields.counterpart`)}
            />
        </ReferenceField>
        <DateField source="contractDate" />
        <DateField source="startDate" />
        <DateField source="endDate" />
        <TextField source="mark" />
        <TextField source="paymentId" />
        <TextField source="renewalId" />
        <TextField source="upmLoan" />
        <TextField source="penalty" />
        <TextField source="auctionSupplierFee" />
        <TextField source="spotSupplierFee" />
        <ReferenceField
            label={translate(`${tFP}.fields.mediator`)}
            source="mediator"
            reference="companies"
            link={false}
          >
            <TextField
              source="businessName"
              emptyText="-"
              label={translate(`${tFP}.fields.mediator`)}
            />
        </ReferenceField>
        <TextField source="mediatorFee" />
        <EditDeleteButtons remove={true}/>
      </Datagrid>
    </List>
  );
};
