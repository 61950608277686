import { Theme, ThemeOptions } from '@material-ui/core';

export const defaultTheme: ThemeOptions = {
  palette: {
    error: {
      light: '#e57373',
      main: '#f50057',
      dark: '#c51162',
      contrastText: '#fff',
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
    ].join(','),
    subtitle1: {
      fontWeight: 'bolder',
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        'box-shadow': 'none',
      },
    },
    MuiPaper: {
      root: {
        'box-shadow': 'none',
      },
    },
    MuiCard: {
      root: {
        'box-shadow': 'none',
      },
    },
    MuiTableCell: {
      sizeSmall: {
        'padding-top': '10px',
        'padding-bottom': '10px',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.75em",
      },
    },
  },
};

export type PorthosTheme = typeof defaultTheme & Theme & Record<string, any>;
