import React from 'react';
import { useUserHasPermissions } from '../custom-hooks/useUserHasPermissions';
import { GenericFilterView } from './GenericFilterView';

export const AdminView = (
  component: React.ReactElement | React.ReactElement[] | false
) => {
  const isAdmin = useUserHasPermissions('admin');
  return GenericFilterView({
    filter: () => isAdmin,
    component,
  });
};
