import {
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  ReferenceField,
  ResourceComponentProps,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useTranslate
} from 'react-admin';

const ActionButtons = (props: any) => {
  const { basePath, data, resource } = props;
  const translate = useTranslate();
  const tFP = 'resources.gcp-force-forecast-upm';
  return(
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
      {data && typeof data.id !== 'undefined' && ( 
        <DeleteWithConfirmButton 
          basePath={basePath} 
          record={data} 
          resource={resource} 
          redirect={`/grid-connection-points/${data.gridConnectionPoint}/show`}
          confirmContent={translate(`${tFP}.deleteConfirmMessage`)}/>
      )}
    </TopToolbar>
  );
};

export const GcpForceForecastUpmShow = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.gcp-force-forecast-upm';
  return (
    <Show {...props} actions={<ActionButtons />}>
      <SimpleShowLayout>
        <ReferenceField
          label={translate(`${tFP}.fields.exergyId`)}
          source="gridConnectionPoint"
          reference="grid-connection-points"
          link={false}
        >
          <TextField
            source="exergyId"
            emptyText="-"
            label={translate(`${tFP}.fields.exergyId`)}
          />
        </ReferenceField>
        <DateField source="startDate" />
        <DateField source="endDate" />
      </SimpleShowLayout>
    </Show>
  );
};
