import {
  Box,
  CircularProgress,
  makeStyles,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import InfoIcon from '@mui/icons-material/Info';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { TextFieldProps, useTranslate } from 'react-admin';
import { CustomerUploadFile } from '../../models/cutomer-upload-file';
import { PorthosTheme } from '../../themes/default';

interface CustomProgressFieldProps extends TextFieldProps {
  source: keyof CustomerUploadFile;
}

const useStyles = makeStyles(
  (theme: PorthosTheme) => ({
    loader: {
      margin: theme.spacing(0),
      marginRight: theme.spacing(1),
      fontSize: theme.typography.fontSize,
      size: theme.spacing(2),
    },
    info: {
      margin: theme.spacing(0),
      marginRight: theme.spacing(1),
      color: theme.palette.info.main,
    },
    tooltip: {
      fontSize: theme.typography.h3.fontSize,
    },
    span: {
      fontSize: theme.typography.fontSize,
      alignContent: 'center',
      verticalAlign: 'baseline',
      textAnchor: 'middle',
    },
    loadedIcon: {},
  }),
  { name: 'RaLoadingIndicator' }
);

export const CustomProgressField = (props: CustomProgressFieldProps) => {
  const { record, source } = props;
  const [show, setShow] = React.useState(true);
  const theme = useTheme();
  const classes = useStyles(props);

  const translate = useTranslate();

  React.useEffect(() => {
    if (!record || !source || !record[source]) return;

    const dt = moment(record[source].toString());
    setShow(moment().diff(dt, 'minutes') < 15);
  }, [record, source]);

  if (!record || !source || !record[source] || !show) {
    return <></>;
  }

  return (
    <Tooltip
      className={classNames(classes.tooltip)}
      title={translate(
        'resources.companies.tabs.forecast_avaiability_tab.sync_tooltip'
      )}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography>
          {translate(
            'resources.companies.tabs.forecast_avaiability_tab.sync_label'
          )}
          &nbsp;
        </Typography>
        <InfoIcon color="primary" style={{ fontSize: theme.spacing(2) }} />
        <Typography>&nbsp;</Typography>
        <CircularProgress color="inherit" size={theme.spacing(2)} />
      </Box>
    </Tooltip>
  );
};
