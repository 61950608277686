
import { Button } from '@material-ui/core';
import ContentAdd from '@material-ui/icons/Add';
import {
  Datagrid,
  DateField,
  Link,
  List,
  ResourceComponentProps,
  TextField,
  useTranslate
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { EditDeleteButtons } from 'src/lib/react-admin_lib/EditDeleteButtons';

const ActionButtons = () => {
  const translate = useTranslate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const filter = JSON.parse(queryParams.get('filter') || '{}');
  const gcpId = filter.gridConnectionPoint;
  const tFP = 'resources.grid-connection-point-abilitation-no-product';
  return(
    <Button
        style={{
          marginTop: "20px",
          marginLeft: "8px"
        }}
        color="primary"
        component={Link}
        startIcon={<ContentAdd />}
        to={{
            pathname: '/grid-connection-point-abilitation-no-product/create',
            state: { record: { gridConnectionPoint: gcpId } },
        }}
    >
        {translate(`${tFP}.create`)}
    </Button>
  );
};

export const GridConnectionPointAbilitationNoProductList = (props: ResourceComponentProps) => {
  return (
    <List {...props} exporter={false} bulkActionButtons={false} actions={<ActionButtons />}>
        <Datagrid rowClick="show">
          <DateField source="startDate" />
          <DateField source="endDate" />
          <TextField source="minQuartersBeforeAssign" />
          <EditDeleteButtons remove={true}/>
        </Datagrid>
    </List>
  );
};
