import { Box, Grid } from '@material-ui/core';
import {
  AutocompleteInput,
  DateInput,
  Edit,
  NumberInput,
  ReferenceInput,
  ResourceComponentProps,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  number,
  required,
  useGetList,
  useGetMany,
  useTranslate
} from 'react-admin';
import { TextFieldsCard } from '../../lib/mui_lib/text-fields-card';

const UserEditToolbar = (props: ToolbarProps) => {
  const handleTransform = (data: any) => {
    return {...data};
  };
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} transform={handleTransform} />
    </Toolbar>
  );
};

export const GridConnectionPointCustomContractEdit = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.grid-connection-point-custom-contracts';
  const customContractList = useGetList('custom-contracts');
  const counterpartIds = Object.values(customContractList?.data).map((element) => (element.counterpart));
  const counterpartList = useGetMany('companies', counterpartIds);
  const counterpartLabelMap: Record<string, string> = counterpartList.data.reduce((acc: Record<string, string>, item) => {
    if (item) {
      acc[item.id] = item.businessName;
    }
    return acc;
  }, {});
  return (
    <Edit {...props} title={translate(`${tFP}.name`, 1)} mutationMode="pessimistic">
      <SimpleForm
        redirect="show"
        toolbar={<UserEditToolbar />}
        variant="standard"
      >
        <Box display="flex" flexDirection="row" minWidth="100%">
          <Grid container spacing={9}>
            <TextFieldsCard title={translate(`${tFP}.name`, 0)}>
              <ReferenceInput
                validate={required()}
                label={translate(`${tFP}.fields.customContract`)}
                source="customContract"
                allowEmpty={false}
                filterToQuery={(q) => ({ filter: { name: q } })}
                reference="custom-contracts"
              >
                <SelectInput optionText={(record) => {
                    const counterpart = counterpartLabelMap[record.counterpart];
                    return counterpart ? `${record.id} - ${counterpart}` : `${record.id}`;
                  }}
                />
              </ReferenceInput>
              <DateInput
                source="allocationDate"
                label={translate(`${tFP}.fields.allocationDate`)}
                validate={[required()]}
              />
              <DateInput
                source="startDate"
                label={translate(`${tFP}.fields.startDate`)}
                validate={[required()]}
              />
              <DateInput
                source="endDate"
                label={translate(`${tFP}.fields.endDate`)}
                validate={[required()]}
              />
              <ReferenceInput
                validate={required()}
                label={translate(`${tFP}.fields.gcpId`)}
                source="gridConnectionPoint"
                allowEmpty={false}
                filterToQuery={(q) => ({ filter: { name: q } })}
                reference="grid-connection-points"
              >
                <AutocompleteInput optionText="meteringPointId" />
              </ReferenceInput>
              <ReferenceInput
                validate={required()}
                label={translate(`${tFP}.fields.product`)}
                source="product"
                allowEmpty={false}
                filterToQuery={(q) => ({ filter: { name: q } })}
                reference="products"
              >
                <SelectInput optionText={(record) => `${record.id} - ${record.productCode} - ${record.type}`} />
              </ReferenceInput>
              <NumberInput
                source="powerMw"
                label={translate(`${tFP}.fields.powerMw`)}
                validate={[required(), number()]}
              />
              <NumberInput
                source="price"
                label={translate(`${tFP}.fields.price`)}
                validate={[required(), number()]}
              />
              <TextInput
                source="note"
                multiline
              />
            </TextFieldsCard>
          </Grid>
        </Box>
      </SimpleForm>
    </Edit>
  );
};
