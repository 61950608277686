import MomentUtils from '@date-io/moment';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import GetAppIcon from '@material-ui/icons/GetApp';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { DatePicker } from '@material-ui/pickers';
import fileDownload from 'js-file-download';
import moment from 'moment';
import React from 'react';
import {
  DateField,
  Record as RecordType,
  TextField,
  Title,
  useNotify,
  useRecordContext,
  useReferenceArrayFieldController,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { FileUploader } from '../../lib/mui_lib/file-uploader';
import Modal from '../../lib/mui_lib/modal';
import { SelectField } from '../../lib/mui_lib/select';
import { ChipFieldCustomRoute } from '../../lib/react-admin_lib/chip-field-custom-route';
import { CustomStatusOptionsField } from '../../lib/react-admin_lib/custom-status-options-field';
import { ExcelWarningsPopup } from './excel-warnings-popup';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useAxios } from '../../lib/custom-hooks/useAxios';
import { ExtendedDatagrid } from '../../lib/react-admin_lib/extended-datagrid';

export const ForecastAvaiabilityTab = () => {
  const notify = useNotify();
  const record = useRecordContext();
  const translate = useTranslate();
  const refresh = useRefresh();
  const tFP = 'resources.companies.tabs.forecast_avaiability_tab';
  const { data } = useReferenceArrayFieldController({
    record: { id: record.id, referenceIds: record.contractualPlants },
    reference: 'plants',
    resource: 'resource',
    source: 'referenceIds',
  });
  const [startDate, setStartDate] = React.useState(
    moment().format('YYYY-MM-DD')
  );
  const [uploadModal, setUploadModal] = React.useState(false);
  const [excelWarningModal, setExcelWarningModal] = React.useState(false);
  const [excelWarningRecord, setExcelWarningRecord] =
    React.useState<RecordType>();
  const [excelFile, setExelFile] = React.useState('');
  const [uploadLoading, setUploadLoading] = React.useState(false);
  const [endDate, setEndDate] = React.useState(moment().format('YYYY-MM-DD'));
  const selectPlant =
    data &&
    Object.values(data).map((plant) => {
      return { id: plant.id, name: plant.name };
    });
  const staticSelectFileType = [
    { id: 'forecast', name: translate(`${tFP}.forecast`) },
    { id: 'availability', name: translate(`${tFP}.availability`) },
  ];
  const [selectProductionUnit, setSelectProductionUnit] = React.useState(
    selectPlant.length > 0 && selectPlant[0].id
  );
  const [selectFileType, setSelectFileType] = React.useState(
    staticSelectFileType[0].id
  );
  const { refetch } = useAxios();

  const handleWarningStatusClick = (record: RecordType) => {
    setExcelWarningRecord(record);
    setExcelWarningModal(true);
  };
  const statusLabel = {
    'import-success': [
      `${tFP}.status_import_success`,
      'success',
      CheckCircleRoundedIcon,
      false,
      handleWarningStatusClick,
    ],
    'import-warning': [
      `${tFP}.status_import_warning`,
      'warning',
      WarningRoundedIcon,
      false,
      handleWarningStatusClick,
    ],
    'invalid-template': [
      `${tFP}.status_invalid_template`,
      'error',
      ErrorRoundedIcon,
      true,
    ],
    'missing-sheet': [
      `${tFP}.status_missing_sheet`,
      'error',
      ErrorRoundedIcon,
      true,
    ],
    'missing-sheet-2': [
      `${tFP}.status_missing_sheet`,
      'error',
      ErrorRoundedIcon,
      true,
    ],
    'no-user-plant': [
      `${tFP}.status_no_user_plant`,
      'error',
      ErrorRoundedIcon,
      true,
    ],
    'not-same-data-type': [
      `${tFP}.status_not_same_data_type`,
      'error',
      ErrorRoundedIcon,
      true,
    ],
  } as const;

  const handleSelectProductionUnitChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setSelectProductionUnit(e.target.value as number);
  };
  const handleSelectFileTypeChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setSelectFileType(e.target.value as string);
  };
  const handleDateInitialChange = (date: MaterialUiPickersDate) => {
    const formattedDate = date && new MomentUtils().format(date, 'YYYY-MM-DD');
    formattedDate && setStartDate(formattedDate);
  };
  const handleDateFinalChange = (date: MaterialUiPickersDate) => {
    const formattedDate = date && new MomentUtils().format(date, 'YYYY-MM-DD');
    formattedDate && setEndDate(formattedDate);
  };
  const downloadSampleFile = async () => {
    const res = await refetch({
      url: `/plants/${selectProductionUnit}/download-template?templateType=${selectFileType}&templateStart=${startDate}&templateEnd=${endDate}`,
      method: 'get',
      responseType: 'arraybuffer',
    });
    if (res) {
      const { result, error } = res;
      if (result?.data)
        fileDownload(
          result?.data,
          result?.headers?.['content-disposition'].split('=')[1]
        );
      if (error) notify('error', 'error');
    }
  };
  const handleFileChange = (file: string) => {
    setExelFile(file);
  };

  const isValidStatusLabel = (s: string): s is keyof typeof statusLabel =>
    Object.keys(statusLabel).includes(s);

  const handleUploadFile = async () => {
    const formData = new FormData();
    formData.append('file', excelFile);
    formData.append('dataType', selectFileType);
    setUploadLoading(true);
    const res = await refetch({
      url: `/plants/${selectProductionUnit}/upload-data`,
      method: 'post',
      headers: { 'content-type': 'multipart/form-data' },
      data: formData,
    });
    if (res) {
      const { result, error, loading } = res;
      const receivedStatusLabel = result?.data.status;
      if (isValidStatusLabel(receivedStatusLabel)) {
        const [label, type] =
          statusLabel[receivedStatusLabel] ?? statusLabel['invalid-template'];
        notify(label, type, undefined, false, 5000);
        refresh();
      }
      if (error) notify('error', 'error');
      setUploadLoading(loading);
      setUploadModal(false);
      setExelFile('');
    }
  };

  const handleUploadFileDownload = async (record: RecordType) => {
    const res = await refetch({
      url: record.downloadUrl,
      method: 'get',
      headers: { contentType: 'application/octet-stream' },
      responseType: 'arraybuffer',
    });
    if (res) {
      const { result, error } = res;
      if (result?.data) fileDownload(result?.data, record.filename);
      if (error) notify('error', 'error');
    }
  };

  return (
    <Box>
      <Title title={translate(`${tFP}.name`)} />
      <Card>
        <Box mt={1} mb={2}>
          <SelectField
            list={selectPlant}
            onChange={handleSelectProductionUnitChange}
            value={selectProductionUnit}
            labelName={translate(`${tFP}.sel_production_unit`)}
          />
        </Box>
        {selectProductionUnit && (
          <>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              padding={2}
            >
              <Typography color="textSecondary" variant="subtitle1">
                {translate(`${tFP}.generate_template_file`)}
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                padding={2}
              >
                <Box pr={2}>
                  <DatePicker
                    value={startDate}
                    inputVariant="standard"
                    label={translate(`${tFP}.initial_date`)}
                    format="DD/MM/YYYY"
                    minDate={Date.now()}
                    onChange={handleDateInitialChange}
                  />
                </Box>
                <Box pr={2}>
                  <DatePicker
                    value={endDate}
                    inputVariant="standard"
                    label={translate(`${tFP}.final_date`)}
                    format="DD/MM/YYYY"
                    minDate={startDate}
                    onChange={handleDateFinalChange}
                  />
                </Box>
                <SelectField
                  list={staticSelectFileType}
                  onChange={handleSelectFileTypeChange}
                  value={selectFileType}
                  labelName={translate(`${tFP}.sel_file_type`)}
                />
              </Box>
              <Button
                variant="contained"
                color="primary"
                startIcon={<GetAppIcon />}
                onClick={downloadSampleFile}
              >
                {translate(`${tFP}.btn_download_excel`)}
              </Button>
            </Box>
            <Divider />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mt={1}
              mb={2}
            >
              <Box mb={2}>
                <Typography color="textSecondary" variant="subtitle1">
                  {translate(`${tFP}.upload`)}
                </Typography>
              </Box>
              <Modal
                startIcon={<CloudUploadIcon />}
                name={translate(`${tFP}.upload_file`)}
                open={uploadModal}
                setOpen={setUploadModal}
              >
                <>
                  <CloudUploadIcon fontSize="large" color="disabled" />
                  <Typography variant="h5" color="textPrimary">
                    {translate(`${tFP}.upload_file`)}
                  </Typography>
                  <Typography variant="caption">
                    {translate(`${tFP}.upload_pop_up.upload_des`)}
                  </Typography>
                  <Box pt={3}>
                    <Divider />
                    <Box pt={1}>
                      <Typography variant="caption" color="textSecondary">
                        <b>{translate(`${tFP}.upload_pop_up.instructions`)}</b>:{' '}
                        {translate(`${tFP}.upload_pop_up.instructions_des`)}
                      </Typography>
                    </Box>
                    <Box pt={1} pb={2}>
                      <FileUploader
                        onChange={handleFileChange}
                        value={excelFile}
                        label={translate(`${tFP}.upload_pop_up.sel_file`)}
                      />
                    </Box>
                    <Box pb={2}>
                      <SelectField
                        list={staticSelectFileType}
                        onChange={handleSelectFileTypeChange}
                        value={selectFileType}
                        labelName={translate(`${tFP}.sel_file_type`)}
                      />
                    </Box>

                    <Divider />
                  </Box>
                  <Box mt={2} justifyContent="right">
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<CloudUploadIcon />}
                      onClick={handleUploadFile}
                      disabled={!excelFile || uploadLoading}
                    >
                      {translate(`${tFP}.upload`)}
                    </Button>
                  </Box>
                </>
              </Modal>
            </Box>
            <Divider />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mt={1}
              mb={2}
            >
              <Box>
                <Typography color="textSecondary" variant="subtitle1">
                  {translate(`${tFP}.uploaded_files`)}
                </Typography>
              </Box>
              <>
                <ExtendedDatagrid
                  resourceUrl={`plants/${selectProductionUnit}/customer-uploaded-files`}
                  empty={<></>}
                >
                  <DateField
                    label={translate(`${tFP}.date_uploaded`)}
                    source="createdAt"
                    showTime={true}
                  />
                  <CustomStatusOptionsField
                    label={translate(`${tFP}.upload_result`)}
                    source="status"
                    statusOptions={statusLabel}
                  />
                  <TextField label={translate(`${tFP}.type`)} source="type" />
                  <ChipFieldCustomRoute
                    label={translate(`${tFP}.file`)}
                    source="filename"
                    color="primary"
                    onClick={handleUploadFileDownload}
                  />
                </ExtendedDatagrid>
              </>
            </Box>
          </>
        )}
      </Card>
      {excelWarningModal && excelWarningRecord && selectProductionUnit && (
        <ExcelWarningsPopup
          open={excelWarningModal}
          setOpen={setExcelWarningModal}
          name={`${translate(`${tFP}.excel_warning_pop_up.title`)} - ${
            excelWarningRecord.filename
          }`}
          fileId={excelWarningRecord.id as number}
          plantId={selectProductionUnit}
          type={excelWarningRecord.type}
          tFP={tFP}
          all={excelWarningRecord}
          plants={selectPlant}
        />
      )}
    </Box>
  );
};
