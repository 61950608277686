
import { Button } from '@material-ui/core';
import ContentAdd from '@material-ui/icons/Add';
import {
  Datagrid,
  DateField,
  Link,
  List,
  ReferenceField,
  ResourceComponentProps,
  TextField,
  useTranslate
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { EditDeleteButtons } from 'src/lib/react-admin_lib/EditDeleteButtons';

const ActionButtons = () => {
  const translate = useTranslate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const filter = JSON.parse(queryParams.get('filter') || '{}');
  const gridConnectionPointId = filter.gridConnectionPoint;
  const tFP = 'resources.gcp-force-forecast-upm';
  return(
    <Button
        style={{
          marginTop: "20px",
          marginLeft: "8px"
        }}
        color="primary"
        component={Link}
        startIcon={<ContentAdd />}
        to={{
            pathname: '/gcp-force-forecast-upm/create',
            state: { record: { gridConnectionPoint: gridConnectionPointId } },
        }}
    >
        {translate(`${tFP}.create`)}
    </Button>
  );
};

export const GcpForceForecastUpmList = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.gcp-force-forecast-upm';
  return (
    <List {...props} exporter={false} bulkActionButtons={false} actions={<ActionButtons />}>
        <Datagrid rowClick="show">
          <ReferenceField
            label={translate(`${tFP}.fields.exergyId`)}
            source="gridConnectionPoint"
            reference="grid-connection-points"
            link={false}
          >
            <TextField
              source="exergyId"
              emptyText="-"
              label={translate(`${tFP}.fields.exergyId`)}
            />
          </ReferenceField>
          <DateField source="startDate" />
          <DateField source="endDate" />
          <EditDeleteButtons remove={true}/>
        </Datagrid>
    </List>
  );
};
