import { SelectInput, required, useTranslate } from "react-admin";

const TimeSelectInput = ({ label, sourceHour, sourceMinute }: { label: string, sourceHour: string, sourceMinute: string }) => {
    const translate = useTranslate();
    const tFP = 'resources.products';
  
    const hourChoices = Array.from({length: 24}, (_, i) => i).map((i) => {
      const hour = i.toString().padStart(2, '0');
      return {
        id: hour,
        name: hour,
      };
    });
  
    const minuteChoices = Array.from({length: 4}, (_, i) => i*15).map((i) => {
      const minute = i.toString().padStart(2, '0');
      return {
        id: minute,
        name: minute,
      };
    });
  
    return (
      <span>
        <div style={{marginRight: '20px', marginTop: '20px'}}>
          {translate(`${tFP}.fields.${label}`)}
        </div>
        <SelectInput
          style={{marginRight: '20px', marginTop: '1px'}}
          variant="standard"
          label={translate(`${tFP}.fields.hours`)}
          source={sourceHour}
          choices={hourChoices}
          validate={required()}
        />
        <SelectInput
          style={{marginTop: '1px'}}
          variant="standard"
          label={translate(`${tFP}.fields.minutes`)}
          source={sourceMinute}
          choices={minuteChoices}
          validate={required()}
        />
      </span>
    );
  };

  export default TimeSelectInput;