import { config } from "@config/config";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Typography,
  capitalize,
  makeStyles,
} from "@material-ui/core";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import React from "react";
import {
  ChipField,
  Datagrid,
  FunctionField,
  Identifier,
  List,
  Pagination,
  Record,
  ReferenceArrayField,
  ResourceComponentProps,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput,
  useLocale,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { useHistory } from "react-router-dom";
import { useLocalStorage } from "../../lib/custom-hooks/useLocalStorage";
import { ChipFieldCustomRoute } from "../../lib/react-admin_lib/chip-field-custom-route";
import { SwitchCardViewAction } from "../../lib/react-admin_lib/switch-card-view-actions";
import { AdminAndNonLeafResellerView } from "../../lib/user-management/AdminAndNonLeafReseller";

const useStyles = makeStyles({
  chipfieldColor: {
    color: "#ffffff",
  },
  fieldHeaderAlign: {
    textAlign: "right",
  },
  fieldHeaderAlignLeft: {
    textAlign: "left",
  },
  defaltHoverableRow: {
    cursor: "context-menu",
  },
  hoverableRow: {
    cursor: "pointer",
  },
});
const resellerRoutes = {
  "4e": process.env.REACT_APP_4E,
  plenitude: process.env.REACT_APP_4E,
  ecotrade: process.env.REACT_APP_ECOTRADE,
  ecosuntek: process.env.REACT_APP_ECOSUNTEK,
  sev: process.env.REACT_APP_SEV,
};

const replace4e = (
  reseller: keyof typeof resellerRoutes
): keyof typeof resellerRoutes => (reseller === "4e" ? "plenitude" : reseller);

const resellersListFilter = (
  reseller: keyof typeof resellerRoutes
): Array<string> => {
  switch(replace4e(reseller)){
    case "plenitude":
      return ["Plenitude","Ecotrade","Ecosuntek","Sev"];
    case "ecotrade":
      return["Ecotrade","Ecosuntek"];
    default:
      return[];
  };
};

const TextArrayField = ({ source }: { source: string; sortable?: boolean }) => {
  const record = useRecordContext();
  const array = record[source];

  const handleChipClickToOpenInNewWindow = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    url: keyof typeof resellerRoutes
  ) => {
    e.stopPropagation();
    window.open(
      `${resellerRoutes[url]}/#/companies/${record.id}/show`,
      "_blank"
    );
  };

  const handleChipClickToOpenInCurrentWindow = (
    url: keyof typeof resellerRoutes
  ) => {
    window.open(
      `${resellerRoutes[url]}/#/companies/${record.id}/show`,
      "_self"
    );
  };

  if (!array) {
    return <></>;
  } else {
    return (
      <Box display="flex" flexDirection="row">
        {array.map((value: keyof typeof resellerRoutes) => {
          return replace4e(value) === replace4e(config.currentReseller) ? (
            <Box>
              &nbsp;
              <Chip
                label={capitalize(replace4e(value))}
                onClick={() => handleChipClickToOpenInCurrentWindow(value)}
                size="small"
                color="secondary"
              />
              &nbsp;
            </Box>
          ) : (
            <Box>
              &nbsp;
              <Chip
                icon={<OpenInNewIcon style={{ fontSize: 15, marginLeft: 8 }} />}
                label={capitalize(replace4e(value))}
                onClick={(e) => handleChipClickToOpenInNewWindow(e, value)}
                color="primary"
                size="small"
              />
              &nbsp;
            </Box>
          );
        })}
      </Box>
    );
  }
};
TextArrayField.defaultProps = { addLabel: true };

export const CompanyList = (props: ResourceComponentProps) => {
  const [viewList, setViewList] = useLocalStorage("viewList", true);
  const translate = useTranslate();
  const tFP = "resources.companies.fields";
  const companyFiltersWithoutReseller = [
    <TextInput
      label={translate(`${tFP}.businessName`)}
      source="businessName"
      variant="standard"
      alwaysOn
    />,
    <TextInput
      label={translate(`${tFP}.vatNumber`)}
      source="vatNumber"
      variant="standard"
      alwaysOn
    />,
  ];
  const companyFilters = [
    ...companyFiltersWithoutReseller,
    <SelectInput
      label={translate(`${tFP}.resellers`)}
      source="resellers"
      variant="standard"
      style={{ width: "230px" }}
      alwaysOn
      choices={resellersListFilter(config.currentReseller).map((status) => {
        return { id: status.toLowerCase(), name: status };
      })}
    />,
  ];

  if (config.currentReseller === "plenitude") {
    companyFilters.push(
      <SelectInput
        label={translate(`${tFP}.currentContract.dispatchingType`)}
        source="dp"
        variant="standard"
        style={{ width: "230px" }}
        alwaysOn
        choices={[
          "DI2690 - Plenitude",
          "DI2513 - 4E",
          "DI2755 - Sev",
          "DI2575 - Ecotrade",
        ].map((status) => {
          return { id: status.split(" - ")[0], name: status };
        })}
      />
    );
  }

  return (
    <List
      {...props}
      component={viewList ? undefined : Box}
      actions={
        <SwitchCardViewAction
          viewList={viewList}
          setViewList={setViewList}
          filterAction
        />
      }
      filters={
        AdminAndNonLeafResellerView(companyFilters) ||
        companyFiltersWithoutReseller
      }
    >
      {viewList ? <CompanyListView /> : <CompanyCardsView {...props} />}
    </List>
  );
};

const CompanyCardsView = (props: ResourceComponentProps) => {
  const history = useHistory();
  const goToCompanyPage = (record: any) => {
    history.push(`${props.basePath}/${record.id}/show`);
  };
  const translate = useTranslate();
  const tFP = "resources.companies.fields";
  const locale = useLocale();
  const classes = useStyles();
  return (
    <SingleFieldList
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
      linkType={false}
    >
      <Box m={1}>
        <Card>
          <CardContent>
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography color="textSecondary" variant="subtitle1">
                  {translate(`${tFP}.businessName`)}: &nbsp;
                </Typography>
                <Typography color="textPrimary">
                  <ChipFieldCustomRoute
                    source="businessName"
                    size="small"
                    color="primary"
                    clickable={true}
                    onClick={goToCompanyPage}
                  />
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography color="textSecondary" variant="subtitle1">
                  {translate(`${tFP}.vatNumber`)}: &nbsp;
                </Typography>
                <Typography color="textPrimary">
                  <TextField source="vatNumber" />
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography color="textSecondary" variant="subtitle1">
                  {translate(`${tFP}.legalAddress.address`)}: &nbsp;
                </Typography>
                <Typography color="textPrimary">
                  <TextField source="legalAddress.address" label="Address" />
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography color="textSecondary" variant="subtitle1">
                  {translate(`${tFP}.legalAddress.city.name`)}: &nbsp;
                </Typography>
                <Typography color="textPrimary">
                  <TextField source="legalAddress.city.name" label="City" />
                </Typography>
              </Box>
            </Box>

            <Typography color="textSecondary" variant="subtitle1">
              {translate(`${tFP}.ownedPlants`)}:
            </Typography>

            <ReferenceArrayField
              reference="plants"
              source="contractualPlants"
              sort={{ field: "upCode", order: "ASC" }}
              addLabel={false}
              perPage={5}
              pagination={<Pagination />}
            >
              <Datagrid rowClick="show">
                <TextField source="upCode" />
                <TextField source="currentValues.pod" />
                <TextField source="name" />
                <TextField source="type" />
                <TextField source="address.address" />
                <TextField source="address.city.name" />
                <TextField source="address.city.province.name" />
                <FunctionField
                  textAlign="right"
                  label={translate(
                    `resources.plants.fields.currentValues.power`
                  )}
                  headerClassName={classes.fieldHeaderAlign}
                  render={(record: Record | undefined) =>
                    `${new Intl.NumberFormat(locale).format(
                      record?.currentValues.power
                    )}`
                  }
                />
                <TextField source="currentValues.voltageType" />
              </Datagrid>
            </ReferenceArrayField>
          </CardContent>
        </Card>
      </Box>
    </SingleFieldList>
  );
};

const CompanyListView = () => {
  const handleOnRowClick = (
    id: Identifier,
    basePath: string,
    record: Record
  ): any => {
    return record?.resellers.includes(replace4e(config.currentReseller))
      ? `/companies/${id}/show`
      : undefined;
  };
  const postRowStyle = (record: Record) => ({
    cursor: record?.resellers.includes(replace4e(config.currentReseller))
      ? "pointer"
      : "context-menu",
  });
  return (
    <Datagrid rowClick={handleOnRowClick} rowStyle={postRowStyle}>
      <TextField source="businessName" />
      <TextField source="vatNumber" />
      <TextField source="legalAddress.address" sortable={false} />
      <TextField source="legalAddress.city.name" sortable={false} />
      {AdminAndNonLeafResellerView(
        <TextField source="currentContract.dispatchingType" sortable={false} />
      )}
      {AdminAndNonLeafResellerView(
        <TextArrayField source="resellers" sortable={false} />
      )}

      <ReferenceArrayField
        reference="plants"
        source="contractualPlants"
        sort={{ field: "upCode", order: "ASC" }}
        sortable={false}
      >
        <SingleFieldList linkType="show">
          <Box>
            <ChipField
              source="upCode"
              size="small"
              color="primary"
              clickable={true}
            />
          </Box>
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  );
};
