import { useRecordContext } from 'react-admin';
import { GenericFilterComponent } from '../../../lib/custom-useful-components/GenericFilterComponent';

export enum EditableStatus {
  None = 'None',
  Open = 'Open',
  Rejected = 'Rejected',
}

export function EditableStatusViewNew<T>({
  children,
  editableStatus,
  field,
}: {
  children: React.ReactElement;
  editableStatus: T[];
  field: string;
}) {
  const record = useRecordContext();
  const isEditable = Object.values(
    editableStatus ? editableStatus : EditableStatus
  ).includes(record[field]);
  return (
    <GenericFilterComponent filter={() => isEditable}>
      {children}
    </GenericFilterComponent>
  );
}
