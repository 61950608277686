import {
  Create,
  DateInput,
  ReferenceInput,
  ResourceComponentProps,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  required,
  useTranslate
} from 'react-admin';

const PostCreateToolbar = (props: ToolbarProps) => {
  const handleTransform = (data: any) => {
    return {...data};
  };
  return (
    <Toolbar {...props}>
      <SaveButton transform={handleTransform} />
    </Toolbar>
  );
};

export const GcpForceForecastUpmCreate = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.gcp-force-forecast-upm';
  return (
    <Create {...props}>
      <SimpleForm
        redirect="show"
        variant="standard"
        toolbar={<PostCreateToolbar />}
      >
        <ReferenceInput
          validate={required()}
          label={translate(`${tFP}.fields.gcpName`)}
          source="gridConnectionPoint"
          allowEmpty={false}
          filterToQuery={(q) => ({ filter: { name: q } })}
          reference="grid-connection-points"
          disabled
          className="editCreateForm"
        >
          <SelectInput optionText="resourceName"/>
        </ReferenceInput>
        <ReferenceInput
          validate={required()}
          label={translate(`${tFP}.fields.exergyId`)}
          source="gridConnectionPoint"
          allowEmpty={false}
          filterToQuery={(q) => ({ filter: { name: q } })}
          reference="grid-connection-points"
          disabled
          className="editCreateForm"
        >
          <SelectInput optionText="exergyId"/>
        </ReferenceInput>
        <DateInput
          source="startDate"
          label={translate(`${tFP}.fields.startDate`)}
          validate={[required()]}
          className="editCreateForm"
        />
        <DateInput
          source="endDate"
          label={translate(`${tFP}.fields.endDate`)}
          validate={[required()]}
          className="editCreateForm"
        />
      </SimpleForm>
    </Create>
  );
};
