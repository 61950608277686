import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { AppRegistration, Send } from '@mui/icons-material';
import React from 'react';
import {
  DateField,
  EditButton,
  FunctionField,
  Labeled,
  Record,
  ReferenceField,
  ResourceComponentProps,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { useAxios } from '../../lib/custom-hooks/useAxios';
import { TextFieldsCard } from '../../lib/mui_lib/text-fields-card';
import { ButtonField } from '../../lib/react-admin_lib/button-field';
import { LabeledField } from '../../lib/react-admin_lib/labeled-field';
import { UvamMarketTypes } from '../../models/uvam';
import {
  EditableStatus,
  EditableStatusView,
} from '../grid-connection-points/status-management-components/EditableStatusView';
import { Attachments } from './uvam-resource-tabs/Attachments';
import { ConnectionParameters } from './uvam-resource-tabs/ConnectionParameters';
import { ConnectionPoints } from './uvam-resource-tabs/ConnectionPoints';
import { PqTests } from './uvam-resource-tabs/PqTests';
import { BaselineManagement } from './uvam-resource-tabs/BaselineManagement';

const MarketArrayField = ({ source }: { source: string }) => {
  const record = useRecordContext();
  const recordMarketTypes = record[source].sort(
    (a: UvamMarketTypes, b: UvamMarketTypes) =>
      a.marketType.localeCompare(b.marketType)
  );
  const translate = useTranslate();
  const tFP = 'resources.uvam';

  return (
    <Box>
      {recordMarketTypes.map((item: any, index: number) => (
        <Typography variant="body2" key={index}>
          {translate(`${tFP}.marketTypes.${item.marketType}`)}
        </Typography>
      ))}
    </Box>
  );
};

const ActionButtons = () => {
  const translate = useTranslate();
  const { refetch } = useAxios();
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = React.useState(false);
  const tFP = 'resources.uvam';

  const handleOnSendClick = async (
    record: Record | undefined,
    currentStatus: EditableStatus
  ) => {
    const gotoStatus =
      currentStatus === EditableStatus.None ? 'Registered' : 'UnderReview';
    setLoading(true);
    const res = await refetch({
      url: `/uvam/${record!.id}/status/${gotoStatus}`,
      method: 'POST',
    });
    if (res) {
      const { result, error } = res;
      if (result?.data) {
        refresh();
        notify(`${tFP}.status_updated`, 'success');
        setLoading(false);
      }
      if (error) {
        notify(`error: ${(error as any).message}`, 'error');
        setLoading(false);
      }
    }
  };
  return (
    <Box display="flex" justifyContent="right">
      <FunctionField
        source="currentStatus"
        render={(r: Record | undefined) => {
          return (
            <>
              <EditableStatusView>
                <Box display="flex" flex-direction="row" pt={3} pb={2}>
                  <Box pl={1} pr={1}>
                    <EditButton record={r} basePath="/uvam" />
                  </Box>
                  <Box
                    pr={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ButtonField
                      disabled={loading}
                      label={
                        r!.currentStatus === EditableStatus.None
                          ? translate(`${tFP}.register`)
                          : translate(`${tFP}.review`)
                      }
                      onClick={() => handleOnSendClick(r!, r!.currentStatus)}
                    >
                      <>
                        {r!.currentStatus === EditableStatus.None &&
                        !loading ? (
                          <AppRegistration />
                        ) : loading ? (
                          <CircularProgress size={15} color="inherit" />
                        ) : (
                          <Send />
                        )}
                      </>
                    </ButtonField>
                  </Box>
                </Box>
              </EditableStatusView>
            </>
          );
        }}
      />
    </Box>
  );
};

const PostTitle = ({ record }: Record) => {
  const translate = useTranslate();
  const tFP = 'resources.uvam';
  return (
    <span>
      {translate(`${tFP}.name`, 0)}
      {record && record.upCode ? ` -  ${record.upCode}` : ''}
    </span>
  );
};

export const UvamShow = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.uvam';

  return (
    <Show title={<PostTitle id={''} />} {...props} actions={<ActionButtons />}>
      <>
        <SimpleShowLayout>
          <Grid container spacing={6}>
            <TextFieldsCard title={translate(`${tFP}.fieldGroups.status`)}>
              <Box display="flex" width="190px">
                <Labeled label={translate(`${tFP}.fields.currentStatus`)}>
                  <FunctionField
                    source="currentStatus"
                    render={(r: Record | undefined) =>
                      translate(`${tFP}.status.${r!.currentStatus}`)
                    }
                    label={translate(`${tFP}.fields.currentStatus`)}
                  />
                </Labeled>
              </Box>
              <FunctionField
                source="lastErrorMessage"
                render={(r: Record | undefined) =>
                  r!.lastErrorMessage ? (
                    <LabeledField
                      source="lastErrorMessage"
                      label={translate(`${tFP}.fields.lastErrorMessage`)}
                      emptyText={translate(`empty`)}
                    />
                  ) : null
                }
              />
            </TextFieldsCard>
            <TextFieldsCard title={translate(`${tFP}.fieldGroups.uvam`)}>
              <LabeledField
                source="upCode"
                emptyText={translate(`empty`)}
                label={translate(`${tFP}.fields.upCode`)}
              />
              <LabeledField
                source="name"
                emptyText={translate(`empty`)}
                label={translate(`${tFP}.fields.name`)}
              />
              <Box display="flex" width="190px">
                <Labeled label={translate(`${tFP}.fields.createdAt`)}>
                  <DateField
                    source="createdAt"
                    emptyText={translate(`empty`)}
                  />
                </Labeled>
              </Box>
              <Box display="flex" width="190px">
                <LabeledField
                  source="perimeter"
                  emptyText={translate(`empty`)}
                  label={translate(`${tFP}.fields.perimeter`)}
                />
              </Box>
              <Box display="flex" width="190px">
                <LabeledField
                  source="exergyId"
                  emptyText={translate(`empty`)}
                  label={translate(`${tFP}.fields.exergyId`)}
                />
              </Box>
            </TextFieldsCard>
            <TextFieldsCard title={translate(`${tFP}.fieldGroups.gridVoltage`)}>
              <LabeledField
                source="minimumQualifiedPower"
                emptyText={translate(`empty`)}
                label={translate(`${tFP}.fields.minimumQualifiedPower`)}
              />
              <LabeledField
                source="maximumQualifiedPower"
                emptyText={translate(`empty`)}
                label={translate(`${tFP}.fields.maximumQualifiedPower`)}
              />
            </TextFieldsCard>
            <TextFieldsCard
              title={translate(`${tFP}.fieldGroups.scWebOperator`)}
            >
              <LabeledField
                source="scWebOperatorEic"
                emptyText={translate(`empty`)}
                label={translate(`${tFP}.fields.scWebOperatorEic`)}
              />
              <LabeledField
                source="scWebOperatorName"
                emptyText={translate(`empty`)}
                label={translate(`${tFP}.fields.scWebOperatorName`)}
              />
            </TextFieldsCard>
            <TextFieldsCard
              title={translate(`${tFP}.fieldGroups.qualification`)}
            >
              <Labeled label={translate(`${tFP}.fields.technicalTestsMinDate`)}>
                <FunctionField
                  render={(r: Record | undefined) =>
                    new Date(
                      new Date(r!.createdAt).getTime() + 2 * 24 * 60 * 60 * 1000
                    ).toLocaleDateString()
                  }
                />
              </Labeled>
              <Labeled label={translate(`${tFP}.fields.msdEnablingMinDate`)}>
                <FunctionField
                  render={(r: Record | undefined) =>
                    new Date(
                      new Date(r!.createdAt).getTime() + 2 * 24 * 60 * 60 * 1000
                    ).toLocaleDateString()
                  }
                />
              </Labeled>
            </TextFieldsCard>
            <TextFieldsCard title={translate(`${tFP}.fieldGroups.marketTypes`)}>
              <MarketArrayField source="marketTypes" />
            </TextFieldsCard>
            <TextFieldsCard title={translate(`${tFP}.fieldGroups.other`)}>
              <ReferenceField reference="bsp" source="bsp" link="show">
                <LabeledField source="name" label="Bsp" emptyText="-" />
              </ReferenceField>
            </TextFieldsCard>
          </Grid>
        </SimpleShowLayout>
        <Box pt={4} />
        <TabbedShowLayout>
          <Tab label={translate(`${tFP}.tabs.cp.name`)} path="">
            <ConnectionPoints />
          </Tab>
          <Tab
            label={translate(`${tFP}.tabs.connection_parameters.name`)}
            path="connection-parameters"
          >
            <ConnectionParameters />
          </Tab>
          <Tab
            label={translate(`${tFP}.tabs.attachments.name`)}
            path="attachments"
          >
            <Attachments />
          </Tab>
          <Tab label={translate(`${tFP}.tabs.pq_tests.name`)} path="pq-tests">
            <PqTests />
          </Tab>
          <Tab
            label={translate(`${tFP}.tabs.baselineManagement.name`)}
            path="baseline-management"
          >
            <BaselineManagement />
          </Tab>
        </TabbedShowLayout>
      </>
    </Show>
  );
};
