import React from 'react';
import _ from 'lodash';
import moment from 'moment';

export enum TimeSlotGroup {
  Baseload = 'BASELOAD',
  Pop = 'POP',
  F123 = 'F123',
}

enum BaseloadGroup {
  B = 'B',
}

enum POPGroup {
  P = 'P',
  OP = 'OP',
}

enum F123Group {
  F1 = 'F1',
  F2 = 'F2',
  F3 = 'F3',
}

export interface TimeSlot {
  [TimeSlotGroup.Baseload]: BaseloadGroup;
  [TimeSlotGroup.Pop]: POPGroup;
  [TimeSlotGroup.F123]: F123Group;
}

export enum Market {
  Mgp = 'MGP',
  Mi1 = 'MI1',
  Mi2 = 'MI2',
  Mi3 = 'MI3',
  Mi4 = 'MI4',
  Mi5 = 'MI5',
  Mi6 = 'MI6',
  Mi7 = 'MI7',
}

export enum Zone {
  Brnn = 'BRNN',
  Cala = 'CALA',
  Cnor = 'CNOR',
  Csud = 'CSUD',
  Fogn = 'FOGN',
  Mftv = 'MFTV',
  Nord = 'NORD',
  Prgp = 'PRGP',
  Pun = 'PUN',
  Rosn = 'ROSN',
  Sard = 'SARD',
  Sici = 'SICI',
  Sud = 'SUD',
}

export type TAverageIpexResponse = {
  [k1 in TimeSlotGroup]: {
    [key in Market]: {
      [key in Zone]: {
        [key in TimeSlot[k1]]: {
          timeFrame: string;
          year: number;
          month: number;
          market: Market;
          zone: Zone;
          price: number;
          timeFrameGroup: string;
        }[];
      };
    };
  };
};

const averageIpexGraphInitialData = (
  currentTimeSlot: TimeSlotGroup,
  currentMarket: Market,
  currentZone: Zone,
  response?: TAverageIpexResponse
) => {
  const timeSlotsList = _.sortBy(
    _.map(response, function (value, key) {
      return { id: key, name: key };
    }),
    ['name', 'id']
  );
  const marketList =
    response &&
    _.sortBy(
      _.map(response[currentTimeSlot], function (value, key) {
        return { id: key, name: key };
      }),
      ['name', 'id']
    );

  const zoneList =
    response &&
    _.sortBy(
      _.map(response[currentTimeSlot][currentMarket], function (value, key) {
        return { id: key, name: key };
      }),
      ['name', 'id']
    );

  const arrays =
    response &&
    _.map(
      response[currentTimeSlot][currentMarket][currentZone],
      (value, key) => {
        return value;
      }
    );
  const zipped =
    arrays &&
    _.zip(...arrays).map((tuple: any) =>
      tuple.reduce(
        (acc: any, o: any) =>
          Object.assign(acc, {
            [o.timeFrame]: Number.parseFloat(o.price),
          }),
        {
          year: tuple[0].year,
          month: tuple[0].month,
          yearmonth: tuple[0].year + '-' + tuple[0].month,
        }
      )
    );
  const minDate = new Date(
    zipped && zipped[0]?.year,
    zipped && zipped[0]?.month
  );

  const initialStartDate = new Date(new Date().getFullYear() - 1, 0, 1);
  const initialEndDate = new Date();
  const maxDate = initialEndDate;

  return {
    data: zipped,
    minDate,
    maxDate,
    initialEndDate,
    initialStartDate,
    timeSlotsList,
    marketList,
    zoneList,
  };
};

export const useAverageIpexGraph = (
  response: TAverageIpexResponse,
  initialTimeSlot: TimeSlotGroup,
  initialMarket: Market,
  initialZone: Zone
) => {
  const {
    data: initialData,
    initialStartDate,
    initialEndDate,
    minDate,
    maxDate,
    timeSlotsList: ipexAveragetimeSlotsList,
    marketList,
    zoneList,
  } = averageIpexGraphInitialData(
    initialTimeSlot,
    initialMarket,
    initialZone,
    response
  );

  const [data, setData] = React.useState(initialData);
  const [startDate, setStartDate] = React.useState(initialStartDate);
  const [endDate, setEndDate] = React.useState(initialEndDate);
  const [ipexAveragecurrentTimeSlot, setIpexAverageCurrentTimeSlot] =
    React.useState(initialTimeSlot);
  const [ipexAveragemarketList, setIpexAverageMarketList] =
    React.useState(marketList);
  const [ipexAveragecurrentMarket, setIpexAverageCurrentMarket] =
    React.useState(initialMarket);
  const [ipexAveragezoneList, setIpexAverageZoneList] =
    React.useState(zoneList);
  const [ipexAveragecurrentZone, setIpexAverageCurrentZone] =
    React.useState(initialZone);
  const handleIpexAverageDateInitialChange = (e: any) => {
    setStartDate(e);
  };
  const handleIpexAverageDateFinalChange = (e: any) => {
    setEndDate(e);
  };
  const handleIpexAverageCurrentTimeSlotChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: TimeSlotGroup;
    }>
  ) => {
    setIpexAverageCurrentTimeSlot(event.target.value);
    const SetMarKetList = _.map(response[event.target.value], (value, key) => {
      return { id: key, name: key };
    });
    const SetZonaList = _.map(
      response[event.target.value][ipexAveragecurrentMarket],
      function (value, key) {
        return { id: key, name: key };
      }
    );
    setIpexAverageMarketList(SetMarKetList);
    setIpexAverageZoneList(SetZonaList);
  };
  const handleIpexAverageCurrentMarketChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: Market;
    }>
  ) => {
    const getDefaultZoneByMarket = (market: Market) =>
      market === Market.Mgp ? Zone.Pun : Zone.Nord;
    const nextMarket = event.target.value;
    setIpexAverageCurrentMarket(nextMarket);
    const ZoneList = _.map(
      response[ipexAveragecurrentTimeSlot][nextMarket],
      function (value, key) {
        return { id: key, name: key };
      }
    );
    setIpexAverageZoneList(ZoneList);

    if (!ZoneList.some((zone) => zone.name === ipexAveragecurrentZone)) {
      setIpexAverageCurrentZone(getDefaultZoneByMarket(nextMarket));
    }
  };
  const handleIpexAverageZoneChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: Zone;
    }>
  ) => {
    setIpexAverageCurrentZone(event.target.value);
  };

  React.useEffect(() => {
    const loadUpdatedData = (response: any): any => {
      const { data, initialStartDate, initialEndDate, marketList, zoneList } =
        averageIpexGraphInitialData(
          ipexAveragecurrentTimeSlot,
          ipexAveragecurrentMarket,
          ipexAveragecurrentZone,
          response
        );

      const updatedData =
        data &&
        data.filter((a) => {
          const date = new Date(a.yearmonth);
          return moment(date).isBetween(startDate, endDate, 'M', '[]');
        });

      setIpexAverageMarketList(marketList);
      setIpexAverageZoneList(zoneList);
      setData(updatedData);
      if (!startDate) {
        setStartDate(initialStartDate);
      }
      if (!endDate) {
        setEndDate(initialEndDate);
      }
    };
    loadUpdatedData(response);
  }, [
    response,
    ipexAveragecurrentTimeSlot,
    ipexAveragecurrentMarket,
    ipexAveragecurrentZone,
    startDate,
    endDate,
  ]);

  return {
    data,
    minDate,
    maxDate,
    startDate,
    endDate,
    handleIpexAverageDateInitialChange,
    handleIpexAverageDateFinalChange,
    handleIpexAverageZoneChange,
    handleIpexAverageCurrentTimeSlotChange,
    handleIpexAverageCurrentMarketChange,
    ipexAveragetimeSlotsList,
    ipexAveragemarketList,
    ipexAveragezoneList,
    ipexAveragecurrentTimeSlot,
    ipexAveragecurrentMarket,
    ipexAveragecurrentZone,
  };
};
