import { config } from '@config/config';
import MomentUtils from '@date-io/moment';
import { createMuiTheme } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BusinessIcon from '@material-ui/icons/Business';
import EuroIcon from '@material-ui/icons/Euro';
import ProductIcon from '@material-ui/icons/Inventory';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DifferenceIcon from '@mui/icons-material/Difference';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import GridConnectionPointCustomContractIcon from '@mui/icons-material/Gavel';
import CustomContractIcon from '@mui/icons-material/Handshake';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import React from 'react';
import { Admin, LegacyDataProvider, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import { Dashboard } from './layout/Dashboard';
import { CustomLayout } from './layout/Layout';
import { useDarkTheme } from './lib/custom-hooks/store-dark-theme';
import { AverageIpexGraph } from './pages/average-ipex-graph';
import { HoursIpexGraph } from './pages/hours-ipex-graph';
import { Settings } from './pages/settings';
import authProvider, { keycloak } from './providers/authProvider';
import dataProvider from './providers/dataProvider';
import i18nProvider from './providers/i18nProvider';
import { BspList } from './resources/bsp/BspList';
import { CompanyCreate } from './resources/companies/CompanyCreate';
import { CompanyList } from './resources/companies/CompanyList';
import { CompanyShow } from './resources/companies/CompanyShow';
import { ContractList } from './resources/contracts/ContractList';
import { GenerationUnitCreate } from './resources/generation-units/GenerationUnitCreate';
import { GenerationUnitEdit } from './resources/generation-units/GenerationUnitEdit';
import { GridConnectionPointCreate } from './resources/grid-connection-points/GridConnectionPointCreate';
import { GridConnectionPointEdit } from './resources/grid-connection-points/GridConnectionPointEdit';
import { GridConnectionPointList } from './resources/grid-connection-points/GridConnectionPointList';
import { GridConnectionPointShow } from './resources/grid-connection-points/GridConnectionPointShow';
import { InvoiceList } from './resources/invoices/InvoiceList';
import { PlantList } from './resources/plants/PlantList';
import { PlantShow } from './resources/plants/PlantShow';
import { UvamConnectionParametersCreate } from './resources/uvam-connection-parameters/UvamConnectionParametersCreate';
import { UvamConnectionParametersEdit } from './resources/uvam-connection-parameters/UvamConnectionParametersEdit';
import { UvamPqTestsCreate } from './resources/uvam-pq-tests/UvamPqTestsCreate';
import { UvamCreate } from './resources/uvam/UvamCreate';
import { UvamEdit } from './resources/uvam/UvamEdit';
import { UvamList } from './resources/uvam/UvamList';
import { UvamShow } from './resources/uvam/UvamShow';

import { GcpForceForecastUpmCreate } from './pages/gcp-force-forecast-upm/GcpForceForecastUpmCreate';
import { GcpForceForecastUpmEdit } from './pages/gcp-force-forecast-upm/GcpForceForecastUpmEdit';
import { GcpForceForecastUpmList } from './pages/gcp-force-forecast-upm/GcpForceForecastUpmList';
import { GcpForceForecastUpmShow } from './pages/gcp-force-forecast-upm/GcpForceForecastUpmShow';
import { GridConnectionPointAbilitationNoProductCreate } from './pages/grid-connection-point-abilitation-no-product/GridConnectionPointAbilitationNoProductCreate';
import { GridConnectionPointAbilitationNoProductEdit } from './pages/grid-connection-point-abilitation-no-product/GridConnectionPointAbilitationNoProductEdit';
import { GridConnectionPointAbilitationNoProductList } from './pages/grid-connection-point-abilitation-no-product/GridConnectionPointAbilitationNoProductList';
import { GridConnectionPointAbilitationNoProductShow } from './pages/grid-connection-point-abilitation-no-product/GridConnectionPointAbilitationNoProductShow';
import { GridConnectionPointForceAvailabilityCreate } from './pages/grid-connection-point-force-availabilities/GridConnectionPointForceAvailabilityCreate';
import { GridConnectionPointForceAvailabilityEdit } from './pages/grid-connection-point-force-availabilities/GridConnectionPointForceAvailabilityEdit';
import { GridConnectionPointForceAvailabilityList } from './pages/grid-connection-point-force-availabilities/GridConnectionPointForceAvailabilityList';
import { GridConnectionPointForceAvailabilityShow } from './pages/grid-connection-point-force-availabilities/GridConnectionPointForceAvailabilityShow';
import { UvamActivationStatusCreate } from './pages/uvam-activation-statuses/UvamActivationStatusCreate';
import { UvamActivationStatusEdit } from './pages/uvam-activation-statuses/UvamActivationStatusEdit';
import { UvamActivationStatusList } from './pages/uvam-activation-statuses/UvamActivationStatusList';
import { UvamActivationStatusShow } from './pages/uvam-activation-statuses/UvamActivationStatusShow';
import { UvamForceAvailabilityCreate } from './pages/uvam-force-availabilities/UvamForceAvailabilityCreate';
import { UvamForceAvailabilityEdit } from './pages/uvam-force-availabilities/UvamForceAvailabilityEdit';
import { UvamForceAvailabilityList } from './pages/uvam-force-availabilities/UvamForceAvailabilityList';
import { UvamForceAvailabilityShow } from './pages/uvam-force-availabilities/UvamForceAvailabilityShow';
import { BspCreate } from './resources/bsp/BspCreate';
import { BspShow } from './resources/bsp/BspShow';
import { CustomContractCreate } from './resources/custom-contracts/CustomContractCreate';
import { CustomContractEdit } from './resources/custom-contracts/CustomContractEdit';
import { CustomContractList } from './resources/custom-contracts/CustomContractList';
import { CustomContractShow } from './resources/custom-contracts/CustomContractShow';
import { GridConnectionPointCustomContractCreate } from './resources/grid-connection-point-custom-contracts/GridConnectionPointCustomContractCreate';
import { GridConnectionPointCustomContractEdit } from './resources/grid-connection-point-custom-contracts/GridConnectionPointCustomContractEdit';
import { GridConnectionPointCustomContractList } from './resources/grid-connection-point-custom-contracts/GridConnectionPointCustomContractList';
import { GridConnectionPointCustomContractShow } from './resources/grid-connection-point-custom-contracts/GridConnectionPointCustomContractShow';
import { ProductCreate } from './resources/products/ProductCreate';
import { ProductEdit } from './resources/products/ProductEdit';
import { ProductList } from './resources/products/ProductList';
import { ProductShow } from './resources/products/ProductShow';

const pages = [
  <Route
    key="average-ipex-graph"
    path="/average-ipex-graph"
    component={AverageIpexGraph}
  />,
  <Route
    key="hours-ipex-graph"
    path="/hours-ipex-graph"
    component={HoursIpexGraph}
  />,
  <Route key="settings" path="/settings" component={Settings} />,
];
const App = () => {
  const { theme: environmentTheme } = useDarkTheme();
  const theme = createMuiTheme(environmentTheme);

  React.useEffect(() => {
    const favico = () => {
      const favicon: any = document.getElementById('favicon');
      favicon.href = config.favicon;
      document.title = config.title;
    };
    favico();
  }, []);
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: 'login-required' }}
      LoadingComponent={<div>Loading...</div>}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Admin
          authProvider={authProvider}
          dataProvider={dataProvider as unknown as LegacyDataProvider}
          i18nProvider={i18nProvider}
          layout={CustomLayout}
          dashboard={Dashboard}
          customRoutes={pages}
          theme={theme}
        >
          {(permissions: any) => [
            <Resource
              name="companies"
              list={CompanyList}
              show={CompanyShow}
              create={permissions('admin') ? CompanyCreate : undefined}
              icon={BusinessIcon}
            />,
            <Resource name="invoices" list={InvoiceList} icon={EuroIcon} />,
            <Resource
              name="contracts"
              list={ContractList}
              icon={AssignmentIcon}
            />,
            <Resource
              name="plants"
              list={PlantList}
              show={PlantShow}
              icon={SolarPowerIcon}
            />,
            <Resource
              name="grid-connection-points"
              list={GridConnectionPointList}
              create={GridConnectionPointCreate}
              edit={GridConnectionPointEdit}
              show={GridConnectionPointShow}
              icon={ElectricMeterIcon}
            />,
            <Resource
              name="generation-units"
              create={GenerationUnitCreate}
              edit={GenerationUnitEdit}
            />,
            <Resource
              name="uvam"
              list={UvamList}
              create={UvamCreate}
              edit={UvamEdit}
              show={UvamShow}
              icon={DifferenceIcon}
            />,
            <Resource
              name="uvam-connections"
              create={UvamConnectionParametersCreate}
              edit={UvamConnectionParametersEdit}
            />,
            <Resource name="pq-tests" create={UvamPqTestsCreate} />,

            <Resource
              name="bsp"
              list={BspList}
              create={BspCreate}
              // edit={BspEdit}
              show={BspShow}
              icon={DonutLargeIcon}
            />,

            <Resource
              name="products"
              list={ProductList}
              create={ProductCreate}
              edit={ProductEdit}
              show={ProductShow}
              icon={ProductIcon}
            />,

            <Resource
              name="uvam-activation-statuses"
              list={UvamActivationStatusList}
              create={UvamActivationStatusCreate}
              edit={UvamActivationStatusEdit}
              show={UvamActivationStatusShow}
            />,

            <Resource
              name="uvam-force-availabilities"
              list={UvamForceAvailabilityList}
              create={UvamForceAvailabilityCreate}
              edit={UvamForceAvailabilityEdit}
              show={UvamForceAvailabilityShow}
            />,

            <Resource
              name="grid-connection-point-force-availabilities"
              list={GridConnectionPointForceAvailabilityList}
              create={GridConnectionPointForceAvailabilityCreate}
              edit={GridConnectionPointForceAvailabilityEdit}
              show={GridConnectionPointForceAvailabilityShow}
            />,

            <Resource
              name="grid-connection-point-abilitation-no-product"
              list={GridConnectionPointAbilitationNoProductList}
              create={GridConnectionPointAbilitationNoProductCreate}
              edit={GridConnectionPointAbilitationNoProductEdit}
              show={GridConnectionPointAbilitationNoProductShow}
            />,

            <Resource
              name="gcp-force-forecast-upm"
              list={GcpForceForecastUpmList}
              create={GcpForceForecastUpmCreate}
              edit={GcpForceForecastUpmEdit}
              show={GcpForceForecastUpmShow}
            />,

            <Resource
              name="custom-contracts"
              list={CustomContractList}
              create={CustomContractCreate}
              edit={CustomContractEdit}
              show={CustomContractShow}
              icon={CustomContractIcon}
            />,

            <Resource
              name="grid-connection-point-custom-contracts"
              list={GridConnectionPointCustomContractList}
              create={GridConnectionPointCustomContractCreate}
              edit={GridConnectionPointCustomContractEdit}
              show={GridConnectionPointCustomContractShow}
              icon={GridConnectionPointCustomContractIcon}
            />,

            // aggiungo qui le risorse e le azioni che posso svolgere
            <Resource name="sc-web-operator" />, // posso anche non avere azioni (magari mi serve per un autocomplete)
          ]}
        </Admin>
      </MuiPickersUtilsProvider>
    </ReactKeycloakProvider>
  );
};

export default App;
