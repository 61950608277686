import React from 'react';

export const GenericFilterComponent = ({
  filter,
  children,
  empty,
}: {
  filter: () => boolean;
  children: React.ReactElement;
  empty?: React.ReactElement;
}): React.ReactElement => {
  return filter() ? children : empty ? empty : <></>;
};
