/* eslint-disable eqeqeq */
import { Box, Typography, makeStyles } from '@material-ui/core';
import {
  BooleanField,
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  FunctionField,
  Record,
  ResourceComponentProps,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useLocale,
  useRecordContext,
  useTranslate
} from 'react-admin';
import { TextFieldsCard } from 'src/lib/mui_lib/text-fields-card';

const useStyles = makeStyles({
  chipfieldColor: {
    color: '#ffffff',
  },
  fieldHeaderAlign: {
    textAlign: 'right',
  },
});

const ActivationDaysField = ({ source }: { source: string }) => {
  const record = useRecordContext();
  const translate = useTranslate();
  const tFP = 'resources.weekDays';
  const selectedDays = record[source] !== undefined ? 
    record[source].map((day:any) => {
      switch (day) {
        case 1:
          return translate(`${tFP}.monday`);
        case 2:
          return translate(`${tFP}.tuesday`);
        case 3:
          return translate(`${tFP}.wednesday`);
        case 4:
          return translate(`${tFP}.thursday`);
        case 5:
          return translate(`${tFP}.friday`);
        case 6:
          return translate(`${tFP}.saturday`);
        case 7:
          return translate(`${tFP}.sunday`);
        default:
          return translate(`${tFP}.none`);
      }
    }) : [] ;

  return (
    <Box>
      {selectedDays !== undefined && (selectedDays.map((day: string, index: number) => (
        <Typography variant="body2" key={index}>
          {day}
        </Typography>
      )))}
      {(!selectedDays || selectedDays.length == 0) && (
        <Typography variant="body2">
          {translate(`${tFP}.none`)}
        </Typography>
      )}
    </Box>
  );
};

const ActionButtons = (props: any) => {
  const { basePath, data, resource } = props;
  const translate = useTranslate();
  const tFP = 'resources.products';
  return(
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
      {data && typeof data.id !== 'undefined' && ( 
        <DeleteWithConfirmButton 
          basePath={basePath} 
          record={data} 
          resource={resource} 
          // redirect={`/grid-connection-points/${data.gridConnectionPoint}/show`}
          confirmContent={translate(`${tFP}.deleteConfirmMessage`)}/>
      )}
    </TopToolbar>
  );
};

export const ProductShow = (props: ResourceComponentProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  const locale = useLocale();
  const tFP = 'resources.products';
  return (
    <Show {...props} actions={<ActionButtons />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <DateField source="startDate" />
        <TextField source="type" />
        <TextField source="startTime" />
        <TextField source="endTime" />
        <BooleanField source="holiday" />
        <FunctionField
          align="right"
          textAlign="right"
          headerClassName={classes.fieldHeaderAlign}
          label={translate(`${tFP}.fields.strikePrice`)}
          render={(record: Record | undefined) =>
            `${new Intl.NumberFormat(locale, {
              style: 'currency',
              currency: 'EUR',
            }).format(record?.strikePrice)}`
          }
        />
        <TextFieldsCard title={translate(`${tFP}.fields.activationDays`)}>
          <ActivationDaysField source="weekDaysIds" />
        </TextFieldsCard>
        <DateField source="endDate" />
        <TextField source="productCode" />
      </SimpleShowLayout>
    </Show>
  );
};
