import {
  Create,
  required,
  ResourceComponentProps,
  SimpleForm,
  TextInput,
} from 'react-admin';

export const CompanyCreate = (props: ResourceComponentProps) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="businessName" validate={required()} />
      </SimpleForm>
    </Create>
  );
};
