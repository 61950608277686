import React from 'react';
import { useGetPermissions } from 'react-admin';

export const useUserHasPermissions = (
  checkPermissions: string | string[]
): boolean => {
  const [permissions, setPermissions] = React.useState(false);
  const getPermissions = useGetPermissions();
  React.useEffect(() => {
    getPermissions().then((permissions) => {
      if (Array.isArray(checkPermissions)) {
        const grantAccess = checkPermissions.reduce(
          (boolean, role) => permissions(role) === true || boolean,
          false
        );
        setPermissions(grantAccess);
      }
      if (!Array.isArray(checkPermissions)) {
        const grantAccess = permissions(checkPermissions);
        setPermissions(grantAccess);
      }
    });
  }, [checkPermissions, getPermissions]);
  return permissions;
};
