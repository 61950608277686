import { Box, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormState } from 'react-final-form';

import {
  AutocompleteInput,
  AutocompleteInputProps,
  Edit,
  FunctionField,
  InputProps,
  NumberInput,
  Record,
  ReferenceInput,
  ResourceComponentProps,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  maxValue,
  minValue,
  number,
  regex,
  required,
  useInput,
  useTranslate
} from 'react-admin';

import { AutocompleteProps } from '@mui/material';
import { useQuery } from '../../lib/custom-hooks/use-quey';
import { TextFieldsCard } from '../../lib/mui_lib/text-fields-card';
import { TextInput } from '../../lib/react-admin_lib/labeled-field';
import { GridConnectionPoint } from '../../models/grid-connection-point';
import { UvamStatus } from '../../models/uvam-status';
import {
  brpOptions,
  gridVoltageOptions,
  locationOptions,
} from './gcpConstants';

import { CreateDistributor } from './gcp-resource-tabs/CreateDistributor';
import { EditableStatusViewNew } from './status-management-components/EditableStatusViewNew';

const validatePower = [required(), number(), minValue(0)];

const PostTitle = ({ record }: Record) => {
  const translate = useTranslate();
  const tFP = 'resources.grid-connection-points';
  return (
    <span>
      {translate(`${tFP}.name`, 0)}
      {record?.meteringPointId ? ` - ${record.meteringPointId}` : ''}
    </span>
  );
};
const UserEditToolbar = (props: ToolbarProps) => {
  const transform = (data: GridConnectionPoint): GridConnectionPoint => ({
    ...data
  });
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} transform={transform} />
    </Toolbar>
  );
};

// TODO: tooooo many any
const CustomAutoCompleteField = <T, Multiple extends boolean | undefined = undefined, DisableClearable extends boolean | undefined = undefined, FreeSolo extends boolean | undefined = undefined>(props: Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'options' | 'renderInput'> & {
  choices: { id: string; name: string }[];
  label: string;
  source: string;
  emptyText?: string;
  variant?: string;
}) => {
  const { choices, label, ...rest } = props;
  const {
    input: { value, onChange },
  } = useInput(props as InputProps<string>) as {
    input: { value: any; onChange: (value: string) => void };
  };

  const tokens = value.split(' ');
  const checkTokens = (key: string) => tokens.every((token: string) => {
    return key?.toLowerCase().indexOf(token.toLowerCase()) >= 0
  });

  const filteredChoices =
    (choices && choices.length > 0
      ? choices.filter((item) => checkTokens(`${item.id} - ${item.name}`))
      : []);
  return (
    <Box mt={0.8}>
      <Autocomplete
        id="free-solo-demo"
        freeSolo
        // @ts-expect-error
        options={
          filteredChoices.map((option) => `${option.id} - ${option.name}`)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => onChange(e.target.name)}
            label={label}
          />
        )}
        value={value.split(' - ')[0]}
        // @ts-expect-error
        onChange={(e, n) => onChange(n?.split(' - ')[0])}
        {...rest}
      />
    </Box>
  );
};

const BrpNameField = (props: any) => {
  const { values } = useFormState();
  const zones = [
    'NORD',
    'CNOR',
    'CSUD',
    'SUD',
    'CALA',
    'SICI',
    'SARD',
  ];


  let findBrpNames: {
    id: string;
    name: string;
  }[] = (zones.map((zone) => {
    return { id: `UC_${values.brpContractId}_${zone}`, name: `UC_${values.brpContractId}_${zone}` };
  }))

  // if (values.brpContractId) {
  //   findBrpNames =
  //     (brpContractOptions
  //       .find((brp) => brp.brpContractId === values.brpContractId)
  //       ?.brpName.map((brpName: string) => {
  //         return { id: brpName, name: brpName };
  //       }))
  //     ?? (zones.map((zone) => {
  //       return { id: `UC_${values.brpContractId}_${zone}`, name: `UC_${values.brpContractId}_${zone}` };
  //     }));
  // }

  return (<Box mt={0.8}>
    <AutocompleteInput freeSolo choices={findBrpNames} {...props} />
  </Box>)
};

export const GridConnectionPointEdit = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.grid-connection-points';
  const brpContractOption = brpOptions.map((value) => {
    return {
      id: value.brpId,
      name: value.brpBusinessName,
    };
  });
  const sortedLocationOptions = locationOptions.sort((a, b) => {
    return Object.entries(a)[0][1].localeCompare(Object.entries(b)[0][1]);
  });

  const DsoInput = (props: AutocompleteInputProps) => {
    const { data, loading } = useQuery('getList', {
      resource: 'distributor',
      payload: {
        pagination: { page: 1, perPage: 100 },
        filter: {},
        sort: { field: 'id', order: 'ASC' },
      }
    });
    return (
      <Box mt={0.8} width={"400px"}>
        <AutocompleteInput
          {...props}
          choices={data}
          freeSolo
          clearAlwaysVisible
          optionText={(dt: any) => {
            if (!dt.id && !dt.name) { return '' }
            if (dt.id === '@@ra-create') { return  translate('ra.action.create')  }
            return `${dt.eic} - ${dt.name}`
          }
          }
          isLoading={loading}
          create={<CreateDistributor />}
          createLabel='create'
          variant="standard"
          filter='contains'
          style={{ minWidth: '300px' }}
          resettable
        />
      </Box>
    );
  }

  return (
    <Edit
      {...props}
      title={<PostTitle id={''} />}
      mutationMode="pessimistic"
      transform={(data: any) => ({
        ...data,
        generationUnits: null,
      })}
    >
      <SimpleForm
        redirect="show"
        toolbar={<UserEditToolbar />}
        variant="standard"
      >
        <Box display="flex" flexDirection="row" minWidth="100%">
          <Grid container spacing={9}>
            <TextFieldsCard title={translate(`${tFP}.fieldGroups.pod`)}>
              <TextInput
                source="meteringPointId"
                label={translate(`${tFP}.fields.meteringPointId`)}
                emptyText={translate(`empty`)}
                variant="standard"
                disabled
              />
              <Box pt={0.5}>
                <FunctionField
                  source="direction"
                  render={(r: Record | undefined) => (
                    <TextField
                      value={translate(`${tFP}.direction.${r!.direction}`)}
                      label={translate(`${tFP}.fields.direction`)}
                      // emptyText={translate(`empty`)}
                      style={{ width: '305px' }}
                      variant="standard"
                      disabled
                    />
                  )}
                />
              </Box>

              <TextInput
                source="resourceName"
                label={translate(`${tFP}.fields.resourceName`)}
                variant="standard"
              />
              <FunctionField
                source="location"
                render={(r: Record | undefined) => (
                  <SelectInput
                    source="location"
                    style={{ width: '305px' }}
                    variant="standard"
                    choices={sortedLocationOptions.map((location, index) => {
                      return {
                        id: Object.entries(location)[0][0],
                        name: Object.entries(location)[0][1],
                      };
                    })}
                    format={(v: any) => (v && v[0] ? v.filter((l: any) => l.endDate === null)[0].locationId : null)}
                    parse={(v: any) => [
                      {
                        locationId: v,
                        startDate: new Date(),
                        endDate: null,
                        gridConnectionPoint: r?.id,
                      },
                    ]}
                  />
                )}
              />
              <SelectInput
                style={{ width: '305px' }}
                variant="standard"
                label={translate(`${tFP}.fields.gridVoltageLevel`)}
                source="gridVoltageLevel"
                choices={gridVoltageOptions.map((level) => {
                  return {
                    id: level,
                    name: level,
                  };
                })}
              />
            </TextFieldsCard>
            <TextFieldsCard title={translate(`${tFP}.fieldGroups.gridVoltage`)}>
              <NumberInput
                source="contractedPower"
                style={{ width: '305px' }}
                variant="standard"
                validate={[number(), minValue(0)]}
                label={translate(`${tFP}.fields.contractedPower`)}
              />
              <NumberInput
                label={translate(`${tFP}.fields.maximumFlexDown`)}
                variant="standard"
                source="maximumFlexDown"
                style={{ width: '305px' }}
                validate={[required(), number(), maxValue(0)]}
              />
              <NumberInput
                source="maximumFlexUp"
                style={{ width: '305px' }}
                variant="standard"
                validate={validatePower}
                label={translate(`${tFP}.fields.maximumFlexUp`)}
              />
            </TextFieldsCard>
            <TextFieldsCard
              title={translate(`${tFP}.fieldGroups.secondaryPod`)}
            >
              <TextInput
                source="accountingPointId"
                variant="standard"
                validate={[
                  regex(
                    /IT[0-9]{3}E[0-9]{8}$/,
                    translate(`${tFP}.fields.meteringPointId_error`)
                  ),
                ]}
                label={translate(`${tFP}.fields.accountingPointId`)}
                emptyText={translate(`empty`)}
              />

              <NumberInput
                source="contractedPowerSecondaryPod"
                label={translate(`${tFP}.fields.contractedPowerSecondaryPod`)}
                validate={[number(), minValue(0)]}
                variant="standard"
                style={{ width: '305px' }}
              />
            </TextFieldsCard>
            <TextFieldsCard
              title={translate(`${tFP}.fieldGroups.associazioneUDD`)}
            >
              <CustomAutoCompleteField
                source="brpContractId"
                label={translate(`${tFP}.fields.brpContractId`)}
                emptyText={translate(`empty`)}
                variant="standard"
                style={{ width: '305px' }}
                choices={brpContractOption}
              />
              <BrpNameField
                source="brpName"
                label={translate(`${tFP}.fields.brpName`)}
                emptyText={translate(`empty`)}
                variant="standard"
                style={{ width: '305px' }}
              />
            </TextFieldsCard>

            <TextFieldsCard
              title={translate(`${tFP}.fieldGroups.distributionOperator`)}
            >
              <DsoInput source="distributor" label='DSO' autoComplete='true' />
            </TextFieldsCard>

            <EditableStatusViewNew
              field="currentStatus"
              editableStatus={[UvamStatus.None]}
            >
              <TextFieldsCard title={translate(`${tFP}.fieldGroups.other`)}>
                <ReferenceInput
                  validate={required()}
                  label="Bsp"
                  source="bsp"
                  allowEmpty={false}
                  filterToQuery={(q) => ({ filter: { name: q } })}
                  reference="bsp"
                >
                  <SelectInput
                    optionText="name"
                    variant="standard"
                    style={{ width: '305px' }}
                  />
                </ReferenceInput>
              </TextFieldsCard>
            </EditableStatusViewNew>
          </Grid>
        </Box>
      </SimpleForm>
    </Edit>
  );
};
