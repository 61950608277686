import {
  Create,
  ReferenceInput,
  regex,
  required,
  ResourceComponentProps,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin';


const PostCreateToolbar = (props: ToolbarProps) => {
  const handleTransform = (data: any) => {

    return {
      ...data,
      bsp: data.bsp && {
        id: data.bsp
      }
    };
  };
  return (
    <Toolbar {...props}>
      <SaveButton transform={handleTransform} />
    </Toolbar>
  );
};

export const GridConnectionPointCreate = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.grid-connection-points';

  return (
    <Create {...props}>
      <SimpleForm redirect="edit" toolbar={<PostCreateToolbar />}>
        <TextInput
          label={translate(`${tFP}.fields.meteringPointId`)}
          variant="standard"
          source="meteringPointId"
          validate={[
            required(),
            regex(
              /IT[0-9]{3}E[0-9]{8}$/,
              translate(`${tFP}.fields.meteringPointId_error`)
            ),
          ]}
        />
        <SelectInput
          variant="standard"
          label={translate(`${tFP}.fields.direction`)}
          source="direction"
          choices={['generation', 'consumption', 'mixed'].map((direction) => {
            return {
              id: direction,
              name: translate(`${tFP}.direction.${direction}`),
            };
          })}
          validate={required()}
        />

        <ReferenceInput validate={required()} label="Bsp" source="bsp" allowEmpty={false} filterToQuery={(q) => ({filter:{businessName: q}})}  reference="bsp">
            <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
