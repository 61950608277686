import { Box, Grid } from '@material-ui/core';
import {
  AutocompleteInput,
  DateInput,
  Edit,
  NumberInput,
  ReferenceInput,
  ResourceComponentProps,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  number,
  required,
  useTranslate
} from 'react-admin';
import { TextFieldsCard } from '../../lib/mui_lib/text-fields-card';

const UserEditToolbar = (props: ToolbarProps) => {
  const handleTransform = (data: any) => {
    return {...data};
  };
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} transform={handleTransform} />
    </Toolbar>
  );
};

export const CustomContractEdit = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.custom-contracts';

  return (
    <Edit {...props} title={translate(`${tFP}.name`, 1)} mutationMode="pessimistic">
      <SimpleForm
        redirect="show"
        toolbar={<UserEditToolbar />}
        variant="standard"
      >
        <Box display="flex" flexDirection="row" minWidth="100%">
          <Grid container spacing={9}>
            <TextFieldsCard title={translate(`${tFP}.name`, 0)}>
              <ReferenceInput
                validate={required()}
                label={translate(`${tFP}.fields.legalEntity`)}
                source="legalEntity"
                allowEmpty={false}
                filterToQuery={(q) => ({ filter: { name: q } })}
                reference="companies"
              >
                <AutocompleteInput optionText="businessName" />
              </ReferenceInput>
              <ReferenceInput
                validate={required()}
                label={translate(`${tFP}.fields.counterpart`)}
                source="counterpart"
                allowEmpty={false}
                filterToQuery={(q) => ({ filter: { name: q } })}
                reference="companies"
              >
                <AutocompleteInput optionText="businessName" />
              </ReferenceInput>
              <DateInput
                source="contractDate"
                label={translate(`${tFP}.fields.contractDate`)}
                validate={[required()]}
              />
              <DateInput
                source="startDate"
                label={translate(`${tFP}.fields.startDate`)}
                validate={[required()]}
              />
              <DateInput
                source="endDate"
                label={translate(`${tFP}.fields.endDate`)}
                validate={[required()]}
              />
              <SelectInput
                variant="standard"
                label={translate(`${tFP}.fields.mark`)}
                source="mark"
                choices={['B'].map((mark) => {
                  return {
                    id: mark,
                    name: mark,
                  };
                })}
                validate={required()}
              />
              <SelectInput
                variant="standard"
                label={translate(`${tFP}.fields.paymentId`)}
                source="paymentId"
                choices={['FMF'].map((paymentId) => {
                  return {
                    id: paymentId,
                    name: paymentId,
                  };
                })}
                validate={required()}
              />
              <SelectInput
                variant="standard"
                label={translate(`${tFP}.fields.renewalId`)}
                source="renewalId"
                choices={['ESC', 'RIPF'].map((renewalId) => {
                  return {
                    id: renewalId,
                    name: renewalId,
                  };
                })}
                validate={required()}
              />
              <SelectInput
                variant="standard"
                label={translate(`${tFP}.fields.upmLoan`)}
                source="upmLoan"
                choices={['GRA'].map((upmLoan) => {
                  return {
                    id: upmLoan,
                    name: upmLoan,
                  };
                })}
                validate={required()}
              />
              <SelectInput
                variant="standard"
                label={translate(`${tFP}.fields.penalty`)}
                source="penalty"
                choices={['PAS', 'RIM'].map((penalty) => {
                  return {
                    id: penalty,
                    name: penalty,
                  };
                })}
                validate={required()}
              />
              <NumberInput
                source="auctionSupplierFee"
                label={translate(`${tFP}.fields.auctionSupplierFee`)}
                validate={[required(), number()]}
              />
              <NumberInput
                source="spotSupplierFee"
                label={translate(`${tFP}.fields.spotSupplierFee`)}
                validate={[required(), number()]}
              />
              <ReferenceInput
                label={translate(`${tFP}.fields.mediator`)}
                source="mediator"
                allowEmpty={false}
                filterToQuery={(q) => ({ filter: { name: q } })}
                reference="companies"
              >
                <AutocompleteInput optionText="businessName" resettable/>
              </ReferenceInput>
              <NumberInput
                source="mediatorFee"
                label={translate(`${tFP}.fields.mediatorFee`)}
                validate={number()}
              />
            </TextFieldsCard>
          </Grid>
        </Box>
      </SimpleForm>
    </Edit>
  );
};
