import {
  BooleanInput,
  DateInput,
  Edit,
  ReferenceInput,
  ResourceComponentProps,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  required,
  useTranslate
} from 'react-admin';

const UserEditToolbar = (props: ToolbarProps) => {
  const handleTransform = (data: any) => {
    return {...data};
  };
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} transform={handleTransform} />
    </Toolbar>
  );
};

export const UvamForceAvailabilityEdit = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.uvam-force-availabilities';

  return (
    <Edit {...props} title={translate(`${tFP}.name`, 1)} mutationMode="pessimistic">
      <SimpleForm
        redirect="show"
        toolbar={<UserEditToolbar />}
        variant="standard"
      >
        <ReferenceInput
          validate={required()}
          label={translate(`${tFP}.fields.uvamName`)}
          source="uvam"
          allowEmpty={false}
          filterToQuery={(q) => ({ filter: { name: q } })}
          reference="uvam"
          disabled
          className="editCreateForm"
        >
          <SelectInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput
          validate={required()}
          label={translate(`${tFP}.fields.product`)}
          source="product"
          allowEmpty={false}
          filterToQuery={(q) => ({ filter: { name: q } })}
          reference="products"
          className="editCreateForm"
        >
          <SelectInput optionText={(record) => `${record.id} - ${record.productCode} - ${record.type}`} />
        </ReferenceInput>
        <DateInput
          source="startDate"
          label={translate(`${tFP}.fields.startDate`)}
          validate={[required()]}
          className="editCreateForm"
        />
        <DateInput
          source="endDate"
          label={translate(`${tFP}.fields.endDate`)}
          validate={[required()]}
          className="editCreateForm"
        />
        <BooleanInput
          source="availability"
          defaultValue={false}
          validate={[required()]}
          className="editCreateForm"
        />
      </SimpleForm>
    </Edit>
  );
};
