export const gridVoltageOptions = [
  'BT',
  'AAT 380 kV',
  'AAT 220 kV',
  'AT 150 kV',
  'AT 120-132 kV',
  'AT 40-90 kV',
  'MT 23-39 kV',
  'MT 17-22 kV',
  'MT 11-16 kV',
  'MT 1-10 kV',
];

export const locationOptions = [
  { '001': 'Torino' },
  { '002': 'Vercelli' },
  { '003': 'Novara' },
  { '004': 'Cuneo' },
  { '005': 'Asti' },
  { '006': 'Alessandria' },
  { '096': 'Biella' },
  { '103': 'Verbano-Cusio-Ossola' },
  { '007': "Valle d'Aosta" },
  { '012': 'Varese' },
  { '013': 'Como' },
  { '014': 'Sondrio' },
  { '015': 'Milano' },
  { '016': 'Bergamo' },
  { '017': 'Brescia' },
  { '018': 'Pavia' },
  { '019': 'Cremona' },
  { '020': 'Mantova' },
  { '097': 'Lecco' },
  { '098': 'Lodi' },
  { '108': 'Monza e della Brianza' },
  { '021': 'Bolzano/Bozen' },
  { '022': 'Trento' },
  { '023': 'Verona' },
  { '024': 'Vicenza' },
  { '025': 'Belluno' },
  { '026': 'Treviso' },
  { '027': 'Venezia' },
  { '028': 'Padova' },
  { '029': 'Rovigo' },
  { '030': 'Udine' },
  { '031': 'Gorizia' },
  { '032': 'Trieste' },
  { '093': 'Pordenone' },
  { '008': 'Imperia' },
  { '009': 'Savona' },
  { '010': 'Genova' },
  { '011': 'La Spezia' },
  { '033': 'Piacenza' },
  { '034': 'Parma' },
  { '035': "Reggio nell'Emilia" },
  { '036': 'Modena' },
  { '037': 'Bologna' },
  { '038': 'Ferrara' },
  { '039': 'Ravenna' },
  { '040': 'Forlì-Cesena' },
  { '099': 'Rimini' },
  { '045': 'Massa-Carrara' },
  { '046': 'Lucca' },
  { '047': 'Pistoia' },
  { '048': 'Firenze' },
  { '049': 'Livorno' },
  { '050': 'Pisa' },
  { '051': 'Arezzo' },
  { '052': 'Siena' },
  { '053': 'Grosseto' },
  { '100': 'Prato' },
  { '054': 'Perugia' },
  { '055': 'Terni' },
  { '041': 'Pesaro e Urbino' },
  { '042': 'Ancona' },
  { '043': 'Macerata' },
  { '044': 'Ascoli Piceno' },
  { '109': 'Fermo' },
  { '056': 'Viterbo' },
  { '057': 'Rieti' },
  { '058': 'Roma' },
  { '059': 'Latina' },
  { '060': 'Frosinone' },
  { '066': "L'Aquila" },
  { '067': 'Teramo' },
  { '068': 'Pescara' },
  { '069': 'Chieti' },
  { '070': 'Campobasso' },
  { '094': 'Isernia' },
  { '061': 'Caserta' },
  { '062': 'Benevento' },
  { '063': 'Napoli' },
  { '064': 'Avellino' },
  { '065': 'Salerno' },
  { '071': 'Foggia' },
  { '072': 'Bari' },
  { '073': 'Taranto' },
  { '074': 'Brindisi' },
  { '075': 'Lecce' },
  { '110': 'Barletta-Andria-Trani' },
  { '076': 'Potenza' },
  { '077': 'Matera' },
  { '078': 'Cosenza' },
  { '079': 'Catanzaro' },
  { '080': 'Reggio Calabria' },
  { '101': 'Crotone' },
  { '102': 'Vibo Valentia' },
  { '081': 'Trapani' },
  { '082': 'Palermo' },
  { '083': 'Messina' },
  { '084': 'Agrigento' },
  { '085': 'Caltanissetta' },
  { '086': 'Enna' },
  { '087': 'Catania' },
  { '088': 'Ragusa' },
  { '089': 'Siracusa' },
  { '090': 'Sassari' },
  { '091': 'Nuoro' },
  { '092': 'Cagliari' },
  { '095': 'Oristano' },
  { '111': 'Sud Sardegna' },
];

export const brpOptions = [
  {brpId: "DP2305", brpBusinessName: "EP PRODUZIONE S.P.A."},
  {brpId: "DP1511", brpBusinessName: "ABENERGIE SPA"},
  {brpId: "DP2032", brpBusinessName: "GLOBAL POWER SPA"},
  {brpId: "DP2246", brpBusinessName: "ENERPARTNER"},
  {brpId: "DP2156", brpBusinessName: "ATS POWER"},
  {brpId: "DP2350", brpBusinessName: "APLOS SPA"},
  {brpId: "DP2176", brpBusinessName: "INNOVENERGY SRL"},
  {brpId: "DP2355", brpBusinessName: "ARCADIA ITALIA"},
  {brpId: "DP2262", brpBusinessName: "ALPHASTAR S.R.L."},
  {brpId: "DP2230", brpBusinessName: "NEWATT S.R.L."},
  {brpId: "DP2257", brpBusinessName: "SOCIETA COOPERATIVA ELETTRICA DI DISTRIBUZIONE CAMPO TURES"},
  {brpId: "DP2349", brpBusinessName: "CONVERGENZE SPA"},
  {brpId: "DP2338", brpBusinessName: "BP ENERGY EUROPE LIMITED"},
  {brpId: "DP2352", brpBusinessName: "BANCO ENERGIA FORNITURE S.R.L."},
  {brpId: "DP2222", brpBusinessName: "UNET ENERGIA ITALIANA SPA"},
  {brpId: "DP2089", brpBusinessName: "SORGENIA PUGLIA SPA"},
  {brpId: "DP2348", brpBusinessName: "ENECO S.R.L."},
  {brpId: "DP2340", brpBusinessName: "ITAL GREEN ENERGY SRL"},
  {brpId: "DP2562", brpBusinessName: "OMNIA ENERGIA SPA"},
  {brpId: "DP0384", brpBusinessName: "OMNIA ENERGIA SPA"},
  {brpId: "DP2125", brpBusinessName: "SOCIETA ENERGIA ITALIA S.P.A."},
  {brpId: "DP2248", brpBusinessName: "ONDA ENERGIA S.R.L."},
  {brpId: "DP2287", brpBusinessName: "ESA SRL"},
  {brpId: "DP1999", brpBusinessName: "DUFERCO ENERGIA S.P.A."},
  {brpId: "DP2252", brpBusinessName: "AUDAX ENERGIA"},
  {brpId: "DP0068", brpBusinessName: "ENECO TRADE SRL"},
  {brpId: "DP2171", brpBusinessName: "WATT AND VOLT S.A."},
  {brpId: "DP2325", brpBusinessName: "INVOLTA SRL"},
  {brpId: "DP2346", brpBusinessName: "SPEZIA ENERGY TRADING SRL"},
  {brpId: "DP2088", brpBusinessName: "SORGENIA POWER SPA"},
  {brpId: "DP2199", brpBusinessName: "POMI GAS & POWER S.R.L."},
  {brpId: "DP2131", brpBusinessName: "TRADEINV GAS & ENERGY  S.P.A."},
  {brpId: "DP1883", brpBusinessName: "RFI SPA"},
  {brpId: "DP2302", brpBusinessName: "A2A SPA"},
  {brpId: "DP2299", brpBusinessName: "ARGOS S.R.L."},
  {brpId: "DP2167", brpBusinessName: "SOCIETA' GAS RIMINI SPA"},
  {brpId: "DP2343", brpBusinessName: "POLIGRAND ENERGY SRL"},
  {brpId: "DP2206", brpBusinessName: "4ENERGIA SRL"},
  {brpId: "DP1121", brpBusinessName: "TELENERGIA SRL"},
  {brpId: "DP1936", brpBusinessName: "A2A AIRPORT ENERGY SPA"},
  {brpId: "DP2190", brpBusinessName: "GESTORE DEI SERVIZI ENERGETICI - GSE SPA"},
  {brpId: "DP1975", brpBusinessName: "GUNVOR INTERNATIONAL B.V. AMSTERDAM GENEVA BRANCH"},
  {brpId: "DP2115", brpBusinessName: "SG ENERGIA SOCIETA' PER AZIONI SPA"},
  {brpId: "DP2191", brpBusinessName: "KOCH SUPPLY & TRADING"},
  {brpId: "DP2329", brpBusinessName: "FEI"},
  {brpId: "DP2017", brpBusinessName: "ENGIE ITALIA S.P.A."},
  {brpId: "DP2251", brpBusinessName: "ENEGA S.R.L."},
  {brpId: "DP0063", brpBusinessName: "ELECTRA ITALIA SPA"},
  {brpId: "DP1519", brpBusinessName: "HOLDING SLOVENSKE  ELEKTRARNE DOO"},
  {brpId: "DP1896", brpBusinessName: "STATKRAFT MARKETS GMBH"},
  {brpId: "DP2168", brpBusinessName: "INTERENERGO DD"},
  {brpId: "DP1921", brpBusinessName: "SPIENERGY SPA"},
  {brpId: "DP0062", brpBusinessName: "AXPO ITALIA SPA"},
  {brpId: "DP1125", brpBusinessName: "ILLUMIA  SPA"},
  {brpId: "DP0361", brpBusinessName: "EGEA COMMERCIALE SRL"},
  {brpId: "DP2166", brpBusinessName: "SUNCITY ENERGY SRL"},
  {brpId: "DP2506", brpBusinessName: "PETROL SLOVENSKA ENERGETSKA DRUZBA D.D.LJUBLJANA"},
  {brpId: "DP2063", brpBusinessName: "VATTENFALL ENERGY TRADING GMBH"},
  {brpId: "DP0276", brpBusinessName: "EDF TRADING LIMITED"},
  {brpId: "DP2264", brpBusinessName: "SEV ENERGIES"},
  {brpId: "DP2066", brpBusinessName: "DENCO S.R.L."},
  {brpId: "DP2241", brpBusinessName: "FENIX SRL"},
  {brpId: "DP2134", brpBusinessName: "ENGIE GLOBAL MARKETS"},
  {brpId: "DP2290", brpBusinessName: "TRASPORTO ENERGETICO SULCITANO SRL"},
  {brpId: "DP2371", brpBusinessName: "STRATEGIC ENERGY TRADING"},
  {brpId: "DP2376", brpBusinessName: "GESTIONE ITALIA"},
  {brpId: "DP2381", brpBusinessName: "ALPHERG SPA"},
  {brpId: "DP2452", brpBusinessName: "BAYWA R.E. ENERGY TRADING S.R.L."},
  {brpId: "DP2311", brpBusinessName: "B.A.T. ENGINEERING SOC. COOP. SOCIALE"},
  {brpId: "DP2336", brpBusinessName: "KEN PRODUCTION AND TRADE OF ENERGY PRODUCTS SA"},
  {brpId: "DP2377", brpBusinessName: "ASCOPIAVE ENERGIE SPA"},
  {brpId: "DP1607", brpBusinessName: "AZIENDA ENERGETICA VALTELLINA VALCHIAVENNA SPA"},
  {brpId: "DP2298", brpBusinessName: "IBERDROLA CLIENTI ITALIA SRL"},
  {brpId: "DP2344", brpBusinessName: "ONDA PIU' S.R.L."},
  {brpId: "DP2387", brpBusinessName: "MORE LIFE ENERGY S.R.L."},
  {brpId: "DP2363", brpBusinessName: "EKOMOBIL"},
  {brpId: "DP1848", brpBusinessName: "MORGAN STANLEY CAPITAL GROUP INC."},
  {brpId: "DP2247", brpBusinessName: "BANCO ENERGIA SPA"},
  {brpId: "DP2296", brpBusinessName: "ETRURIALUCEGAS SPA"},
  {brpId: "DP2378", brpBusinessName: "ESTRA ENERGIE SRL"},
  {brpId: "DP2025", brpBusinessName: "E.S.TR.A. ELETTRICITA'"},
  {brpId: "DP2382", brpBusinessName: "CAMER PETROLEUM EUROPA S.R.L."},
  {brpId: "DP1196", brpBusinessName: "DANSKE COMMODITIES A/S"},
  {brpId: "DP2153", brpBusinessName: "ENERGIA ITALIANA SRL"},
  {brpId: "DP7004", brpBusinessName: "A2A ENERGIA SPA - SALVAGUARDIA"},
  {brpId: "DP2362", brpBusinessName: "FABRIALE SRL"},
  {brpId: "DP2111", brpBusinessName: "GNERA ENERGIA Y TECNOLOGIA"},
  {brpId: "DP2224", brpBusinessName: "EROGA ENERGIA S.R.L."},
  {brpId: "DP2005", brpBusinessName: "GELSIA  SRL"},
  {brpId: "DP2034", brpBusinessName: "BLU SHELF S.R.L."},
  {brpId: "DP2286", brpBusinessName: "ENEIDE S.R.L."},
  {brpId: "DP2364", brpBusinessName: "LENERGIA SPA"},
  {brpId: "DP2375", brpBusinessName: "MFT ENERGY"},
  {brpId: "DP2335", brpBusinessName: "STANDARD GAS S.R.L."},
  {brpId: "DP2082", brpBusinessName: "META NEWPOWER S.R.L."},
  {brpId: "DP2184", brpBusinessName: "NVALUE SA"},
  {brpId: "DP2192", brpBusinessName: "ENERG.IT SPA"},
  {brpId: "DP0071", brpBusinessName: "ENERG.IT SPA"},
  {brpId: "DP0339", brpBusinessName: "NIGGELER & KUPFER ENERGIA SRL"},
  {brpId: "DP7003", brpBusinessName: "RFI SPA - TRAZIONE"},
  {brpId: "DP2310", brpBusinessName: "EN-GAS SRL"},
  {brpId: "DP2255", brpBusinessName: "APE SRL"},
  {brpId: "DP2151", brpBusinessName: "ENONE S.R.L."},
  {brpId: "DP2022", brpBusinessName: "ENERGYA VM GESTION DE ENERGIA"},
  {brpId: "DP2431", brpBusinessName: "SEREN ENERGIA SRL"},
  {brpId: "DP2110", brpBusinessName: "POMILIA ENERGIA S.R.L."},
  {brpId: "DP2313", brpBusinessName: "NORDOVEST ENERGIE S.R.L."},
  {brpId: "DP2239", brpBusinessName: "ECOENERGIA  S.R.L."},
  {brpId: "DP2392", brpBusinessName: "ALTEA GREEN POWER SPA"},
  {brpId: "DP2394", brpBusinessName: "BPOWER ENERGIA S.R.L."},
  {brpId: "DP2403", brpBusinessName: "DINAMO TRADE S.R.L."},
  {brpId: "DP2400", brpBusinessName: "LIBERAMENTE ENERGIA SRL"},
  {brpId: "DP1939", brpBusinessName: "AZ. AUT. DI STATO PER I SERVIZI PUBBLICI DELLA REPUBBLICA DI SAN MARINO"},
  {brpId: "DP0114", brpBusinessName: "AGSM AIM ENERGIA SPA"},
  {brpId: "DP1005", brpBusinessName: "ACEA ENERGIA SPA"},
  {brpId: "DP0011", brpBusinessName: "ACEA ENERGIA SPA"},
  {brpId: "DP2233", brpBusinessName: "VELGA S.R.L."},
  {brpId: "DP1676", brpBusinessName: "ENEL ENERGIA SPA"},
  {brpId: "DP0273", brpBusinessName: "ENEL ENERGIA SPA"},
  {brpId: "DP2195", brpBusinessName: "FONTEL S.P.A."},
  {brpId: "DP0318", brpBusinessName: "EXERGIA SPA"},
  {brpId: "DP2043", brpBusinessName: "TEAENERGIA S.R.L."},
  {brpId: "DP1109", brpBusinessName: "TEAENERGIA S.R.L."},
  {brpId: "DP2092", brpBusinessName: "EVISO S.P.A."},
  {brpId: "DP0367", brpBusinessName: "CONSORZIO PER LE RISORSE ENERGETICHE  S.C.P.A"},
  {brpId: "DP1974", brpBusinessName: "GEN-I ENERGIA S.R.L."},
  {brpId: "DP0015", brpBusinessName: "CONSORZIO APUANIA ENERGIA"},
  {brpId: "DP1981", brpBusinessName: "BLUENERGY GROUP SPA"},
  {brpId: "DP1919", brpBusinessName: "HB TRADING SPA"},
  {brpId: "DP2322", brpBusinessName: "GRITTI ENERGIA SRL"},
  {brpId: "DP2397", brpBusinessName: "EUNICE TRADING S.A."},
  {brpId: "DP1945", brpBusinessName: "AZIENDA ELETTRICA EGGERBACH SOC. COOP. A R.L."},
  {brpId: "DP2420", brpBusinessName: "ENEIDE LUCE E GAS SRL"},
  {brpId: "DP0195", brpBusinessName: "ODOARDO ZECCA SRL"},
  {brpId: "DP0060", brpBusinessName: "EDISON ENERGIA SPA"},
  {brpId: "DP1924", brpBusinessName: "NORD ENERGIA SPA"},
  {brpId: "DP1903", brpBusinessName: "CONS. ENERG. INDUSTRIALI RIUNITI SCARL"},
  {brpId: "DP1991", brpBusinessName: "COOPERATIVA DI ENERGIA MULES"},
  {brpId: "DP2127", brpBusinessName: "CLEANPOWER S.R.L."},
  {brpId: "DP1715", brpBusinessName: "HELIOS SPA"},
  {brpId: "DP2258", brpBusinessName: "ITALPOWER ENERGIA SRL"},
  {brpId: "DP2210", brpBusinessName: "INSIEMENERGIA SPA"},
  {brpId: "DP1183", brpBusinessName: "E-DISTRIBUZIONE S.P.A."},
  {brpId: "DP2250", brpBusinessName: "EUROPA GESTIONI IMMOBILIARI S.P.A."},
  {brpId: "DP2408", brpBusinessName: "ILEKTROPARAGOGI SOUSAKIOU AE"},
  {brpId: "DP2216", brpBusinessName: "LUCE E GAS ITALIA SERVIZI S.R.L."},
  {brpId: "DP1918", brpBusinessName: "ACSM - AGAM SPA"},
  {brpId: "DP0219", brpBusinessName: "ACSM - AGAM SPA"},
  {brpId: "DP2083", brpBusinessName: "AIM ENERGY SRL"},
  {brpId: "DP7001", brpBusinessName: "ENEL ENERGIA SPA SALVAGUARDIA"},
  {brpId: "DP0450", brpBusinessName: "AZIENDA ELETTRICA KALMTAL SOCIETA' COOPERATIVA"},
  {brpId: "DP0513", brpBusinessName: "AZ.EL. STELVIO"},
  {brpId: "DP1909", brpBusinessName: "ENERGI  DANMARK A/S"},
  {brpId: "DP2413", brpBusinessName: "PASSUELLO FRATELLI SRL"},
  {brpId: "DP2050", brpBusinessName: "PASSUELLO FRATELLI SRL"},
  {brpId: "DP2014", brpBusinessName: "J.P. MORGAN SECURITIES PLC."},
  {brpId: "DP0426", brpBusinessName: "ACQUIRENTE UNICO SPA"},
  {brpId: "DP2270", brpBusinessName: "FREE GAS & POWER"},
  {brpId: "DP1706", brpBusinessName: "CITTA DEL VATICANO"},
  {brpId: "DP2229", brpBusinessName: "P&B UNION"},
  {brpId: "DP2308", brpBusinessName: "ACSM TRADING SRL UNIPERSONALE"},
  {brpId: "DP1885", brpBusinessName: "SEBINA"},
  {brpId: "DP0247", brpBusinessName: "IREN MERCATO SPA"},
  {brpId: "DP0013", brpBusinessName: "IREN MERCATO SPA"},
  {brpId: "DP2419", brpBusinessName: "SOLARYS SRL - ENERGIE RINNOVABILI"},
  {brpId: "DP2337", brpBusinessName: "ENERGY GAS&OIL"},
  {brpId: "DP2065", brpBusinessName: "EDELWEISS ENERGY HOLDING SPA"},
  {brpId: "DP1986", brpBusinessName: "ENERXENIA SPA"},
  {brpId: "DP2091", brpBusinessName: "DXT COMMODITIES SA"},
  {brpId: "DP2438", brpBusinessName: "QUA ENERGIA SRLS"},
  {brpId: "DP2398", brpBusinessName: "ENERGON ESCO SPA"},
  {brpId: "DP1170", brpBusinessName: "EDELWEISS ENERGIA SPA"},
  {brpId: "DP0196", brpBusinessName: "CVA ENERGIE S.R.L."},
  {brpId: "DP2046", brpBusinessName: "ENERGY.TRADE"},
  {brpId: "DP2055", brpBusinessName: "NEXUS ENERGIA SA"},
  {brpId: "DP2033", brpBusinessName: "GDF SUEZ TRADING"},
  {brpId: "DP2312", brpBusinessName: "JAS ENERGY TRADING S.R.O."},
  {brpId: "DP2163", brpBusinessName: "ENERGIE WASSER BERN"},
  {brpId: "DP2077", brpBusinessName: "AEMME LINEA ENERGIE"},
  {brpId: "DP2100", brpBusinessName: "EST PIU' S.P.A."},
  {brpId: "DP1707", brpBusinessName: "EST PIU' S.P.A."},
  {brpId: "DP2109", brpBusinessName: "ENERGY.CONSULTING SRL"},
  {brpId: "DP2114", brpBusinessName: "SOCIETA ELETTRICA INDUSTRIALE ITALIANA"},
  {brpId: "DP2117", brpBusinessName: "SOCIETA' CENTRO ENERGIA"},
  {brpId: "DP2118", brpBusinessName: "POSTE ENERGIA"},
  {brpId: "DP9999", brpBusinessName: "ACQUIRENTE UNICO SALVAGUARDIA"},
  {brpId: "DP2221", brpBusinessName: "PROTERGIA AGIOS NIKOLAOS POWER SOCIETE ANONYME"},
  {brpId: "DP2174", brpBusinessName: "MERCURIA ENERGY TRADING SA"},
  {brpId: "DP2157", brpBusinessName: "GSA ENERGY SRL"},
  {brpId: "DP2447", brpBusinessName: "ENERCOM SRL"},
  {brpId: "DP2161", brpBusinessName: "ENERCOM SRL"},
  {brpId: "DP2464", brpBusinessName: "ENSCO ENERGY SERVICES COMPANY AG"},
  {brpId: "DP2170", brpBusinessName: "ENSCO ENERGY SERVICES COMPANY AG"},
  {brpId: "DP2180", brpBusinessName: "ELECTROROUTE ENERGY TRADING LIMITED"},
  {brpId: "DP2301", brpBusinessName: "MAG ENERGY EUROPE INC."},
  {brpId: "DP2240", brpBusinessName: "ENERGY.COM SPA"},
  {brpId: "DP2297", brpBusinessName: "IWB INDUSTRIELLE WERKE BASEL"},
  {brpId: "DP7002", brpBusinessName: "HERA COMM SRL SALVAGUARDIA"},
  {brpId: "DP2214", brpBusinessName: "ENERGETICA ROMANA"},
  {brpId: "DP2218", brpBusinessName: "MADE IN ENERGY"},
  {brpId: "DP2227", brpBusinessName: "PLURIENERGIA SPA"},
  {brpId: "DP2242", brpBusinessName: "XI ENERGY PARTNERS"},
  {brpId: "DP2245", brpBusinessName: "NOVA ENERGY TRADING SA"},
  {brpId: "DP2269", brpBusinessName: "CNC COMMODITIES"},
  {brpId: "DP2274", brpBusinessName: "X TRADE GAS & POWER"},
  {brpId: "DP2276", brpBusinessName: "CITYLUCE S.R.L."},
  {brpId: "DP2315", brpBusinessName: "A.P. SRL"},
  {brpId: "DP0242", brpBusinessName: "ACAM CLIENTI SPA"},
  {brpId: "DP2028", brpBusinessName: "AZIENDA ENERGIA E GAS SOCIETA' COOPERATIVA"},
  {brpId: "DP1014", brpBusinessName: "ENIA ENERGIA SPA"},
  {brpId: "DP0422", brpBusinessName: "ASJA MARKET SRL"},
  {brpId: "DP0313", brpBusinessName: "GELSIA ENERGIA SRL"},
  {brpId: "DP0464", brpBusinessName: "ADVANCED TECHNOLOGY SOLUTIONS SPA"},
  {brpId: "DP0465", brpBusinessName: "AGAM VENDITE SRL"},
  {brpId: "DP0026", brpBusinessName: "BP ITALIA SPA"},
  {brpId: "DP1893", brpBusinessName: "CONSORZIO CHIERI ENERGIA"},
  {brpId: "DP1948", brpBusinessName: "CKW AG (SA)"},
  {brpId: "DP1952", brpBusinessName: "CITIGROUP GLOBAL MARKETS LIMITED"},
  {brpId: "DP1724", brpBusinessName: "DEUTSCHE BANK AG LONDON BRANCH"},
  {brpId: "DP2097", brpBusinessName: "ENERGIA & IMPRESA SPA"},
  {brpId: "DP2036", brpBusinessName: "ENERGIA & IMPRESA SPA"},
  {brpId: "DP2044", brpBusinessName: "ENERGIA E TERRITORIO SPA"},
  {brpId: "DP1517", brpBusinessName: "ENERGY FINANCING TEAM"},
  {brpId: "DP1882", brpBusinessName: "ELETTROGAS SPA"},
  {brpId: "DP1943", brpBusinessName: "ENECO ENERGY TRADE BV"},
  {brpId: "DP1892", brpBusinessName: "ENERGYLIFE SRL"},
  {brpId: "DP1711", brpBusinessName: "GREEN NETWORK LUCE & GAS SRL"},
  {brpId: "DP1886", brpBusinessName: "EMMECIDUE SPA"},
  {brpId: "DP0136", brpBusinessName: "ESTENERGY SPA"},
  {brpId: "DP1849", brpBusinessName: "E&T ENERGIE HANDELSGESELLSCHAFT MBH"},
  {brpId: "DP0175", brpBusinessName: "FINARVEDI SPA"},
  {brpId: "DP2038", brpBusinessName: "ICD - INTER COMMERCIAL DIALOGUE LTD"},
  {brpId: "DP2010", brpBusinessName: "IMC ENERGY TRADING BV AMSTERDAM CHAM BRANCH"},
  {brpId: "DP0223", brpBusinessName: "IDROELETTRICA SCRL"},
  {brpId: "DP0246", brpBusinessName: "LUCAS ENGINE"},
  {brpId: "DP1920", brpBusinessName: "MERRILL  LYNCH COMMODITIES (EUROPE) LIMITED"},
  {brpId: "DP2027", brpBusinessName: "OMPEX AG"},
  {brpId: "DP1197", brpBusinessName: "ALPIQ SUISSE SA"},
  {brpId: "DP1632", brpBusinessName: "PGM TRADE SRL"},
  {brpId: "DP1185", brpBusinessName: "PUBLIC POWER CORPORATION SA"},
  {brpId: "DP1973", brpBusinessName: "SEL SPA"},
  {brpId: "DP0334", brpBusinessName: "SELTRADE SPA"},
  {brpId: "DP0387", brpBusinessName: "SMIENERGIA  SPA"},
  {brpId: "DP1843", brpBusinessName: "NECO SA TRANSBALKAN ELECTRIC POWER TRADING SA"},
  {brpId: "DP2243", brpBusinessName: "ELPEDISON S.A."},
  {brpId: "DP1090", brpBusinessName: "TGE SPA"},
  {brpId: "DP2052", brpBusinessName: "TRADECOM SRL"},
  {brpId: "DP1854", brpBusinessName: "VALLENERGIE SPA"},
  {brpId: "DP1831", brpBusinessName: "VERBUND-AUSTRIAN POWER TRADING ENERGA HELLAS S.A."},
  {brpId: "DP0360", brpBusinessName: "DOLOMITI ENERGIA SPA"},
  {brpId: "DP2493", brpBusinessName: "CONTROPARTE DI TEST"},
  {brpId: "DP2149", brpBusinessName: "CONTROPARTE DI TEST"},
  {brpId: "DP2071", brpBusinessName: "CONTROPARTE DI TEST"},
  {brpId: "DP2426", brpBusinessName: "SEREN SRL"},
  {brpId: "DP1516", brpBusinessName: "ALPIQ AG"},
  {brpId: "DP2410", brpBusinessName: "CENTREX ITALIA S.P.A."},
  {brpId: "DP1942", brpBusinessName: "CENTRICA ENERGY TRADING A/S"},
  {brpId: "DP2193", brpBusinessName: "ERG POWER GENERATION S.P.A."},
  {brpId: "DP2042", brpBusinessName: "GEO SPA"},
  {brpId: "DP0931", brpBusinessName: "GREEN NETWORK SPA"},
  {brpId: "DP0381", brpBusinessName: "GREEN NETWORK SPA"},
  {brpId: "DP0312", brpBusinessName: "GREEN NETWORK SPA"},
  {brpId: "DP2330", brpBusinessName: "VOLTY S.R.L."},
  {brpId: "DP2445", brpBusinessName: "ENERG POWER ITALIA SPA"},
  {brpId: "DP2441", brpBusinessName: "ITALIAN ENERGY COMPANY SRL"},
  {brpId: "DP9960", brpBusinessName: "EDISON TRADING SPA"},
  {brpId: "DP0228", brpBusinessName: "DOLOMITI ENERGIA TRADING SPA"},
  {brpId: "DP0073", brpBusinessName: "SORGENIA SPA"},
  {brpId: "DP0029", brpBusinessName: "C.U.R.A.CONSORZIO UTILITIES RAVENNA SCRL"},
  {brpId: "DP2469", brpBusinessName: "SINERGAS SPA"},
  {brpId: "DP2444", brpBusinessName: "COGEME NUOVE ENERGIE S.R.L."},
  {brpId: "DP2208", brpBusinessName: "CONSORZIO ENERGETICO VAL VENOSTA SOCIETA' COOPERATIVA"},
  {brpId: "DP2435", brpBusinessName: "FRIZZARIN S.R.L."},
  {brpId: "DP0156", brpBusinessName: "ALPIQ ENERGIA ITALIA SPA"},
  {brpId: "DP2434", brpBusinessName: "VISITEL SRL"},
  {brpId: "DP2457", brpBusinessName: "NWG ENERGIA SPA SB"},
  {brpId: "DP2094", brpBusinessName: "FALCK NEXT ENERGY S.R.L."},
  {brpId: "DP2446", brpBusinessName: "EPLUS SRL"},
  {brpId: "DP2402", brpBusinessName: "SOLARIS POWER SA"},
  {brpId: "DP0323", brpBusinessName: "CONSORZIO TOSCANA ENERGIA SPA"},
  {brpId: "DP1898", brpBusinessName: "E.ON ENERGIA S.P.A"},
  {brpId: "DP1155", brpBusinessName: "E.ON ENERGIA S.P.A"},
  {brpId: "DP0078", brpBusinessName: "E.ON ENERGIA S.P.A"},
  {brpId: "DP0016", brpBusinessName: "A2A TRADING SRL"},
  {brpId: "DP0005", brpBusinessName: "A2A TRADING SRL"},
  {brpId: "DP9004", brpBusinessName: "A2A ENERGIA SPA"},
  {brpId: "DP0004", brpBusinessName: "A2A ENERGIA SPA"},
  {brpId: "DP2215", brpBusinessName: "E.JA S.R.L."},
  {brpId: "DP1174", brpBusinessName: "ELECTRADE SPA"},
  {brpId: "DP0177", brpBusinessName: "ESPERIA SPA"},
  {brpId: "DP2347", brpBusinessName: "EDISON SPA"},
  {brpId: "DP2373", brpBusinessName: "IN COMMODITIES A/S"},
  {brpId: "DP2051", brpBusinessName: "FREE ENERGIA S.P.A."},
  {brpId: "DP2460", brpBusinessName: "TRAILSTONE RENEWABLES GMBH"},
  {brpId: "DP2304", brpBusinessName: "BKW ITALIA SPA"},
  {brpId: "DP1828", brpBusinessName: "BKW ITALIA SPA"},
  {brpId: "DP2345", brpBusinessName: "POWERMART"},
  {brpId: "DP1955", brpBusinessName: "SORGENIA TRADING S.P.A."},
  {brpId: "DP2073", brpBusinessName: "TUENERGIE"},
  {brpId: "DP2064", brpBusinessName: "SOENERGY S.R.L."},
  {brpId: "DP1621", brpBusinessName: "ENERGY.DIS SRL"},
  {brpId: "DP2057", brpBusinessName: "ENOI SPA"},
  {brpId: "DP2468", brpBusinessName: "AGF ENERGY SRL"},
  {brpId: "DP2162", brpBusinessName: "SIMECOM SRL"},
  {brpId: "DP2169", brpBusinessName: "FOR GREEN SPA"},
  {brpId: "DP1825", brpBusinessName: "EUROPE ENERGY SPA"},
  {brpId: "DP2454", brpBusinessName: "SUPREMA SPA"},
  {brpId: "DP1835", brpBusinessName: "UTILITA' SPA"},
  {brpId: "DP2189", brpBusinessName: "EUROPE ENERGY GAS & POWER S.P.A."},
  {brpId: "DP2401", brpBusinessName: "CEZ, A. S."},
  {brpId: "DP2504", brpBusinessName: "EP COMMODITIES, A.S."},
  {brpId: "DP2173", brpBusinessName: "IBERDROLA ENERGIA ESPANA S.A.U."},
  {brpId: "DP1968", brpBusinessName: "J. ARON & COMPANY LLC"},
  {brpId: "DP2442", brpBusinessName: "YADA ENERGIA S.R.L"},
  {brpId: "DP0088", brpBusinessName: "ITALGEN SPA"},
  {brpId: "DP2249", brpBusinessName: "UNIPER GLOBAL COMMODITIES SE"},
  {brpId: "DP0522", brpBusinessName: "UNIPER GLOBAL COMMODITIES SE"},
  {brpId: "DP2380", brpBusinessName: "ENERGYSERVICES S.R.L."},
  {brpId: "DP2463", brpBusinessName: "RESPECT ENERGY S.A."},
  {brpId: "DP2108", brpBusinessName: "FREEPOINT COMMODITIES EUROPE LLP"},
  {brpId: "DP1619", brpBusinessName: "VIVIGAS SPA"},
  {brpId: "DP0144", brpBusinessName: "ENIPOWER SPA"},
  {brpId: "DP0076", brpBusinessName: "ENIPOWER SPA"},
  {brpId: "DP0373", brpBusinessName: "GALA SPA"},
  {brpId: "DP2021", brpBusinessName: "SHELL ENERGY EUROPE LIMITED"},
  {brpId: "DP2455", brpBusinessName: "LITASCO SA"},
  {brpId: "DP2093", brpBusinessName: "OPTIMA ITALIA SPA"},
  {brpId: "DP2265", brpBusinessName: "EGERIA ENERGIE S.P.A."},
  {brpId: "DP2254", brpBusinessName: "ENERGIA MEDITERRANEA"},
  {brpId: "DP2185", brpBusinessName: "NRG TRADING HOUSE S.A."},
  {brpId: "DP2101", brpBusinessName: "NRG TRADING HOUSE S.A."},
  {brpId: "DP2279", brpBusinessName: "EICOM SRL"},
  {brpId: "DP2132", brpBusinessName: "AXPO ENERGY ROMANIA S.A."},
  {brpId: "DP2148", brpBusinessName: "ENERFIN SRL"},
  {brpId: "DP1625", brpBusinessName: "BKW ENERGIE AG"},
  {brpId: "DP2331", brpBusinessName: "ENBW ENERGIE BADEN-WUERTTEMBERG AG"},
  {brpId: "DP1690", brpBusinessName: "GEN-I  TRGOVANJE IN PRODAJA ELEKTRICNE ENERGIJE DOO"},
  {brpId: "DP1929", brpBusinessName: "GAZPROM MARKETING AND TRADING LTD"},
  {brpId: "DP1720", brpBusinessName: "GAZPROM MARKETING AND TRADING LTD"},
  {brpId: "DP0344", brpBusinessName: "TIRRENO POWER SPA"},
  {brpId: "DP1925", brpBusinessName: "SOLVAY CHIMICA ITALIA SPA"},
  {brpId: "DP2456", brpBusinessName: "ACINQUE ENERGIA S.R.L"},
  {brpId: "DP0526", brpBusinessName: "HERA TRADING SRL"},
  {brpId: "DP2081", brpBusinessName: "EDLO ENERGY S.P.A."},
  {brpId: "DP2443", brpBusinessName: "QUATERNA SRL"},
  {brpId: "DP1823", brpBusinessName: "EZPADA AG"},
  {brpId: "DP1717", brpBusinessName: "EZPADA SRO"},
  {brpId: "DP0370", brpBusinessName: "BURGO ENERGIA SRL"},
  {brpId: "DP2256", brpBusinessName: "VEOS"},
  {brpId: "DP4119", brpBusinessName: "HERA COMM SRL"},
  {brpId: "DP2473", brpBusinessName: "BANCO ENERGIA COMMERCIALE S.R.L."},
  {brpId: "DP2369", brpBusinessName: "LUCE E GAS ITALIA SPA"},
  {brpId: "DP0388", brpBusinessName: "OTTANA ENERGIA SPA"},
  {brpId: "DP2483", brpBusinessName: "METAMER SRL"},
  {brpId: "DP0069", brpBusinessName: "ENEL GLOBAL TRADING S.P.A."},
  {brpId: "DP1931", brpBusinessName: "VITOL SA"},
  {brpId: "DP0459", brpBusinessName: "S.I.E.C. SOC COOP"},
  {brpId: "DP2075", brpBusinessName: "ATENA TRADING SRL"},
  {brpId: "DP2238", brpBusinessName: "ELMETIGAS SA"},
  {brpId: "DP1888", brpBusinessName: "HELLAS POWER   S.A"},
  {brpId: "DP1907", brpBusinessName: "GENOSSENSCHAFT E-WERK RIDNAUN"},
  {brpId: "DP0462", brpBusinessName: "LINEA PIU' SPA"},
  {brpId: "DP1158", brpBusinessName: "A2A RETI ELETTRICHE SPA"},
  {brpId: "DP2461", brpBusinessName: "MAVIGAS S.R.L."},
  {brpId: "DP2479", brpBusinessName: "ENERWAWE SRL"},
  {brpId: "DP1623", brpBusinessName: "AZIENDA ELETTRICA TICINESE"},
  {brpId: "DP1697", brpBusinessName: "ACCIAIERIA ARVEDI"},
  {brpId: "DP2476", brpBusinessName: "THEMA ENERGIA S.R.L"},
  {brpId: "DP2280", brpBusinessName: "PLT PUREGREEN"},
  {brpId: "DP2477", brpBusinessName: "UNION GAS METANO S.P.A."},
  {brpId: "DP2498", brpBusinessName: "SELGAS SRL"},
  {brpId: "DP2485", brpBusinessName: "INTERPORTO TOSCANO A. VESPUCCI SPA"},
  {brpId: "DP2495", brpBusinessName: "PRIME POWER S.R.L."},
  {brpId: "DP2496", brpBusinessName: "OLIMPIA SRL"},
  {brpId: "DP2494", brpBusinessName: "ENERGETICA SRL"},
  {brpId: "DP2486", brpBusinessName: "ITALY ENERGIA SRL"},
  {brpId: "DP2278", brpBusinessName: "RWE SUPPLY & TRADING"},
  {brpId: "DP2418", brpBusinessName: "ENEGAN S.P.A."},
  {brpId: "DP2142", brpBusinessName: "POLISENERGIA SRL"},
  {brpId: "DP2489", brpBusinessName: "DUFERCO HELLAS S.A."},
  {brpId: "DP2527", brpBusinessName: "SORGENTE GAS E POWER SRL"},
  {brpId: "DP2366", brpBusinessName: "SORGENTE GAS E POWER SRL"},
  {brpId: "DP2501", brpBusinessName: "SECOND FOUNDATION A.S."},
  {brpId: "DP2488", brpBusinessName: "INFOSYN 4.0 SRL"},
  {brpId: "DP2502", brpBusinessName: "ENET ENERGY"},
  {brpId: "DP2508", brpBusinessName: "ENEMALTA"},
  {brpId: "DP2177", brpBusinessName: "VERBUND ENERGY4BUSINESS GMBH"},
  {brpId: "DP0310", brpBusinessName: "ENEL TRADE - SAN MARINO"},
  {brpId: "DP2505", brpBusinessName: "ENERGY-TRADE SPA"},
  {brpId: "DP2466", brpBusinessName: "MET ENERGIA ITALIA S.P.A."},
  {brpId: "DP2076", brpBusinessName: "COOPERATIVA FORZA E LUCE DI AOSTA"},
  {brpId: "DP0314", brpBusinessName: "ENERGETIC SOURCE LUCE & GAS"},
  {brpId: "DP2503", brpBusinessName: "POSTEPAY  SPA"},
  {brpId: "DP2284", brpBusinessName: "ITALIA POWER SRL"},
  {brpId: "DP2288", brpBusinessName: "NOVA AEG S.P.A."},
  {brpId: "DP2200", brpBusinessName: "ELECTRABEL SA"},
  {brpId: "DP1626", brpBusinessName: "ELECTRABEL SA"},
  {brpId: "DP2511", brpBusinessName: "PODINI SPA"},
  {brpId: "DP2509", brpBusinessName: "ASTI ENERGY SRL"},
  {brpId: "DP0012", brpBusinessName: "ALPERIA SMART SERVICES S.R.L."},
  {brpId: "DP2223", brpBusinessName: "AUGUSTA RATIO"},
  {brpId: "DP1839", brpBusinessName: "AXPO POWER AG"},
  {brpId: "DP1518", brpBusinessName: "AXPO SOLUTIONS AG"},
  {brpId: "DP1627", brpBusinessName: "COMPAGNIE NATIONALE DU RHONE"},
  {brpId: "DP0320", brpBusinessName: "CONSORZIO ENERGIE RINNOVABILI SCARL"},
  {brpId: "DP2152", brpBusinessName: "E.A.R. CHALLENGE SRL"},
  {brpId: "DP2314", brpBusinessName: "ECO TRADE SRL"},
  {brpId: "DP2389", brpBusinessName: "EDP ENERGIA ITALIA SRL"},
  {brpId: "DP1628", brpBusinessName: "ENDESA TRADING SA"},
  {brpId: "DP2120", brpBusinessName: "ENEGAN POWER TRADING SRL"},
  {brpId: "DP2136", brpBusinessName: "ENERGIJA NATURALIS INT. D.O.O"},
  {brpId: "DP1709", brpBusinessName: "ENERGRID SPA"},
  {brpId: "DP0072", brpBusinessName: "ENERGRID SPA"},
  {brpId: "DP2554", brpBusinessName: "ENERVALUE SRL"},
  {brpId: "DP2209", brpBusinessName: "ENERVALUE SRL"},
  {brpId: "DP0056", brpBusinessName: "E.ON ENERGY TRADING SPA"},
  {brpId: "DP1971", brpBusinessName: "ERG SPA"},
  {brpId: "DP1923", brpBusinessName: "EXEN  SRO"},
  {brpId: "DP1894", brpBusinessName: "GASCOM SPA"},
  {brpId: "DP2112", brpBusinessName: "GEKO S.P.A."},
  {brpId: "DP1710", brpBusinessName: "GREEN NETWORK SUD SRL"},
  {brpId: "DP2292", brpBusinessName: "INXIEME ENERGIA S.R.L."},
  {brpId: "DP1887", brpBusinessName: "KORLEA INVEST AS"},
  {brpId: "DP2367", brpBusinessName: "KOSLIGHT SERVICE S.R.L."},
  {brpId: "DP1901", brpBusinessName: "AET ITALIA SRL"},
  {brpId: "DP2451", brpBusinessName: "MOODS ENERGY S.P.A."},
  {brpId: "DP1937", brpBusinessName: "METAENERGIA SPA"},
  {brpId: "DP2319", brpBusinessName: "MYTILINEOS GROUP OF COMPANIES SOCIETE ANONYME"},
  {brpId: "DP0058", brpBusinessName: "REPOWER VENDITA ITALIA SPA"},
  {brpId: "DP2037", brpBusinessName: "RISORSE ENERGETICHE S.R.L."},
  {brpId: "DP2478", brpBusinessName: "ROMA GAS SRL"},
  {brpId: "DP2012", brpBusinessName: "Essent Trading International S.A."},
  {brpId: "DP1721", brpBusinessName: "Essent Trading International S.A."},
  {brpId: "DP2236", brpBusinessName: "SMARTUTILITY S.R.L."},
  {brpId: "DP2070", brpBusinessName: "SUNTRADING"},
  {brpId: "DP0124", brpBusinessName: "TEI ENERGY S.R.L. IN LIQUIDAZIONE"},
  {brpId: "DP2039", brpBusinessName: "T.P.E. TRADING PER L'ENERGIA SPA UNIP."},
  {brpId: "DP1520", brpBusinessName: "VERBUND AG"},
  {brpId: "DP2085", brpBusinessName: "VITOL GAS & POWER B.V."},
  {brpId: "DP2386", brpBusinessName: "ENERGETIC SRL"},
  {brpId: "DP2316", brpBusinessName: "ENI SPA"},
  {brpId: "DP2011", brpBusinessName: "E.ON GLOBAL COMMODITIES SE - SEDE SECONDARIA ITALIANA"},
  {brpId: "DP2523", brpBusinessName: "ENERGY OK SRL"},
  {brpId: "DP2513", brpBusinessName: "SORS MARKETS LTD"},
  {brpId: "DP2275", brpBusinessName: "ARTEMIDE POWER SRL"},
  {brpId: "DP2517", brpBusinessName: "HARTREE PARTNERS POWER & GAS CO UK LTD"},
  {brpId: "DP2524", brpBusinessName: "NOVA INSULA S.R.L."},
  {brpId: "DP2194", brpBusinessName: "OENERGY SPA"},
  {brpId: "DP2317", brpBusinessName: "UNOENERGY SPA"},
  {brpId: "DP2339", brpBusinessName: "BFD IMPORT EXPORT ENERGIA E GAS SRL"},
  {brpId: "DP2516", brpBusinessName: "GHEOS TRADE SRL"},
  {brpId: "DP2514", brpBusinessName: "ANTARES ENERGIA  SRL"},
  {brpId: "DP2522", brpBusinessName: "SEV SOCIETA' COOPERATIVA"},
  {brpId: "DP2526", brpBusinessName: "MY WAY POWER AND GAS S.R.L."},
  {brpId: "DP2528", brpBusinessName: "LIRENAS GAS & LUCE"},
  {brpId: "DP2379", brpBusinessName: "ALEA ENERGIA SPA"},
  {brpId: "DP2531", brpBusinessName: "BETELGEUSE TRADING SRL"},
  {brpId: "DP2150", brpBusinessName: "GAS SALES S.R.L."},
  {brpId: "DP2273", brpBusinessName: "GOENERGY S.R.L."},
  {brpId: "DP2323", brpBusinessName: "MINERAL BARIUM ITALIA GAS ELUCE SRL"},
  {brpId: "DP2533", brpBusinessName: "ERG POWER SRL"},
  {brpId: "DP2547", brpBusinessName: "LIMEON SRL"},
  {brpId: "DP2544", brpBusinessName: "EU1 SRL"},
  {brpId: "DP2182", brpBusinessName: "DIMENSIONE SOLARE SRL"},
  {brpId: "DP2130", brpBusinessName: "SASOL ITALY SPA"},
  {brpId: "DP2545", brpBusinessName: "MASSIMA ENERGIA SPA"},
  {brpId: "DP2530", brpBusinessName: "WITHU ITALIA  SRL"},
  {brpId: "DP0182", brpBusinessName: "ENEL PRODUZIONE SPA"},
  {brpId: "DP2538", brpBusinessName: "FUTURENERGY RINNOVABILE SRL"},
  {brpId: "DP2031", brpBusinessName: "EGO ENERGY S.R.L."},
  {brpId: "DP1616", brpBusinessName: "ENI PLENITUDE  SPA    SOCIETA'  BENEFIT"},
  {brpId: "DP2537", brpBusinessName: "5 G SRL"},
  {brpId: "DP2551", brpBusinessName: "NORBERT FINCH ENERGY SRL"},
  {brpId: "DP2080", brpBusinessName: "DALMINE SPA"},
  {brpId: "DP2548", brpBusinessName: "ENERBI SRL"},
  {brpId: "DP2539", brpBusinessName: "OCTOPUS ENERGY ITALIA  SRL"},
  {brpId: "DP2541", brpBusinessName: "ENERGIA AZZURRA SRL"},
  {brpId: "DP2543", brpBusinessName: "SELLING COMPANY SRL"},
  {brpId: "DP2552", brpBusinessName: "CIP LOMBARDIA SRL"},
  {brpId: "DP2159", brpBusinessName: "ENSCO S.A."},
  {brpId: "DP2107", brpBusinessName: "ENSCO S.A."},
  {brpId: "DP0386", brpBusinessName: "SERVIZI INTEGRATI ENERGIA SIE SRL"},
  {brpId: "DP0297", brpBusinessName: "ALPERIA SUM SPA"},
  {brpId: "DP0340", brpBusinessName: "TIWAG ITALIA SRL"},
  {brpId: "DP1282", brpBusinessName: "CARTIERE ERMOLLI SPA"},
  {brpId: "DP2237", brpBusinessName: "KRELGAS SRL"},
  {brpId: "DP2358", brpBusinessName: "GREEK ENVIRONMENTAL & ENERGY NETWORK"},
  {brpId: "DP1850", brpBusinessName: "OLIMPIA ENERGIA E GAS"},
  {brpId: "DP1866", brpBusinessName: "STE ENERGETIKA DOO"},
  {brpId: "DP1847", brpBusinessName: "ERGON ENERGIA SRL"},
  {brpId: "DP2068", brpBusinessName: "UNITRADING ENERGIA S.P.A."},
  {brpId: "DP2090", brpBusinessName: "NOVAERA ENERGY S.A."},
  {brpId: "DP1977", brpBusinessName: "UTILITYNORTH A/S"},
  {brpId: "DP1951", brpBusinessName: "ENECO POWEL SA"},
  {brpId: "DP2048", brpBusinessName: "NOA ENERGIA"},
  {brpId: "DP0163", brpBusinessName: "AZ.ENERG. SPA - ETSCHWERKE AG"},
  {brpId: "DP1198", brpBusinessName: "BARCLAYS BANK PLC"},
  {brpId: "DP2283", brpBusinessName: "DELTAGIZERO"},
  {brpId: "DP2281", brpBusinessName: "NOVAWATT"},
  {brpId: "DP2253", brpBusinessName: "SIMPOSIO SRL"},
  {brpId: "DP2259", brpBusinessName: "NECTAWARE S.R.L."},
  {brpId: "DP2359", brpBusinessName: "ECOWAY S.P.A."},
  {brpId: "DP1524", brpBusinessName: "IBERDROLA GENERACION SAU"},
  {brpId: "DP2053", brpBusinessName: "MASTER TRADING SRL"},
  {brpId: "DP1148", brpBusinessName: "GDF SUEZ  ENERGIE  SPA"},
  {brpId: "DP1147", brpBusinessName: "DUFERCO SERTUBI SPA"},
  {brpId: "DP0230", brpBusinessName: "REPOWER ITALIA SPA"},
  {brpId: "DP0962", brpBusinessName: "WORLDENERGY ITALIA SRL"},
  {brpId: "DP2211", brpBusinessName: "ENOVA SRL"},
  {brpId: "DP2556", brpBusinessName: "PIEMONTE ENERGY SPA"},
  {brpId: "DP2178", brpBusinessName: "MAXIMA ITALIA"},
  {brpId: "DP0238", brpBusinessName: "ASTEA ENERGIA SPA"},
  {brpId: "DP0155", brpBusinessName: "ENISERVIZI SPA"},
  {brpId: "DP1195", brpBusinessName: "RWE SUPPLY & TRADING NETHERLANDS B.V."},
  {brpId: "DP2244", brpBusinessName: "NGC 6611 SRL"},
  {brpId: "DP2555", brpBusinessName: "ENERGY GROWERS S.R.L."},
  {brpId: "DP2069", brpBusinessName: "ENERGY GREEN CITY  SPA"},
  {brpId: "DP2059", brpBusinessName: "INTERCONSULT SPA"},
  {brpId: "DP2104", brpBusinessName: "ENOI POWER"},
  {brpId: "DP2119", brpBusinessName: "TRAILSTONE GMBH"},
  {brpId: "DP2074", brpBusinessName: "PROTERGIA POWER GENERATION AND SUPPLIES"},
  {brpId: "DP1953", brpBusinessName: "JETIVIA SA"},
  {brpId: "DP2061", brpBusinessName: "CONSORZIO CARTIERE IN TIVOLI"},
  {brpId: "DP1608", brpBusinessName: "AXOPOWER S.P.A."},
  {brpId: "DP2103", brpBusinessName: "ENTRACO INTERNATIONAL"},
  {brpId: "DP2079", brpBusinessName: "SORYX"},
  {brpId: "DP2020", brpBusinessName: "WORLDENERGY SA"},
  {brpId: "DP1130", brpBusinessName: "ELETTROGREEN SPA"},
  {brpId: "DP2226", brpBusinessName: "50 HERTZ"},
  {brpId: "DP2188", brpBusinessName: "GLOBO ENERGIA SRL"},
  {brpId: "DP2432", brpBusinessName: "FORGREEN HUB S.R.L"},
  {brpId: "DP1881", brpBusinessName: "ERG POWER & GAS SPA"},
  {brpId: "DP1842", brpBusinessName: "ASCO ENERGY S.P.A."},
  {brpId: "DP1851", brpBusinessName: "RUDNAP HUNGARY ENERGIA KERESKEDELMI KFT"},
  {brpId: "DP2399", brpBusinessName: "FREE LUCE & GAS S.R.L."},
  {brpId: "DP2122", brpBusinessName: "FREE LUCE & GAS S.R.L."},
  {brpId: "DP0265", brpBusinessName: "GDF SUEZ ENERGY MANAGEMENT"},
  {brpId: "DP2217", brpBusinessName: "NOBLE CLEAN FUELS LIMITED"},
  {brpId: "DP2450", brpBusinessName: "ZENERGIA S.R.L."},
  {brpId: "DP0107", brpBusinessName: "GEOENERGIE SPA"},
  {brpId: "DP2204", brpBusinessName: "ENERGY NET ITALIA SRL"},
  {brpId: "DP2175", brpBusinessName: "TERSICORE"},
  {brpId: "DP0368", brpBusinessName: "NUOVE CARTIERE DI TIVOLI SPA"},
  {brpId: "DP1156", brpBusinessName: "EVIVA SPA"},
  {brpId: "DP0146", brpBusinessName: "EVIVA SPA"},
  {brpId: "DP2155", brpBusinessName: "ELEKTRO ENERGIJA D.O.O."},
  {brpId: "DP2154", brpBusinessName: "IDROTRADE"},
  {brpId: "DP2124", brpBusinessName: "API NOVA ENERGIA SRL"},
  {brpId: "DP1922", brpBusinessName: "ALFANO ENERGIA SPA"},
  {brpId: "DP1905", brpBusinessName: "THE ROYAL BANK OF SCOTLAND PLC"},
  {brpId: "DP1902", brpBusinessName: "EN.E.R TRADING SPA"},
  {brpId: "DP1895", brpBusinessName: "ENERGHE SPA"},
  {brpId: "DP1904", brpBusinessName: "ONDA SRL"},
  {brpId: "DP1829", brpBusinessName: "ENERGIEALLIANZ AUSTRIA GMBH"},
  {brpId: "DP2282", brpBusinessName: "ENERGY ENTERPRICE"},
  {brpId: "DP2294", brpBusinessName: "B.F.D. ENERGY"},
  {brpId: "DP2303", brpBusinessName: "ASPM ENERGIA S.R.L."},
  {brpId: "DP2295", brpBusinessName: "YOUTRADE ENERGY COMMODITIES S.A."},
  {brpId: "DP2289", brpBusinessName: "EDISON ENERGIE S.P.A."},
  {brpId: "DP2411", brpBusinessName: "ENERGRID S.R.L."},
  {brpId: "DP0919", brpBusinessName: "ALFANO SRL IN LIQUIDAZIONE"},
  {brpId: "DP1816", brpBusinessName: "IMC ENERGY TRADING BV"},
  {brpId: "DP0086", brpBusinessName: "IDROENERGIA SCRL"},
  {brpId: "DP2365", brpBusinessName: "VOLTON HELLENIC ENERGY SOCIETE ANONYME"},
  {brpId: "DP1962", brpBusinessName: "ELPEDISON ENERGY S.A."},
  {brpId: "DP1990", brpBusinessName: "EPIU' S.R.L. - IN LIQUIDAZIONE"},
  {brpId: "DP2072", brpBusinessName: "TRANSOHM AG"},
  {brpId: "DP1900", brpBusinessName: "YOUTRADE SPA"},
  {brpId: "DP0299", brpBusinessName: "AMGA ENERGIA E SERVIZI SRL"},
  {brpId: "DP0159", brpBusinessName: "CENTOMILACANDELE SCPA"},
  {brpId: "DP0198", brpBusinessName: "CLEANPOWER SOGLIO S.R.L."},
  {brpId: "DP2234", brpBusinessName: "G.E.T. ENERGY S.R.L."},
  ]

export const brpContractOptions = [
  {
    brpContractId: 'DP0062',
    brpName: ['UC_DP0062_CSUD'],
  },
  {
    brpContractId: 'DP0060',
    brpName: ['UC_DP0060_CSUD'],
  },
  {
    brpContractId: 'DI002513',
    brpName: [
      'UP_DI2513_CSUD_C',
      'UP_DI2513_NORD_B',
      'UP_DI2513_CALA_G',
      'UP_DI2513_CSUD_G',
      'UP_DI2513_CALA_C',
      'UP_DI2513_NORD_G',
      'UP_DI2513_CSUD_A',
      'UP_DI2513_CSUD_B',
    ],
  },
  {
    brpContractId: 'DP7001',
    brpName: ['UC_DP7001_CALA'],
  },
  {
    brpContractId: 'DP1616',
    brpName: ['UC_DP1616_NORD', 'UC_DP1616_CSUD'],
  },
  {
    brpContractId: 'DP1676',
    brpName: ['UC_DP1676_CALA', 'UC_DP1676_CSUD'],
  },
  {
    brpContractId: 'DI001170',
    brpName: ['UP_DI1170_CSUD_G'],
  },
  {
    brpContractId: 'DI001885',
    brpName: ['UP_DI1885_CSUD_C'],
  },

  {
    brpContractId: 'DI002755',
    brpName: ['UP_DI2755_NORD_C'],
  },
  {
    brpContractId: 'DP2017',
    brpName: ['UC_DP2017_CSUD'],
  },
  {
    brpContractId: 'DP4119',
    brpName: ['UC_DP4119_CSUD'],
  },
  {
    brpContractId: 'DP9004',
    brpName: ['UC_DP9004_NORD'],
  },
];
