import { useCallback } from 'react';
import { useAuthProvider } from 'react-admin';

const getRoleWithoutProvider = (): string[] => [];

const useGetRole = () => {
  const authProvider = useAuthProvider();
  const getRole = useCallback(
    (clientId: string): string[] =>
      authProvider.getRoles().realm_access.roles.includes('admin')
        ? ['admin']
        : Array.from<string>(
            new Set(
              authProvider
                .getRoles()
                .resource_access[clientId].roles.map((role: string) =>
                  role.includes('admin')
                    ? 'admin'
                    : role.split('-').slice(2).join('-')
                )
            )
          ).filter((val) => !!val),

    [authProvider]
  );

  return authProvider ? getRole : getRoleWithoutProvider;
};

export default useGetRole;
