import {
  AutocompleteInput,
  Create,
  DateInput,
  NumberInput,
  ReferenceInput,
  ResourceComponentProps,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  number,
  required,
  useTranslate
} from 'react-admin';

const PostCreateToolbar = (props: ToolbarProps) => {
  const handleTransform = (data: any) => {
    return {...data};
  };
  return (
    <Toolbar {...props}>
      <SaveButton transform={handleTransform} />
    </Toolbar>
  );
};

export const CustomContractCreate = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.custom-contracts';
  return (
    <Create {...props}>
      <SimpleForm
        redirect="show"
        variant="standard"
        toolbar={<PostCreateToolbar />}
      >
        <ReferenceInput
          validate={required()}
          label={translate(`${tFP}.fields.legalEntity`)}
          source="legalEntity"
          allowEmpty={false}
          filterToQuery={(q) => ({ filter: { name: q } })}
          reference="companies"
          className="editCreateForm"
        >
          <AutocompleteInput optionText="businessName" />
        </ReferenceInput>
        <ReferenceInput
          validate={required()}
          label={translate(`${tFP}.fields.counterpart`)}
          source="counterpart"
          allowEmpty={false}
          filterToQuery={(q) => ({ filter: { name: q } })}
          reference="companies"
          className="editCreateForm"
        >
          <AutocompleteInput optionText="businessName" />
        </ReferenceInput>
        <DateInput
          source="contractDate"
          label={translate(`${tFP}.fields.contractDate`)}
          validate={[required()]}
          className="editCreateForm"
        />
        <DateInput
          source="startDate"
          label={translate(`${tFP}.fields.startDate`)}
          validate={[required()]}
          className="editCreateForm"
        />
        <DateInput
          source="endDate"
          label={translate(`${tFP}.fields.endDate`)}
          validate={[required()]}
          className="editCreateForm"
        />
        <SelectInput
          variant="standard"
          label={translate(`${tFP}.fields.mark`)}
          source="mark"
          choices={['B'].map((mark) => {
            return {
              id: mark,
              name: mark,
            };
          })}
          validate={required()}
          className="editCreateForm"
        />
        <SelectInput
          variant="standard"
          label={translate(`${tFP}.fields.paymentId`)}
          source="paymentId"
          choices={['FMF'].map((paymentId) => {
            return {
              id: paymentId,
              name: paymentId,
            };
          })}
          validate={required()}
          className="editCreateForm"
        />
        <SelectInput
          variant="standard"
          label={translate(`${tFP}.fields.renewalId`)}
          source="renewalId"
          choices={['ESC', 'RIPF'].map((renewalId) => {
            return {
              id: renewalId,
              name: renewalId,
            };
          })}
          validate={required()}
          className="editCreateForm"
        />
        <SelectInput
          variant="standard"
          label={translate(`${tFP}.fields.upmLoan`)}
          source="upmLoan"
          choices={['GRA'].map((upmLoan) => {
            return {
              id: upmLoan,
              name: upmLoan,
            };
          })}
          validate={required()}
          className="editCreateForm"
        />
        <SelectInput
          variant="standard"
          label={translate(`${tFP}.fields.penalty`)}
          source="penalty"
          choices={['PAS', 'RIM'].map((penalty) => {
            return {
              id: penalty,
              name: penalty,
            };
          })}
          validate={required()}
          className="editCreateForm"
        />
        <NumberInput
          source="auctionSupplierFee"
          validate={[required(), number()]}
          className="editCreateForm"
        />
        <NumberInput
          source="spotSupplierFee"
          validate={[required(), number()]}
          className="editCreateForm"
        />
        <ReferenceInput
          label={translate(`${tFP}.fields.mediator`)}
          source="mediator"
          allowEmpty={false}
          filterToQuery={(q) => ({ filter: { name: q } })}
          reference="companies"
          className="editCreateForm"
        >
          <AutocompleteInput optionText="businessName" resettable/>
        </ReferenceInput>
        <NumberInput
          source="mediatorFee"
          validate={number()}
          className="editCreateForm"
        />
      </SimpleForm>
    </Create>
  );
};
