import { makeStyles, Typography } from '@material-ui/core';
import {
  ChipField,
  Datagrid,
  FunctionField,
  List,
  Record,
  ReferenceField,
  ReferenceInput,
  ResourceComponentProps,
  SelectInput,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin';
import { ShowEditButtons } from '../../lib/react-admin_lib/ShowEditButtons';
import { EditableStatus } from './status-management-components/EditableStatusView';
// import MultipleSelectCheckmarks from '../lib/mui_lib/select-multiple';
import { gridVoltageOptions } from './gcpConstants';
import { findLocation, locationId } from './GridConnectionPointShow';
// import { gridVoltageOptions } from './gcpConstants';

const useStyles = makeStyles({
  chipfieldColor: {
    color: '#ffffff',
  },
  fieldHeaderAlign: {
    textAlign: 'right',
  },
  fieldHeaderAlignLeft: {
    textAlign: 'left',
  },
});

export const GridConnectionPointList = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const classes = useStyles();
  // const [selectedStatus, setSelectedStatus] = React.useState<
  //   string[] | undefined
  // >();

  const tFP = 'resources.grid-connection-points';
  const filters = [
    <TextInput
      placeholder={translate(`${tFP}.fields.meteringPointId`)}
      style={{ width: '200px' }}
      source="meteringPointId"
      alwaysOn
      resettable
      variant="standard"
    />,
    <TextInput
      placeholder={translate(`${tFP}.fields.resourceName`)}
      style={{ width: '200px' }}
      source="resourceName"
      alwaysOn
      resettable
      variant="standard"
    />,
    <TextInput
      placeholder={translate(`${tFP}.fields.exergyId`)}
      source="exergyId"
      style={{ width: '200px' }}
      alwaysOn
      resettable
      variant="standard"
    />,
    <SelectInput
      label={translate(`${tFP}.fields.direction`)}
      source="direction"
      style={{ width: '200px' }}
      alwaysOn
      choices={['generation', 'consumption', 'mixed'].map((direction) => {
        return {
          id: direction,
          name: translate(`${tFP}.direction.${direction}`),
        };
      })}
      variant="standard"
    />,
    // <MultipleSelectCheckmarks
    //   list={[
    //     'None',
    //     'Registered',
    //     'Open',
    //     'Rejected',
    //     'UnderReview',
    //     'PreValidated',
    //     'Validated',
    //     'Released',
    //   ]}
    //   onChange={setSelectedStatus}
    //   value={selectedStatus?.sort()}
    //   labelName={translate(`${tFP}.fields.currentStatus`)}
    // />,

    <SelectInput
      label={translate(`${tFP}.fields.currentStatus`)}
      source="currentStatus"
      variant="standard"
      alwaysOn
      style={{ width: '200px' }}
      choices={[
        'None',
        'Registered',
        'Open',
        'Rejected',
        'UnderReview',
        'PreValidated',
        'Validated',
        'Released',
      ].map((status) => {
        return { id: status, name: translate(`${tFP}.status.${status}`) };
      })}
    />,
    <TextInput
      placeholder={translate(`${tFP}.fields.brpName`)}
      source="brpName"
      style={{ width: '200px' }}
      alwaysOn
      resettable
      variant="standard"
    />,
    <ReferenceInput
      reference="bsp"
      source="bsp"
      link="show"
      alwaysOn
      variant="standard"
    >
      <SelectInput
        optionText="name"
        optionValue="id"
        style={{ width: '200px' }}
      />
    </ReferenceInput>,

    <SelectInput
      variant="standard"
      label={translate(`${tFP}.fields.gridVoltageLevel`)}
      source="gridVoltageLevel"
      style={{ width: '200px' }}
      alwaysOn
      choices={gridVoltageOptions.map((level) => {
        return {
          id: level,
          name: level,
        };
      })}
    />,
  ];
  return (
    <List
      {...props}
      filters={filters}
      bulkActionButtons={false}
      exporter={false}
    >
      <Datagrid rowClick="show">
        <TextField source="meteringPointId" />
        <TextField source="resourceName" />
        <TextField source="exergyId" />
        <TextField source="gridVoltageLevel" />
        <FunctionField
          source="direction"
          render={(r: Record | undefined) =>
            translate(`${tFP}.direction.${r!.direction}`)
          }
        />
        <FunctionField
          source="currentStatus"
          render={(r: Record | undefined) => (
            <Typography variant="body2">
              {r?.currentLocation &&
                findLocation(locationId(r?.currentLocation)) &&
                findLocation(locationId(r?.currentLocation))?.[
                  locationId(r?.currentLocation)
                ]}
            </Typography>
          )}
          label={translate(`${tFP}.fields.currentLocation`)}
        />
        <TextField source="brpName" />
        <FunctionField
          source="currentStatus"
          render={(r: Record | undefined) =>
            translate(`${tFP}.status.${r!.currentStatus}`)
          }
          sortable={false}
        />

        <ReferenceField
          reference="uvam"
          source="currentUvam"
          link="show"
          sortable={false}
        >
          <ChipField
            source="name"
            size="small"
            color="primary"
            className={classes.chipfieldColor}
            clickable={true}
          />
        </ReferenceField>

        <ReferenceField reference="bsp" source="bsp" link="show">
          <ChipField
            source="name"
            size="small"
            color="primary"
            className={classes.chipfieldColor}
            clickable={true}
          />
        </ReferenceField>

        <ShowEditButtons editableStatus={Object.values(EditableStatus)} />
      </Datagrid>
    </List>
  );
};
