import { config } from '@config/config';
import { Divider, makeStyles } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import DashboardIcon from '@material-ui/icons/Dashboard';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import SettingsIcon from '@material-ui/icons/Settings';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import classnames from 'classnames';
import lodashGet from 'lodash/get';
import { MenuItemLink, MenuProps, ReduxState, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { useUserHasPermissions } from '../lib/custom-hooks/useUserHasPermissions';
import { MenuItemIcon } from '../lib/mui_lib/menu-item-icon';
import { MenuDropDown } from '../lib/mui_lib/sidebar-dropdown';
import { ResourceMenuItem } from '../lib/react-admin_lib/resource-menu-item';
import { InvoiceUserView } from '../lib/user-management/InvoiceUserView';

export const MENU_WIDTH = 240;
export const CLOSED_MENU_WIDTH = 55;

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: '0.5em',
      marginBottom: '1em',
      [theme.breakpoints.only('xs')]: {
        marginTop: 0,
      },
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    open: {
      width: lodashGet(theme, 'menu.width', MENU_WIDTH),
    },
    closed: {
      width: lodashGet(theme, 'menu.closedWidth', CLOSED_MENU_WIDTH),
    },
    alignItemsFlexStart: {
      justifyContent: ' inherit',
    },
    icon: { minWidth: theme.spacing(5) },
    iconActive: {
      minWidth: theme.spacing(5),
      color: theme.palette.text.primary,
    },
    active: {
      color: theme.palette.text.primary,
    },
    nestedopen: {
      paddingLeft: theme.spacing(4),
    },
    nestedclosed: {
      paddingLeft: theme.spacing(2),
    },
    root: {
      color: theme.palette.text.secondary,
    },
  }),
  { name: 'RaMenu' }
);

const Menu = (props: MenuProps) => {
  const { className, dense } = props;
  const classes = useStyles(props);
  const menuOpen = useSelector(
    (state: ReduxState) => state.admin.ui.sidebarOpen
  );
  const isAdmin = useUserHasPermissions('admin');
  const translate = useTranslate();
  const tFP = 'resources';

  return (
    <div
      className={classnames(
        classes.main,
        {
          [classes.open]: menuOpen,
          [classes.closed]: !menuOpen,
        },
        className
      )}
    >
      <MenuItemLink
        to="/"
        primaryText={translate(`${tFP}.dashboard.name`)}
        leftIcon={<MenuItemIcon icon={<DashboardIcon />} to="/" exact />}
        sidebarIsOpen={menuOpen}
        dense={dense}
        exact
      />
      <Divider />
      <ResourceMenuItem name="companies" />
      {InvoiceUserView(<ResourceMenuItem name="invoices" />)}

      {/* <MenuItemLink
        to="/reports"
        primaryText={translate(`${tFP}.invoices.name`)}
        leftIcon={<MenuItemIcon icon={<EuroIcon />} to="/reports" />}
        sidebarIsOpen={menuOpen}
      /> */}
      {config.showIpex && (
        <MenuDropDown
          leftIcon={<InsertChartIcon />}
          name={translate(`${tFP}.reports.name`)}
        >
          <MenuItemLink
            to="/hours-ipex-graph"
            primaryText={translate(`${tFP}.reports.hourly_ipex.name`)}
            leftIcon={
              <MenuItemIcon icon={<ShowChartIcon />} to="/hours-ipex-graph" />
            }
            sidebarIsOpen={menuOpen}
            className={menuOpen ? classes.nestedopen : classes.nestedclosed}
          />
          <MenuItemLink
            to="/average-ipex-graph"
            primaryText={translate(`${tFP}.reports.average_ipex.name`)}
            leftIcon={
              <MenuItemIcon icon={<BarChartIcon />} to="/average-ipex-graph" />
            }
            sidebarIsOpen={menuOpen}
            className={menuOpen ? classes.nestedopen : classes.nestedclosed}
          />
        </MenuDropDown>
      )}
      {isAdmin && (
        <>
          <Divider />
          <ResourceMenuItem name="contracts" />
          <ResourceMenuItem name="plants" />
        </>
      )}
      {isAdmin && config.showUvam && (
        <>
          <Divider />
          <ResourceMenuItem name="grid-connection-points" />
          <ResourceMenuItem name="uvam" />
          <ResourceMenuItem name="bsp" />
          <ResourceMenuItem name="products" />
          <ResourceMenuItem name="custom-contracts" />
          <ResourceMenuItem name="grid-connection-point-custom-contracts" />
        </>
      )}
      <Divider />
      <MenuItemLink
        to="/settings"
        primaryText={translate(`${tFP}.settings.name`)}
        leftIcon={<MenuItemIcon icon={<SettingsIcon />} to="/settings" />}
        sidebarIsOpen={menuOpen}
      />
    </div>
  );
};

export default Menu;
