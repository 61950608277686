import { Box } from '@material-ui/core';
import { Add, Edit } from '@material-ui/icons';
import {
  ArrayField,
  Datagrid,
  FunctionField,
  Record,
  ReferenceField,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import { GenericFilterComponent } from '../../../lib/custom-useful-components/GenericFilterComponent';
import { Empty } from '../../../lib/mui_lib/empty';
import { ButtonField } from '../../../lib/react-admin_lib/button-field';
import { EditableStatusView } from '../status-management-components/EditableStatusView';

enum UpEditableStatus {
  Open = 'Open',
  Rejected = 'Rejected',
}

export const Up = () => {
  const history = useHistory();
  const translate = useTranslate();
  const tFP = 'resources.grid-connection-points.tabs.up';

  const record = useRecordContext();
  const handleOnClickAddNewUp = (record: Record) => {
    history.push({
      pathname: '/generation-units/create',
      state: { GcpID: record.id },
    });
  };
  return (
    <Box mb={2}>
      <Box display="flex" justifyContent="right">
        <EditableStatusView editableStatus={UpEditableStatus}>
          <ButtonField
            label="Create"
            onClick={handleOnClickAddNewUp}
            startIcon={<Add />}
          />
        </EditableStatusView>
      </Box>
      <GenericFilterComponent
        filter={() => record.generationUnits?.length > 0}
        empty={<Empty resource="generation-units" />}
      >
        <ArrayField source="generationUnits" label="">
          <Datagrid>
            <ReferenceField
              label={translate(`${tFP}.fields.resourceId`)}
              source="generationUnit"
              reference="generation-units"
              link={false}
            >
              <TextField source="resourceId" />
            </ReferenceField>
            <ReferenceField
              label={translate(`${tFP}.fields.resourceName`)}
              source="generationUnit"
              reference="generation-units"
              link={false}
            >
              <TextField source="resourceName" />
            </ReferenceField>
            <ReferenceField
              label={translate(`${tFP}.fields.maximumFlexDown`)}
              source="generationUnit"
              reference="generation-units"
              link={false}
            >
              <TextField source="maximumFlexDown" />
            </ReferenceField>
            <ReferenceField
              label={translate(`${tFP}.fields.maximumFlexUp`)}
              source="generationUnit"
              reference="generation-units"
              link={false}
            >
              <TextField source="maximumFlexUp" />
            </ReferenceField>
            <ReferenceField
              label={translate(`${tFP}.fields.currentStatus`)}
              source="generationUnit"
              reference="generation-units"
              link={false}
            >
              <FunctionField
                source="currentStatus"
                render={(r: Record | undefined) =>
                  translate(`${tFP}.status.${r!.currentStatus}`)
                }
              />
            </ReferenceField>

            <FunctionField
              source=""
              render={(r: Record | undefined) => (
                <ButtonField
                  label="Edit"
                  onClick={function (id: any): void {
                    history.push({
                      pathname: '/generation-units/' + r!.generationUnit,
                      state: { GcpID: record!.id },
                    });
                  }}
                >
                  <Edit fontSize="inherit" />
                </ButtonField>
              )}
            />
          </Datagrid>
        </ArrayField>
      </GenericFilterComponent>
    </Box>
  );
};
