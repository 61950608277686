import { config } from "@config/config";
import { Box } from "@material-ui/core";
import {
  ChipField,
  ReferenceArrayField,
  Show,
  ShowProps,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  Title,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { useUserHasPermissions } from "../../lib/custom-hooks/useUserHasPermissions";
import { ForecastAvaiabilityTab } from "../../pages/companies-resource-tabs/forecast-avaiability-tab";
import { PlantsGraphTab } from "../../pages/companies-resource-tabs/plants-graph-tab";
import { CompanyPlantList } from "../../pages/companies-resource-tabs/plants-list";
import {
  AddUserModal,
  UsersTab,
} from "../../pages/companies-resource-tabs/users-tab";


const CompanyTabsShow = (props: any) => {
  const translate = useTranslate();
  const tFP = "resources.companies";
  const record = useRecordContext();

  const usersTabResellerPermissions = record?.resellers.includes(
    config.currentReseller
  );
  const usersTabPermissions = useUserHasPermissions([
    "admin",
    `company-${props.id}-manager`,
  ]);
  const plantGraphTabPermissions = useUserHasPermissions([
    "admin",
    `company-${props.id}-manager`,
    `company-${props.id}-production-unit`,
    `company-${props.id}-read-unit`,
  ]);
  const forecastAvaiabilityTabPermissions = useUserHasPermissions([
    "admin",
    `company-${props.id}-manager`,
    `company-${props.id}-production-unit`,
  ]);

  return (
    <TabbedShowLayout syncWithLocation={false}>
      {plantGraphTabPermissions && (
        <Tab
          label={translate(`${tFP}.tabs.plants_graph_tab.name`)}
          path="plant-graph"
          value="/companies/1/show/plant-graph"
        >
          <PlantsGraphTab />
        </Tab>
      )}

      {forecastAvaiabilityTabPermissions && (
        <Tab
          label={translate(`${tFP}.tabs.forecast_avaiability_tab.name`)}
          path="forecast"
        >
          <ForecastAvaiabilityTab />
        </Tab>
      )}

      <Tab label={translate(`${tFP}.tabs.info_tab.name`)} path="info">
        <Title title={translate(`${tFP}.tabs.info_tab.name`)} />
        <TextField source="businessName" />
        <TextField source="vatNumber" />
        <TextField source="legalAddress.address" label={translate(`${tFP}.fields.legalAddress.address`)} />
        <TextField source="legalAddress.city.name" label={translate(`${tFP}.fields.legalAddress.city.name`)} />
        <ReferenceArrayField
          reference="plants"
          source="ownedPlants"
          sort={{ field: "upCode", order: "ASC" }}
        >
          <SingleFieldList linkType="show">
            <Box mb={2}>
              <ChipField
                source="upCode"
                size="small"
                color="primary"
                clickable={true}
              />
            </Box>
          </SingleFieldList>
        </ReferenceArrayField>
      </Tab>
      <Tab label={translate(`${tFP}.tabs.plants_tab.name`)} path="plants">
        <Title title={translate(`${tFP}.tabs.plants_tab.name`)} />

        <CompanyPlantList companyId={props.id} />
        
      </Tab>

      {usersTabPermissions && usersTabResellerPermissions && (
        <Tab label={translate(`${tFP}.tabs.users_tab.name`)} path="users">
          <Box display="flex" flexDirection="row-reverse" pt={1}>
            <AddUserModal />
          </Box>
          <UsersTab source="members" />
        </Tab>
      )}
    </TabbedShowLayout>
  );
};

export const CompanyShow = (props: ShowProps) => {
  return (
    <Show {...props} title={<CompanyTitle props />}>
      <CompanyTabsShow {...props} />
    </Show>
  );
};

const CompanyTitle = (props: any) => {
  const translate = useTranslate();
  const tFP = "resources.companies";
  return (
    <>
      {translate(`${tFP}.name`, 1)}
      {props.record ? " - " + props.record.businessName : ""}
      &nbsp;-&nbsp;
    </>
  );
};
