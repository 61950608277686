import {
  AutocompleteInput,
  Create,
  DateInput,
  NumberInput,
  ReferenceInput,
  ResourceComponentProps,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  number,
  required,
  useGetList,
  useGetMany,
  useTranslate
} from 'react-admin';

const PostCreateToolbar = (props: ToolbarProps) => {
  const handleTransform = (data: any) => {
    return {...data};
  };
  return (
    <Toolbar {...props}>
      <SaveButton transform={handleTransform} />
    </Toolbar>
  );
};

export const GridConnectionPointCustomContractCreate = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.grid-connection-point-custom-contracts';
  const customContractList = useGetList('custom-contracts');
  const counterpartIds = Object.values(customContractList?.data).map((element) => (element.counterpart));
  const counterpartList = useGetMany('companies', counterpartIds);
  const counterpartLabelMap: Record<string, string> = counterpartList.data.reduce((acc: Record<string, string>, item) => {
    if (item) {
      acc[item.id] = item.businessName;
    }
    return acc;
  }, {});
  return (
    <Create {...props}>
      <SimpleForm
        redirect="show"
        variant="standard"
        toolbar={<PostCreateToolbar />}
      >
        <ReferenceInput
          validate={required()}
          label={translate(`${tFP}.fields.customContract`)}
          source="customContract"
          allowEmpty={false}
          filterToQuery={(q) => ({ filter: { name: q } })}
          reference="custom-contracts"
          className="editCreateForm"
        >
          <SelectInput optionText={(record) => {
              const counterpart = counterpartLabelMap[record.counterpart];
              return counterpart ? `${record.id} - ${counterpart}` : `${record.id}`;
            }}
          />
        </ReferenceInput>
        <DateInput
          source="allocationDate"
          label={translate(`${tFP}.fields.allocationDate`)}
          validate={[required()]}
          className="editCreateForm"
        />
        <DateInput
          source="startDate"
          label={translate(`${tFP}.fields.startDate`)}
          validate={[required()]}
          className="editCreateForm"
        />
        <DateInput
          source="endDate"
          label={translate(`${tFP}.fields.endDate`)}
          validate={[required()]}
          className="editCreateForm"
        />
        <ReferenceInput
          validate={required()}
          label={translate(`${tFP}.fields.gcpId`)}
          source="gridConnectionPoint"
          allowEmpty={false}
          filterToQuery={(q) => ({ filter: { name: q } })}
          reference="grid-connection-points"
          className="editCreateForm"
        >
          <AutocompleteInput optionText="meteringPointId" />
        </ReferenceInput>
        <ReferenceInput
          validate={required()}
          label={translate(`${tFP}.fields.product`)}
          source="product"
          allowEmpty={false}
          filterToQuery={(q) => ({ filter: { name: q } })}
          reference="products"
          className="editCreateForm"
        >
          <SelectInput optionText={(record) => `${record.id} - ${record.productCode} - ${record.type}`} />
        </ReferenceInput>
        <NumberInput
          source="powerMw"
          validate={[required(), number()]}
          className="editCreateForm"
        />
        <NumberInput
          source="price"
          validate={[required(), number()]}
          className="editCreateForm"
        />
        <TextInput
          source="note"
          multiline
          className="editCreateForm"
        />
      </SimpleForm>
    </Create>
  );
};
