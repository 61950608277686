import { config } from '@config/config';
import React from 'react';
import useGetRole from '../custom-hooks/useRoles';
import { GenericFilterView } from './GenericFilterView';

export const InvoiceUserView = (
  component: React.ReactElement | React.ReactElement[] | false
) => {
  const getUserRoles = useGetRole();

  return GenericFilterView({
    filter: () =>
      getUserRoles(config.authConfig.Keycloak.clientId).some((role) =>
        ['admin', 'invoice', 'manager', 'read-unit'].includes(role)
      ),
    component,
  });
};
