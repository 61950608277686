import { Box } from '@material-ui/core';
import keyBy from 'lodash/keyBy';
import { Fragment, useState } from 'react';
import { Datagrid, DatagridProps, Loading } from 'react-admin';
import { useQuery } from '../../lib/custom-hooks/use-quey';
import { Pagination } from '../../lib/react-admin_lib/pagination';

interface ExtendedDatagridProps extends DatagridProps {
  paging?: boolean;
  resourceUrl?: string;
}

export const ExtendedDatagrid = ({
  paging = true,
  resource,
  resourceUrl = resource,
  children,
  empty,
  ...rest
}: ExtendedDatagridProps) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [sort, setSort] = useState({ field: 'id', order: 'ASC' });
  const { data, total, loading, error } = useQuery('getList', {
    resource: resourceUrl ?? '',
    payload: {
      pagination: { page, perPage },
      sort,
      filter: {},
    },
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <></>;
  }

  if (total === 0) {
    return empty ? empty : <></>;
  }

  return (
    <Fragment>
      <Datagrid
        data={keyBy(data, 'id')}
        ids={data?.map((r) => r.id) ?? []}
        currentSort={sort}
        setSort={(field, order) => setSort({ field, order: order ?? 'ASC' })}
        {...rest}
      >
        {children}
      </Datagrid>
      <Box display="flex" width="100%" justifyContent="right">
        {paging && (
          <Pagination
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            total={total}
          />
        )}
      </Box>
    </Fragment>
  );
};
