import React from 'react';
import {
  Create,
  DateTimeInput,
  maxValue,
  minValue,
  number,
  required,
  ResourceComponentProps,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useLocale,
  useTranslate,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { useHistory, useLocation } from 'react-router-dom';
import { RadioSelectField } from '../../lib/react-admin_lib/radio-select-field';
interface LocationState {
  UvamId: number;
  maximumQualifiedPower: number;
  minimumQualifiedPower: number;
}

const testActivationTimeTypeA = [{ id: 15, name: 15 }];
const testActivationTimeTypeB = [
  { id: 15, name: 15 },
  { id: 30, name: 30 },
  { id: 45, name: 45 },
  { id: 60, name: 60 },
  { id: 75, name: 75 },
  { id: 90, name: 90 },
  { id: 105, name: 105 },
  { id: 120, name: 120 },
];
const UserEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

const TestActivationField = (props: any) => {
  const { values } = useFormState();
  const options =
    values.testType === 'A' ? testActivationTimeTypeA : testActivationTimeTypeB;

  return (
    <SelectInput {...props} defaultValue={options[0].id} choices={options} />
  );
};

const TestPowerField = (props: any) => {
  const { values } = useFormState();
  const translate = useTranslate();
  const locale = useLocale();
  const tFP = 'resources.pq-tests';
  const TestPowerUpValidations = [
    required(),
    number(),
    minValue(0.8 * props.maximumQualifiedPower),
    maxValue(props.maximumQualifiedPower),
  ];
  const TestPowerDownValidations = [
    number(),
    minValue(props.minimumQualifiedPower),
    maxValue(0.8 * props.minimumQualifiedPower),
    required(),
  ];
  const helperText = `${translate(
    `${tFP}.fields.testPowerHelperText`
  )} (${new Intl.NumberFormat(locale).format(
    0.8 * props.maximumQualifiedPower
  )} - ${new Intl.NumberFormat(locale).format(props.maximumQualifiedPower)})`;
  const validate =
    values.testDirection === 'UP'
      ? TestPowerUpValidations
      : TestPowerDownValidations;

  const helperTextDown = `${translate(
    `${tFP}.fields.testPowerHelperText`
  )} (${new Intl.NumberFormat(locale).format(props.minimumQualifiedPower)} - ${new Intl.NumberFormat(
    locale
  ).format(0)})`;

  return (
    <TextInput
      {...props}
      validate={validate}
      helperText={
        values.testDirection === 'UP'
          ? helperText
          : values.testDirection === 'DOWN'
          ? helperTextDown
          : ''
      }
    />
  );
};

export const UvamPqTestsCreate = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.pq-tests';
  const location = useLocation<LocationState>();
  const history = useHistory();
  const UvamId = location?.state?.UvamId;
  const maximumQualifiedPower = location?.state?.maximumQualifiedPower;
  const minimumQualifiedPower = location?.state?.minimumQualifiedPower;
  const redirect = UvamId ? `/uvam/${UvamId}/show/pq-tests` : false;

  React.useEffect(() => {
    !UvamId && history.push('/uvam');
  }, [UvamId, history]);

  return (
    <Create {...props}>
      <SimpleForm
        redirect={redirect}
        initialValues={{
          uvamId: UvamId,
          dateOfIssue: new Date(),
        }}
        toolbar={<UserEditToolbar />}
        variant="standard"
      >
        <RadioSelectField
          source="testType"
          label={translate(`${tFP}.fields.testType.name`)}
          choices={[
            { value: 'A', label: translate(`${tFP}.fields.testType.A`) },
            { value: 'B', label: translate(`${tFP}.fields.testType.B`) },
          ]}
          validate={required()}
        />
        <RadioSelectField
          source="testDirection"
          label={translate(`${tFP}.fields.testDirection.name`)}
          choices={[
            { value: 'UP', label: translate(`${tFP}.fields.testDirection.up`) },
            {
              value: 'DOWN',
              label: translate(`${tFP}.fields.testDirection.down`),
            },
          ]}
          validate={required()}
        />
        <TestPowerField
          source="testPower"
          label={translate(`${tFP}.fields.testPower`)}
          maximumQualifiedPower={maximumQualifiedPower}
          minimumQualifiedPower={minimumQualifiedPower}
        />
        <TestActivationField
          source="testActivationTime"
          label={translate(`${tFP}.fields.testActivationTime`)}
        />

        <DateTimeInput
          source="testStartDate"
          label={translate(`${tFP}.fields.testStartDate`)}
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
};
