import MomentUtils from '@date-io/moment';
import { Button, Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import fileDownload from 'js-file-download';
import moment, { Moment } from 'moment';
import React, { Fragment } from 'react';

import { format } from 'date-fns';
import {
  Loading,
  Title,
  useNotify,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  TPlantGraphResponse,
  usePlantGraph,
} from '../../lib/custom-hooks/usePlantGraph';
import { useWindowSize } from '../../lib/custom-hooks/useWindowSize';

import { useDarkTheme } from '../../lib/custom-hooks/store-dark-theme';
import MultipleSelectCheckmarks from '../../lib/mui_lib/select-multiple';

import { useAxios } from '../../lib/custom-hooks/useAxios';

const formatDate = (date: Moment, format: string) => {
  return new MomentUtils().format(date, format);
};
const minDate = moment().subtract(4, 'years');
const initialStartDate = moment().startOf('month');
const initialEndDate = moment().endOf('month');

export const PlantsGraphTab = () => {
  const translate = useTranslate();
  const tFP = 'resources.companies.tabs.plants_graph_tab';
  const [startDate, setStartDate] = React.useState(initialStartDate);

  const record = useRecordContext();
  const [endDate, setEndDate] = React.useState(initialEndDate);
  const [loading, setLoading] = React.useState(false);
  const { height } = useWindowSize();
  const [plantGraphData, setPlantGraphData] =
    React.useState<TPlantGraphResponse>();
  const plantList =
    plantGraphData && plantGraphData.map((value) => value.plantName);

  const [selectedPlants, setSelectedPlants] = React.useState<
    string[] | undefined
  >();
  const { toggleDark } = useDarkTheme();
  const notify = useNotify();
  const { refetch } = useAxios();
  const { data } = usePlantGraph(plantGraphData, selectedPlants);
  const handleHourlyIpexStartDateChange = (e: any) => {
    setStartDate(e);
  };
  const handleHourlyIpexEndDateChange = (e: any) => {
    setEndDate(e);
  };

  const keyArray: ('m' | 'f' | 'a')[] = ['m', 'f', 'a'];

  const keyTranslation = {
    m: `${tFP}.metering`,
    f: `${tFP}.forecast`,
    a: `${tFP}.availability`,
  };

  const stroke = [
    '#7e57c2',
    '#66bb6a',
    '#29b6f6',
    '#f39c12',
    '#f1c40f',
    '#16a085',
    '#b39ddb',
    '#e74c3c',
  ];
  const [barProps, setBarProps] = React.useState(
    keyArray.reduce(
      (a: any) => {
        a = false;
        return a;
      },
      { hover: null }
    )
  );
  const selectLine = (e: any) => {
    setBarProps({
      ...barProps,
      [e.dataKey]: !barProps[e.dataKey],
      hover: null,
    });
  };
  const handleFileDownload = (extention: 'CSV' | 'XLS') => async () => {
    if (!selectedPlants?.length) {
      notify(translate(`${tFP}.select_one_plant_error`), 'error');
      return;
    }
    setLoading(true);
    const res = await refetch({
      url:
        `/companies/${
          record.id
        }/plant-data/download?format=${extention}&chartStart=${format(
          startDate.toDate(),
          'YYYY-MM-DD'
        )}&chartEnd=${format(endDate.toDate(), 'YYYY-MM-DD')}&` +
        plantGraphData
          ?.filter((pdg) => selectedPlants?.includes(pdg.plantName))
          .map((pdg) => `plantIds=${pdg.plantId}`)
          .join('&'),
      method: 'get',
      responseType: 'arraybuffer',
    });
    if (res) {
      const { result, error, loading } = res;
      if (result?.data)
        fileDownload(
          result?.data,
          result?.headers?.['content-disposition'].split('=')[1]
        );
      setLoading(loading);
      if (error) notify('error', 'error');
    }
  };

  React.useEffect(() => {
    const getHourlyIpexResponse = async () => {
      setLoading(true);
      const res = await refetch({
        url: `/companies/${record.id}/plant-data?chartStart=${formatDate(
          startDate,
          'yyyy-MM-DD'
        )}&chartEnd=${formatDate(endDate, 'yyyy-MM-DD')}`,
        method: 'get',
      });
      if (res) {
        const { result, error, loading } = res;
        if (result && result.data) {
          setPlantGraphData(result.data);
          const plantList = result.data.map((value: any) => value.plantName);

          const initialSelectedPlants =
            plantList && plantList.map((value: any) => value);
          setSelectedPlants(initialSelectedPlants.sort());
        }
        if (error) notify('error', 'error');
        setLoading(loading);
      }
    };
    getHourlyIpexResponse();
  }, [notify, refetch, startDate, endDate, record]);

  if (loading && !selectedPlants) return <Loading />;
  return (
    <Box>
      <Title title={translate(`${tFP}.name`)} />
      <CardContent></CardContent>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Card>
          <Paper elevation={0}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              pb={5}
            >
              <ResponsiveContainer
                width="98%"
                height={height > 1000 ? height / 2.5 : 350}
              >
                <LineChart
                  width={1000}
                  height={700}
                  data={data}
                  margin={{
                    top: 20,
                    right: 10,
                    left: 0,
                    bottom: 80,
                  }}
                >
                  <Legend
                    verticalAlign="top"
                    height={36}
                    onClick={selectLine}
                    wrapperStyle={{ position: 'relative' }}
                  />
                  <CartesianGrid
                    strokeDasharray="3 3"
                    stroke={toggleDark ? '#4d4d4d' : '#c6c6c6'}
                  />
                  <XAxis
                    dataKey="dt"
                    angle={-70}
                    textAnchor="end"
                    minTickGap={-75}
                    tick={
                      toggleDark
                        ? {
                            stroke: '#fff',
                            strokeWidth: 0.5,
                            fontSize: 13,
                            fontWeight: 'lighter',
                          }
                        : {
                            stroke: '#666',
                            strokeWidth: 0.5,
                            fontWeight: 'lighter',
                            fontSize: 13,
                          }
                    }
                    interval="preserveStartEnd"
                  />
                  <YAxis
                    tick={
                      toggleDark
                        ? {
                            stroke: '#fff',
                            strokeWidth: 0.5,
                            fontSize: 13,
                            fontWeight: 'lighter',
                          }
                        : {
                            stroke: '#666',
                            strokeWidth: 0.5,
                            fontWeight: 'lighter',
                            fontSize: 13,
                          }
                    }
                    height={700}
                    label={
                      toggleDark
                        ? {
                            stroke: 'rgba(255, 255, 255, 0.9)',
                            fill: '#fff',
                            strokeWidth: 0.5,
                            fontSize: 15,
                            fontWeight: 'lighter',
                            value: 'MWh',
                            angle: -90,
                            position: 'insideLeft',
                          }
                        : {
                            stroke: '#666',
                            strokeWidth: 0.5,
                            fontWeight: 'lighter',
                            fontSize: 15,
                            value: 'MWh',
                            angle: -90,
                            position: 'insideLeft',
                          }
                    }
                  />
                  <Tooltip
                    formatter={(val: number) => val.toFixed(3)}
                    contentStyle={
                      toggleDark
                        ? {
                            color: 'rgba(255, 255, 255, 0.7)',
                            backgroundColor: '#1E272E',
                            borderColor: '#1E272E',
                          }
                        : {
                            backgroundColor: '#F3F3F4',
                            borderColor: '#F3F3F4',
                          }
                    }
                  />
                  {keyArray.map((value, index: number) => {
                    return (
                      <Line
                        key={index}
                        dataKey={value}
                        stroke={stroke[index]}
                        hide={barProps[value] === true}
                        type="monotone"
                        dot={false}
                        name={translate(keyTranslation[value])}
                        strokeWidth={2}
                      />
                    );
                  })}
                  <Fragment />;
                </LineChart>
              </ResponsiveContainer>
              <Box display="flex" flexDirection="row" mt={10} mb={5}>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  spacing={3}
                >
                  <Grid item xs={6}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box pr={2}>
                        <DatePicker
                          value={startDate}
                          inputVariant="standard"
                          label={translate(`${tFP}.initial_date`)}
                          format="yyyy-MM-DD"
                          minDate={minDate}
                          maxDate={endDate}
                          onChange={handleHourlyIpexStartDateChange}
                        />
                      </Box>
                      <DatePicker
                        value={endDate}
                        inputVariant="standard"
                        label={translate(`${tFP}.final_date`)}
                        format="yyyy-MM-DD"
                        minDate={startDate}
                        onChange={handleHourlyIpexEndDateChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="center"
                      ml={10}
                    >
                      <Box pr={2}>
                        <MultipleSelectCheckmarks
                          list={plantList?.sort()}
                          onChange={setSelectedPlants}
                          value={selectedPlants?.sort()}
                          labelName={translate(`${tFP}.sel_plant`)}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography>{translate(`${tFP}.download_data`)}</Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={1}
                >
                  <Box mr={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<GetAppIcon />}
                      onClick={handleFileDownload('CSV')}
                      disabled={loading}
                    >
                      {translate(`${tFP}.download_csv`)}
                    </Button>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<GetAppIcon />}
                    onClick={handleFileDownload('XLS')}
                    disabled={loading}
                  >
                    {translate(`${tFP}.download_xls`)}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Card>
      </MuiPickersUtilsProvider>
    </Box>
  );
};
