import { Box, Button, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import {
  useCreate,
  useCreateSuggestionContext,
  useRefresh,
  useTranslate,
} from 'react-admin';
import Modal from '../../../lib/mui_lib/modal';

export const CreateScWebOperator = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [name, setName] = useState(filter || '');
  const [modalOpen, setModalOpen] = React.useState(true);
  const [eicCode, setEicCode] = useState('');
  const [create] = useCreate();
  const refresh = useRefresh();
  const translate = useTranslate();
  const tFP = 'resources.uvam';

  const handleSubmit = (event: any) => {
    event.preventDefault();
    create(
      'sc-web-operator',
      {
        name,
        eic: eicCode,
      },
      {
        onSuccess: (data) => {
          setName('');
          setEicCode('');
          console.log(data);
          onCreate(data.data);
          refresh();
        },
        onFailure: (data) => {
          console.log(data.status);
        },
      }
    );
  };

  return (
    <Modal
      open={modalOpen}
      onClose={onCancel}
      onCloseIcon={onCancel}
      name={translate(`${tFP}.createNewScwebOperator`)}
      setOpen={setModalOpen}
      withOutButton={true}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gridGap={5}
      >
        <TextField
          label={translate(`${tFP}.fields.scWebOperatorName`)}
          value={name}
          onChange={(event) => setName(event.target.value)}
          autoFocus
        />
        <TextField
          label={translate(`${tFP}.fields.scWebOperatorEic`)}
          value={eicCode}
          onChange={(event) => setEicCode(event.target.value)}
        />
        <Box display="flex" pt={2}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            size="small"
          >
            {translate(`ra.action.save`)}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
