import { memo } from 'react';
import { Typography, Chip, ChipProps } from '@material-ui/core';
import get from 'lodash/get';
import { sanitizeFieldRestProps, useRecordContext } from 'react-admin';

interface ChipFieldCustomRouteProps extends ChipProps {
  source: string;
  onClick: (id: any) => void;
  emptyText?: string;
}
export const ChipFieldCustomRoute = memo<ChipFieldCustomRouteProps>(
  (props: ChipFieldCustomRouteProps) => {
    const {
      className,
      classes: classesOverride,
      source,
      emptyText,
      onClick,
      ...rest
    } = props;

    const record = useRecordContext(props);
    const value = get(record, source);
    const handleOnClick = () => {
      onClick(record);
    };
    if (value == null && emptyText) {
      return (
        <Typography
          component="span"
          variant="body2"
          className={className}
          {...sanitizeFieldRestProps(rest)}
        >
          {emptyText}
        </Typography>
      );
    }

    return (
      <Chip
        label={value}
        {...sanitizeFieldRestProps(rest)}
        onClick={handleOnClick}
      />
    );
  }
);
