import { makeStyles } from "@material-ui/core";
import { FunctionField, TextField, useLocale, useTranslate } from "react-admin";
import { ExtendedDatagrid } from "../../lib/react-admin_lib/extended-datagrid";

const useStyles = makeStyles({
  chipfieldColor: {
    color: "#ffffff",
  },
  fieldHeaderAlign: {
    textAlign: "right",
  },
  fieldHeaderAlignLeft: {
    textAlign: "left",
  },
});

interface CompanyPlantListProps {
  companyId: number;
}

export const CompanyPlantList = ({ companyId }: CompanyPlantListProps) => {
  const translate = useTranslate();
  const tFP = "resources.plants";
  const classes = useStyles();
  const locale = useLocale();

  return (
    <ExtendedDatagrid
      resource="plants"
      resourceUrl={`companies/${companyId}/plants`}
      rowClick={(id: any) => `/plants/${id}/show`}
    >
      <TextField source="upCode" />
      <TextField source="currentValues.pod" label={translate(`${tFP}.fields.currentValues.pod`)} />
      <TextField source="name" />
      <TextField source="type" />
      <TextField source="address.address" label={translate(`${tFP}.fields.address.address`)} />
      <TextField source="address.city.name" label={translate(`${tFP}.fields.address.city.name`)} />
      <TextField source="address.city.province.name" label={translate(`${tFP}.fields.address.city.province.name`)} />
      <FunctionField
        textAlign="right"
        label={translate(`${tFP}.fields.currentValues.power`)}
        headerClassName={classes.fieldHeaderAlign}
        render={(record?: Record<string, any>): string =>
          record
            ? `${new Intl.NumberFormat(locale).format(
                record.currentValues.power
              )}`
            : ""
        }
      />
      <TextField source="currentValues.voltageType" label={translate(`${tFP}.fields.currentValues.voltageType`)} />
    </ExtendedDatagrid>
  );
};
