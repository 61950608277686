import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

interface SelectProps {
  list: { id: number | string; name: string }[];
  onChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: any;
    }>
  ) => void;
  value: string | number | false;
  labelName?: string;
  defaultValue?: string;
  disabled?: boolean;
}

export function SelectField(props: SelectProps) {
  const classes = useStyles();
  const { list, onChange, value, labelName, disabled } = props;

  return (
    <div>
      <FormControl className={classes.formControl}>
        {labelName && (
          <InputLabel id="demo-simple-select-label">{labelName}</InputLabel>
        )}

        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          onChange={onChange}
          disabled={disabled}
          // defaultValue={defaultValue}
        >
          {list.map((value: any, index: number) => {
            return (
              <MenuItem key={index} value={value.id}>
                {value.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
