import {
  DateInput,
  Edit,
  NumberInput,
  ReferenceInput,
  ResourceComponentProps,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  minValue,
  required,
  useTranslate
} from 'react-admin';

const UserEditToolbar = (props: ToolbarProps) => {
  const handleTransform = (data: any) => {
    return {...data};
  };
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} transform={handleTransform} />
    </Toolbar>
  );
};

export const GridConnectionPointAbilitationNoProductEdit = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.grid-connection-point-abilitation-no-product';

  return (
    <Edit {...props} title={translate(`${tFP}.name`, 1)} mutationMode="pessimistic">
      <SimpleForm
        redirect="show"
        toolbar={<UserEditToolbar />}
        variant="standard"
      >
        <ReferenceInput
          validate={required()}
          label={translate(`${tFP}.fields.gcpName`)}
          source="gridConnectionPoint"
          allowEmpty={false}
          filterToQuery={(q) => ({ filter: { name: q } })}
          reference="grid-connection-points"
          disabled
          className="editCreateForm"
        >
          <SelectInput optionText="resourceName"/>
        </ReferenceInput>
        <DateInput
          source="startDate"
          label={translate(`${tFP}.fields.startDate`)}
          validate={[required()]}
          className="editCreateForm"
        />
        <DateInput
          source="endDate"
          label={translate(`${tFP}.fields.endDate`)}
          validate={[required()]}
          className="editCreateForm"
        />
        <NumberInput
          source="minQuartersBeforeAssign"
          label={translate(`${tFP}.fields.minQuartersBeforeAssign`)}
          validate={[required(), minValue(0)]}
          className="editCreateForm"
        />
      </SimpleForm>
    </Edit>
  );
};
