import { config } from '@config/config';
import {
  Divider,
  IconButton,
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ComponentPropType, setSidebarVisibility, useTranslate } from 'ra-core';
import * as React from 'react';
import { Children, cloneElement } from 'react';
import {
  ClassesOverride,
  HideOnScroll,
  LoadingIndicator,
  UserMenu,
} from 'react-admin';
import { useDispatch } from 'react-redux';
import { useLocalStorage } from '../lib/custom-hooks/useLocalStorage';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  logo: {
    height: '30px',
    marginLeft: '100px',
  },
  logoSev: {
    height: '40px',
    marginLeft: '100px',
  },
  spacer: {
    flex: 1,
  },
  spacerleft: {
    flex: 0.3,
  },
});

export const MyAppBar = (props: AppBarProps) => {
  const classes = useStyles();

  return (
    <LocalAppBar {...props}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <img
        src={config.logo}
        className={config.title === 'Sev' ? classes.logoSev : classes.logo}
        alt="logo"
      />
      <span className={classes.spacer} />
    </LocalAppBar>
  );
};

const useStyle = makeStyles(
  (theme) => ({
    toolbar: {
      paddingRight: 24,
    },
    menuButton: {
      marginLeft: '0.2em',
      marginRight: '0.2em',
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  }),
  { name: 'RaAppBar' }
);

/**
 * The AppBar component renders a custom MuiAppBar.
 *
 * @param {Object} props
 * @param {ReactNode} props.children React node/s to be render as children of the AppBar
 * @param {Object} props.classes CSS class names
 * @param {string} props.className CSS class applied to the MuiAppBar component
 * @param {string} props.color The color of the AppBar
 * @param {Component} props.logout The logout button component that will be pass to the UserMenu component
 * @param {boolean} props.open State of the <Admin/> Sidebar
 * @param {Element | boolean} props.userMenu A custom user menu component for the AppBar. <UserMenu/> component by default. Pass false to disable.
 *
 * @example
 *
 * const MyAppBar = props => {
 *   const classes = useStyles();
 *   return (
 *       <AppBar {...props}>
 *           <Typography
 *               variant="h6"
 *               color="inherit"
 *               className={classes.title}
 *               id="react-admin-title"
 *           />
 *       </AppBar>
 *   );
 *};
 *
 * @example Without a user menu
 *
 * const MyAppBar = props => {
 *   const classes = useStyles();
 *   return (
 *       <AppBar {...props} userMenu={false} />
 *   );
 *};
 */
export const LocalAppBar = (props: AppBarProps): JSX.Element => {
  const {
    children,
    classes: classesOverride,
    className,
    color = 'secondary',
    logout,
    open,
    title,
    userMenu,
    container: Container,
    ...rest
  } = props;
  const classes = useStyle(props);
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useLocalStorage('menu', open);
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('xs')
  );
  const translate = useTranslate();
  const handleOnMenuClick = () => {
    setMenuOpen(!menuOpen);
  };
  React.useEffect(() => {
    dispatch(setSidebarVisibility(menuOpen));
  }, [menuOpen, dispatch]);
  return (
    <>
      <MuiAppBar className={className} color={color} {...rest}>
        <Toolbar
          disableGutters
          variant={isXSmall ? 'regular' : 'dense'}
          className={classes.toolbar}
        >
          <Tooltip
            title={translate(
              menuOpen ? 'ra.action.close_menu' : 'ra.actimenuOpen_menu',
              {
                _: 'Open/Close menu',
              }
            )}
            enterDelay={500}
          >
            <IconButton
              color="inherit"
              onClick={handleOnMenuClick}
              className={classNames(classes.menuButton)}
            >
              <MenuIcon
                classes={{
                  root: menuOpen
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed,
                }}
              />
            </IconButton>
          </Tooltip>
          {Children.count(children) === 0 ? (
            <Typography
              variant="h6"
              color="inherit"
              className={classes.title}
              id="react-admin-title"
            />
          ) : (
            children
          )}
          <LoadingIndicator />
          {typeof userMenu === 'boolean' ? (
            userMenu === true ? (
              <UserMenu logout={logout} />
            ) : null
          ) : (
            userMenu && cloneElement(userMenu, { logout })
          )}
        </Toolbar>
        {config.title === 'Sev' && <Divider />}
      </MuiAppBar>
    </>
  );
};

LocalAppBar.propTypes = {
  children: PropTypes.node,
  // @ts-ignore
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'default',
    'inherit',
    'primary',
    'secondary',
    'transparent',
  ]),
  container: ComponentPropType,
  logout: PropTypes.element,
  open: PropTypes.bool,
  userMenu: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
};

LocalAppBar.defaultProps = {
  userMenu: <UserMenu />,
  container: HideOnScroll,
};

export interface AppBarProps extends Omit<MuiAppBarProps, 'title' | 'classes'> {
  classes?: ClassesOverride<typeof useStyles>;
  container?: React.ElementType<any>;
  logout?: React.ReactNode;
  open?: boolean;
  title?: string | JSX.Element;
  userMenu?: JSX.Element | boolean;
}

export default MyAppBar;
