import { Box, Divider, FormControlLabel, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import React from 'react';
import { Title, resolveBrowserLocale, useSetLocale, useTranslate } from 'react-admin';
import { useDarkTheme } from '../lib/custom-hooks/store-dark-theme';
import { useLocalStorage } from '../lib/custom-hooks/useLocalStorage';
import { SelectField } from '../lib/mui_lib/select';

export const Settings = () => {
  const { toggleDark, handleModeChange } = useDarkTheme();
  const translate = useTranslate();
  const tFP = 'resources.settings';
  const [language, setLanguage] = useLocalStorage(
    'locale',
    resolveBrowserLocale()
  );
  const setLocale = useSetLocale();
  const staticSelectLanguage = [
    { id: 'it', name: 'Italiano' },
    { id: 'en', name: 'English' },
  ];
  const handleSelectLanguageChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setLanguage(e.target.value as string);
    setLocale(e.target.value as string);
  };
  return (
    <Box>
      <Title title={translate(`${tFP}.name`)} />
      <CardContent>{translate(`${tFP}.name`)}</CardContent>
      <Card>
        <Paper>
          <Box ml={1} mr={1}>
            <Box p={1}>
              <Typography variant="subtitle1" color="textSecondary">
                {translate(`${tFP}.theme`)}
              </Typography>
              <Box p={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={toggleDark}
                      onChange={handleModeChange}
                      color="default"
                      name="checkedA"
                    />
                  }
                  label="Dark Mode"
                />
              </Box>
            </Box>
            <Divider />
            <Box p={1}>
              <Typography variant="subtitle1" color="textSecondary">
                {translate(`${tFP}.language`)}
              </Typography>

              <Box p={2}>
                <SelectField
                  list={staticSelectLanguage}
                  onChange={handleSelectLanguageChange}
                  value={language}
                  labelName={translate(`${tFP}.sel_language`)}
                />
              </Box>
            </Box>
          </Box>
        </Paper>
      </Card>
    </Box>
  );
};
