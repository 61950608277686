import { Box, Grid } from '@material-ui/core';
import {
  BooleanInput,
  DateInput,
  Edit,
  NumberInput,
  ResourceComponentProps,
  SaveButton,
  SelectArrayInput,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  number,
  required,
  useTranslate
} from 'react-admin';
import TimeSelectInput from 'src/lib/custom-useful-components/TimeSelectInput';
import { TextFieldsCard } from '../../lib/mui_lib/text-fields-card';
import { TextInput } from '../../lib/react-admin_lib/labeled-field';

const UserEditToolbar = (props: ToolbarProps) => {
  const handleTransform = (data: any) => {
    const startTime = new Date(`2023-10-10T${data.startTimeHour}:${data.startTimeMinute}:00`).getTime() + 7200000;
    const endTime = new Date(`2023-10-10T${data.endTimeHour}:${data.endTimeMinute}:00`).getTime() + 7200000;
    const weekDaysIds = data.selectedDays.map((day: number) => day);
    return {...data,startTime,endTime,weekDaysIds};
  };
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} transform={handleTransform} />
    </Toolbar>
  );
};

export const ProductEdit = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.products';

  return (
    <Edit {...props} title={translate(`${tFP}.name`, 1)} mutationMode="pessimistic">
      <SimpleForm
        redirect="show"
        toolbar={<UserEditToolbar />}
        variant="standard"
      >
        <Box display="flex" flexDirection="row" minWidth="100%">
          <Grid container spacing={10}>
            <TextFieldsCard title={translate(`${tFP}.name`, 0)}>
              <DateInput
                source="startDate"
                label={translate(`${tFP}.fields.startDate`)}
                validate={[required()]}
              />
              <TextInput
                source="type"
                label={translate(`${tFP}.fields.type`)}
                validate={required()}
              />
              <TimeSelectInput
                label="startTime"
                sourceHour="startTimeHour"
                sourceMinute="startTimeMinute"
              />
              <TimeSelectInput
                label="endTime"
                sourceHour="endTimeHour"
                sourceMinute="endTimeMinute"
              />
              <BooleanInput
                source="holiday"
                label={translate(`${tFP}.fields.holiday`)}
                defaultValue={false}
              />
              <NumberInput
                source="strikePrice"
                label={translate(`${tFP}.fields.strikePrice`)}
                validate={[required(), number()]}
              />
              <DateInput
                source="endDate"
                label={translate(`${tFP}.fields.endDate`)}
                validate={[required()]}
              />
              <TextInput
                source="productCode"
                label={translate(`${tFP}.fields.productCode`)}
                validate={required()}
              />
              <SelectArrayInput
                variant="standard"
                label={translate(`${tFP}.fields.activationDays`)}
                source="selectedDays"
                style={{ width: '150px' }}
                choices={[
                  {weekDay: 1, name: translate(`resources.weekDays.monday`) },
                  {weekDay: 2, name: translate(`resources.weekDays.tuesday`) },
                  {weekDay: 3, name: translate(`resources.weekDays.wednesday`) },
                  {weekDay: 4, name: translate(`resources.weekDays.thursday`) },
                  {weekDay: 5, name: translate(`resources.weekDays.friday`) },
                  {weekDay: 6, name: translate(`resources.weekDays.saturday`) },
                  {weekDay: 7, name: translate(`resources.weekDays.sunday`) },
                ]}
                validate={required()}
                optionValue='weekDay'
              />
            </TextFieldsCard>
          </Grid>
        </Box>
      </SimpleForm>
    </Edit>
  );
};
