import { config } from '@config/config';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import RemoveIcon from '@mui/icons-material/Remove';
import React from 'react';
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  Record,
  ReferenceField,
  TextField,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { GenericFilterComponent } from '../../../lib/custom-useful-components/GenericFilterComponent';
import { Empty } from '../../../lib/mui_lib/empty';
import Modal from '../../../lib/mui_lib/modal';
import { SelectField } from '../../../lib/mui_lib/select';
import { GridConnectionPointStatus } from '../../../models/grid-connection-point';
import { httpClient } from '../../../providers/dataProvider';
import { EditableStatusView } from '../../../resources/grid-connection-points/status-management-components/EditableStatusView';
enum CPaEditableStatus {
  Open = 'Open',
  Rejected = 'Rejected',
}

interface IPopUpAdd {
  label: string;
  edit?: boolean;
  startIcon?: React.ReactNode;
}
const AddGcpPopUp = (props: IPopUpAdd) => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const tFP = 'resources.uvam.tabs.cp';
  const [modal, setModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [list, setList] = React.useState<
    {
      id: number | string;
      name: string;
    }[]
  >();
  const [selected, setSelected] = React.useState(
    list && list[0] ? list[0].name : ''
  );
  const handleSelectGcpChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setSelected(e.target.value as string);
  };

  const handleAddGcp = () => {
    if (selected) {
      setLoading(true);
      httpClient(
        `${config.dataConfig.api}/uvam/${record.id}/add-grid-connection-point/${selected}`,
        {
          method: 'POST',
        }
      )
        .then((data) => {
          refresh();
          setModal(false);
          notify(`${tFP}.gcp_added`, 'success');
          setLoading(false);
        })
        .catch((error) => {
          notify(`error: ${error.message}`, 'error');
          setLoading(false);
        });
    } else {
      notify(`${tFP}.select_gcp`, 'error');
      setModal(false);
    }
  };

  const handleOnOpenModal = () => {
    dataProvider
      .getList('grid-connection-points', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
        filter: {
          currentStatus: [
            'Open',
            'PreValidated',
            'Registered',
            'Rejected',
            'Released',
            'UnderReview',
            'Validated',
          ] as GridConnectionPointStatus[],
          connected: false,
          bsp: {
            id: record.bsp as number,
          },
        },
      })
      .then((res) => {
        const list = res.data
          .sort(
            (a, b) =>
              a.currentStatus.localeCompare(b.currentStatus) ||
              a.meteringPointId.localeCompare(b.meteringPointId)
          )
          .map((item) => {
            return {
              id: item.id,
              name: `${item.meteringPointId} ${item.currentStatus} - ${item.resourceName}`,
            };
          });
        setList(list);
      });
  };

  return (
    <Modal
      startIcon={props.startIcon ? props.startIcon : <RemoveIcon />}
      name={props.label}
      open={modal}
      setOpen={setModal}
      variant="text"
      buttonSize="small"
      onOpen={handleOnOpenModal}
    >
      <>
        <Box p={2}>
          <SelectField
            list={list ? list : []}
            onChange={handleSelectGcpChange}
            value={selected}
            labelName={translate(`${tFP}.add_gcp`)}
          />
        </Box>
        <Button
          startIcon={
            loading ? <CircularProgress size={15} color="inherit" /> : <Add />
          }
          variant="contained"
          color="primary"
          size="small"
          onClick={handleAddGcp}
          disabled={loading}
        >
          {translate(`${tFP}.add_gcp`)}
        </Button>
      </>
    </Modal>
  );
};

interface IPopUpRemove {
  uvamId: number | string;
  label: string;
  edit?: boolean;
  popUpLabel?: string;
  startIcon?: React.ReactNode;
}
const RemoveGcpPopUp = (props: IPopUpRemove) => {
  const record = useRecordContext();
  const translate = useTranslate();
  const [modal, setModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const tFP = 'resources.uvam.tabs.cp';

  const handleRemoveGcp = () => {
    setLoading(true);
    httpClient(
      `${config.dataConfig.api}/uvam/${props.uvamId}/remove-grid-connection-point/${record.id}`,
      {
        method: 'POST',
      }
    )
      .then((data) => {
        refresh();
        setModal(false);
        notify(`${tFP}.gcp_added`, 'success');
        setLoading(false);
      })
      .catch((error) => {
        notify(`error: ${error.message}`, 'error');
        setModal(false);
        setLoading(false);
      });
  };
  return (
    <Modal
      withIconButton={<RemoveIcon />}
      name={props.popUpLabel ? props.popUpLabel : ''}
      open={modal}
      setOpen={setModal}
      variant="text"
      buttonSize="small"
      size="small"
    >
      <>
        <Box pb={2}>
          <Typography>{translate(`${tFP}.remove_gcp_confirm`)}</Typography>
        </Box>
        <Button
          startIcon={
            loading ? (
              <CircularProgress size={15} color="inherit" />
            ) : (
              <RemoveIcon />
            )
          }
          variant="contained"
          color="primary"
          size="small"
          disabled={loading}
          onClick={handleRemoveGcp}
        >
          {translate(`${tFP}.remove_gcp`)}
        </Button>
      </>
    </Modal>
  );
};
export const ConnectionPoints = () => {
  const translate = useTranslate();
  const tFP = 'resources.uvam';
  const record = useRecordContext();

  return (
    <Box mb={2}>
      <Box display="flex" justifyContent="right">
        <EditableStatusView editableStatus={CPaEditableStatus}>
          <AddGcpPopUp
            label={translate(`${tFP}.tabs.cp.add_gcp`)}
            startIcon={<Add fontSize="small" />}
            edit
          />
        </EditableStatusView>
      </Box>

      <GenericFilterComponent
        filter={() => record.gridConnectionPoints?.length > 0}
        empty={<Empty resource={translate(`${tFP}.tabs.cp.name`)} />}
      >
        <ArrayField source="gridConnectionPoints" sortBy="gridConnectionPoint">
          <Datagrid>
            <ReferenceField
              label={translate(`${tFP}.tabs.cp.fields.meteringPointId`)}
              source="gridConnectionPoint"
              reference="grid-connection-points"
              link="show"
            >
              <Box>
                <ChipField
                  source="meteringPointId"
                  size="small"
                  color="primary"
                />
              </Box>
            </ReferenceField>
            <ReferenceField
              label={translate(`${tFP}.tabs.cp.fields.exergyId`)}
              source="gridConnectionPoint"
              reference="grid-connection-points"
              link="show"
            >
              <TextField source="exergyId" />
            </ReferenceField>
            <ReferenceField
              label={translate(`${tFP}.tabs.cp.fields.direction`)}
              source="gridConnectionPoint"
              reference="grid-connection-points"
              link={false}
            >
              <FunctionField
                label={translate(`${tFP}.tabs.cp.fields.direction`)}
                render={(r: Record | undefined) =>
                  translate(`${tFP}.direction.${r!.direction}`)
                }
              />
            </ReferenceField>
            <ReferenceField
              label={translate(`${tFP}.tabs.cp.fields.currentStatus`)}
              source="gridConnectionPoint"
              reference="grid-connection-points"
              link={false}
            >
              <FunctionField
                render={(r: Record | undefined) =>
                  translate(`${tFP}.status.${r!.currentStatus}`)
                }
              />
            </ReferenceField>
            <ReferenceField
              label={translate(`${tFP}.tabs.cp.fields.maximumFlexDown`)}
              source="gridConnectionPoint"
              reference="grid-connection-points"
              link={false}
            >
              <TextField source="maximumFlexDown" />
            </ReferenceField>
            <ReferenceField
              label={translate(`${tFP}.tabs.cp.fields.maximumFlexUp`)}
              source="gridConnectionPoint"
              reference="grid-connection-points"
              link={false}
            >
              <TextField source="maximumFlexUp" />
            </ReferenceField>

            <DateField
              source="startDate"
              showTime
              label={translate(`${tFP}.tabs.cp.fields.startDate`)}
            />
            <DateField
              source="endDate"
              label={translate(`${tFP}.tabs.cp.fields.endDate`)}
            />
            {Object.values(CPaEditableStatus).includes(record.currentStatus) &&
              record.currentGridConnectionPoints?.length > 0 && (
                <FunctionField
                  label={translate(`${tFP}.tabs.cp.remove_gcp`)}
                  render={(r: Record | undefined) =>
                    !r!.endDate && (
                      <RemoveGcpPopUp
                        label={translate(`${tFP}.tabs.cp.remove_gcp`)}
                        popUpLabel=""
                        uvamId={record.id}
                      />
                    )
                  }
                />
              )}
          </Datagrid>
        </ArrayField>
      </GenericFilterComponent>
    </Box>
  );
};
