import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Modal as ModalMui,
  Paper,
  Typography,
  ModalProps as ModalMuiProps,
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSpring, animated } from 'react-spring';
import { Loading } from 'react-admin';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    small: {
      backgroundColor: theme.palette.background.paper,
      width: '30vw',
      maxHeight: '70vh',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    medium: {
      backgroundColor: theme.palette.background.paper,
      width: '45vw',
      maxHeight: '70vh',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    large: {
      backgroundColor: theme.palette.background.paper,
      width: '85vw',
      maxHeight: '85vh',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    content: {
      padding: 12,

      overflow: 'scroll',
    },
  })
);

interface FadeProps {
  children?: React.ReactElement;
  in: boolean;
  onEnter?: () => {};
  onExited?: () => {};
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
  props,
  ref
) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

interface ModalProps extends ModalMuiProps {
  name: string;
  startIcon?: React.ReactNode;
  withOutButton?: boolean;
  withIconButton?: React.ReactNode;
  loading?: boolean;
  size?: 'small' | 'medium' | 'large';
  buttonSize?: 'small' | 'medium' | 'large';
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCloseIcon?: () => void;
  variant?: 'text' | 'outlined' | 'contained';
  onOpen?: () => void;
}

export default function Modal(props: ModalProps) {
  const {
    children,
    name,
    startIcon,
    withOutButton,
    withIconButton,
    loading,
    size,
    buttonSize,
    open,
    setOpen,
    onCloseIcon,
    variant,
    onOpen,
    ...more
  } = props;
  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
    onOpen && onOpen();
  };

  const handleClose = () => {
    setOpen(false);
    onCloseIcon && onCloseIcon();
  };

  return (
    <>
      {!withOutButton && !withIconButton && (
        <Button
          startIcon={startIcon}
          variant={variant ? variant : 'contained'}
          color="primary"
          onClick={handleOpen}
          size={buttonSize && buttonSize}
        >
          {name}
        </Button>
      )}
      {withIconButton && (
        <IconButton size="small" color="primary" onClick={handleOpen}>
          {withIconButton}
        </IconButton>
      )}

      <ModalMui
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
        {...more}
      >
        {loading ? (
          <Paper className={size ? classes[size] : classes['small']}>
            <Box
              display="flex"
              flexDirection="row-reverse"
              justifyContent="space-between"
              alignItems="center"
            >
              <IconButton aria-label="delete" onClick={handleClose}>
                <CloseIcon color="inherit" />
              </IconButton>
              <Typography variant="h6" color="textPrimary">
                {name}
              </Typography>
            </Box>
            <Box
              pb={3}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Loading />
            </Box>
          </Paper>
        ) : (
          <Fade in={open}>
            <Paper className={size ? classes[size] : classes['small']}>
              <Box
                display="flex"
                flexDirection="row-reverse"
                justifyContent="space-between"
                alignItems="center"
              >
                <IconButton aria-label="delete" onClick={handleClose}>
                  <CloseIcon color="inherit" />
                </IconButton>
                <Typography variant="h6" color="textPrimary">
                  {name}
                </Typography>
              </Box>
              <Box
                pb={3}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                {children}
              </Box>
            </Paper>
          </Fade>
        )}
      </ModalMui>
    </>
  );
}
