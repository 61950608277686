import {
  Box,
  CircularProgress,
  Grid,
  Icon,
  Typography,
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SendIcon from '@material-ui/icons/Send';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import React from 'react';
import {
  EditButton,
  FunctionField,
  Labeled,
  Record,
  ReferenceField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { useAxios } from '../../lib/custom-hooks/useAxios';
import { GenericFilterComponent } from '../../lib/custom-useful-components/GenericFilterComponent';
import { ButtonField } from '../../lib/react-admin_lib/button-field';
import { Attachments } from './gcp-resource-tabs/Attachments';
import { Up } from './gcp-resource-tabs/Up';
import { Uvam } from './gcp-resource-tabs/Uvam';
import {
  EditableStatus,
  EditableStatusView,
} from './status-management-components/EditableStatusView';

import { TextFieldsCard } from '../../lib/mui_lib/text-fields-card';
import { LabeledField } from '../../lib/react-admin_lib/labeled-field';
import { locationOptions } from './gcpConstants';
import { BaselineManagement } from './gcp-resource-tabs/BaselineManagement';

export const findLocation = (value: string): any => {
  return locationOptions.find((location) => Object.keys(location)[0] === value);
};
export const locationId = (locationId: any): string => {
  return locationId.length > 2 ? locationId : `0${locationId}`;
};

const ActionButtons = () => {
  const translate = useTranslate();
  const { refetch } = useAxios();
  const notify = useNotify();
  const refresh = useRefresh();
  const tFP = 'resources.grid-connection-points';
  const [loading, setLoading] = React.useState(false);
  const handleOnRequestClick = async (record: Record) => {
    setLoading(true);
    const res = await refetch({
      url: `/grid-connection-points/${record!.id}/status/Open`,
      method: 'POST',
    });
    if (res) {
      const { result, error } = res;
      if (result?.data) {
        refresh();
        notify(`${tFP}.status_updated`, 'success');
        setLoading(false);
      }
      if (error) {
        setLoading(false);
        notify(`error: ${(error as any).message}`, 'error');
      }
    }
  };
  const handleOnSendClick = async (
    record: Record | undefined,
    currentStatus: EditableStatus
  ) => {
    const gotoStatus =
      currentStatus === EditableStatus.None ? 'Registered' : 'UnderReview';
    setLoading(true);
    const res = await refetch({
      url: `/grid-connection-points/${record!.id}/status/${gotoStatus}`,
      method: 'POST',
    });
    if (res) {
      const { result, error } = res;
      if (result?.data) {
        refresh();
        notify(`${tFP}.status_updated`, 'success');
        setLoading(false);
      }
      if (error) {
        setLoading(false);
        notify(`error: ${(error as any).message}`, 'error');
      }
    }
  };
  return (
    <Box display="flex" justifyContent="right">
      <FunctionField
        source="currentStatus"
        render={(r: Record | undefined) => {
          return (
            <>
              <EditableStatusView>
                <Box display="flex" flex-direction="row" pt={3} pb={2}>
                  <Box pl={1} pr={1}>
                    <EditButton record={r} basePath="/grid-connection-points" />
                  </Box>

                  <Box
                    pr={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <React.Suspense fallback={<Icon>loading</Icon>}>
                      <ButtonField
                        disabled={loading}
                        label={
                          r!.currentStatus === EditableStatus.None
                            ? translate(`${tFP}.register`)
                            : translate(`${tFP}.review`)
                        }
                        onClick={() => handleOnSendClick(r!, r!.currentStatus)}
                      >
                        {r!.currentStatus === EditableStatus.None ? (
                          loading ? (
                            <CircularProgress size={15} color="inherit" />
                          ) : (
                            <AppRegistrationIcon />
                          )
                        ) : loading ? (
                          <CircularProgress size={15} color="inherit" />
                        ) : (
                          <SendIcon />
                        )}
                      </ButtonField>
                    </React.Suspense>
                  </Box>
                </Box>
              </EditableStatusView>
              <GenericFilterComponent
                filter={() => r!.currentStatus === 'Validated'}
              >
                <Box display="flex" flex-direction="row" pt={3} pb={2}>
                  <Box pl={1} pr={1}>
                    <ButtonField
                      disabled={loading}
                      label={translate(`${tFP}.request_open_status`)}
                      onClick={handleOnRequestClick}
                      startIcon={<AssignmentIcon />}
                    />
                  </Box>
                </Box>
              </GenericFilterComponent>
            </>
          );
        }}
      />
    </Box>
  );
};

const PostTitle = ({ record }: Record) => {
  const translate = useTranslate();
  const tFP = 'resources.grid-connection-points';
  return (
    <span>
      {translate(`${tFP}.name`, 1)}
      {record?.meteringPointId ? ` - ${record.meteringPointId}` : ''}
    </span>
  );
};
export const GridConnectionPointShow = (props: any) => {
  const translate = useTranslate();
  const tFP = 'resources.grid-connection-points';

  return (
    <Show title={<PostTitle id={''} />} {...props} actions={<ActionButtons />}>
      <>
        <SimpleShowLayout>
          <Grid container spacing={6}>
            <TextFieldsCard title={translate(`${tFP}.fieldGroups.status`)}>
              <Box display="flex" width="190px">
                <Labeled label={translate(`${tFP}.fields.currentStatus`)}>
                  <FunctionField
                    source="currentStatus"
                    render={(r: Record | undefined) =>
                      translate(`${tFP}.status.${r!.currentStatus}`)
                    }
                    label={translate(`${tFP}.fields.currentStatus`)}
                  />
                </Labeled>
              </Box>
              <FunctionField
                source="lastErrorMessage"
                render={(r: Record | undefined) =>
                  r!.lastErrorMessage ? (
                    <LabeledField
                      source="lastErrorMessage"
                      label={translate(`${tFP}.fields.lastErrorMessage`)}
                      emptyText={translate(`empty`)}
                    />
                  ) : null
                }
              />
            </TextFieldsCard>
            <TextFieldsCard title={translate(`${tFP}.fieldGroups.pod`)}>
              <LabeledField
                source="resourceName"
                label={translate(`${tFP}.fields.resourceName`)}
                emptyText={translate(`empty`)}
              />
              <LabeledField
                source="meteringPointId"
                label={translate(`${tFP}.fields.meteringPointId`)}
                emptyText={translate(`empty`)}
              />

              <Box display="flex" width="190px">
                <Labeled
                  label={translate(`${tFP}.fields.direction`)}
                  style={{ width: '190px' }}
                >
                  <FunctionField
                    source="direction"
                    render={(r: Record | undefined) =>
                      translate(`${tFP}.direction.${r!.direction}`)
                    }
                  />
                </Labeled>
              </Box>
              <Box display="flex" width="190px">
                <Labeled label={translate(`${tFP}.fields.currentLocation`)}>
                  <FunctionField
                    source="currentStatus"
                    render={(r: Record | undefined) => (
                      <Typography style={{ width: '190px' }} variant="body2">
                        {r?.currentLocation &&
                          findLocation(locationId(r?.currentLocation)) &&
                          findLocation(locationId(r?.currentLocation))?.[
                            locationId(r?.currentLocation)
                          ]}
                      </Typography>
                    )}
                    label={translate(`${tFP}.fields.currentStatus`)}
                  />
                </Labeled>
              </Box>
              <LabeledField
                source="gridVoltageLevel"
                emptyText={translate(`empty`)}
                label={translate(`${tFP}.fields.gridVoltageLevel`)}
              />
              <LabeledField
                source="exergyId"
                emptyText={translate(`empty`)}
                label={translate(`${tFP}.fields.exergyId`)}
              />
            </TextFieldsCard>

            <TextFieldsCard title={translate(`${tFP}.fieldGroups.gridVoltage`)}>
              <LabeledField
                source="contractedPower"
                label={translate(`${tFP}.fields.contractedPowerSecondaryPod`)}
                emptyText={translate(`empty`)}
              />
              <LabeledField
                source="maximumFlexDown"
                emptyText={translate(`empty`)}
                label={translate(`${tFP}.fields.maximumFlexDown`)}
              />
              <LabeledField
                source="maximumFlexUp"
                emptyText={translate(`empty`)}
                label={translate(`${tFP}.fields.maximumFlexUp`)}
              />
            </TextFieldsCard>

            <TextFieldsCard
              title={translate(`${tFP}.fieldGroups.secondaryPod`)}
            >
              <LabeledField
                source="accountingPointId"
                label={translate(`${tFP}.fields.accountingPointId`)}
                emptyText={translate(`empty`)}
              />

              <LabeledField
                source="contractedPowerSecondaryPod"
                label={translate(`${tFP}.fields.contractedPowerSecondaryPod`)}
                emptyText={translate(`empty`)}
              />
            </TextFieldsCard>
            <TextFieldsCard
              title={translate(`${tFP}.fieldGroups.associazioneUDD`)}
            >
              <LabeledField
                source="brpContractId"
                label={translate(`${tFP}.fields.brpContractId`)}
                emptyText={translate(`empty`)}
              />
              <LabeledField
                source="brpName"
                label={translate(`${tFP}.fields.brpName`)}
                emptyText={translate(`empty`)}
              />
            </TextFieldsCard>
            <TextFieldsCard title={translate(`${tFP}.fieldGroups.other`)}>
              <LabeledField
                source="measurementsTreatment"
                label={translate(`${tFP}.fields.measurementsTreatment`)}
                emptyText={translate(`empty`)}
              />

              <LabeledField
                label={translate(`${tFP}.fields.distributionGridOperatorId`)}
                source="distributionGridOperatorId"
                emptyText={translate(`empty`)}
              />
              <LabeledField
                source="distributionGridOperatorName"
                label={translate(`${tFP}.fields.distributionGridOperatorName`)}
                emptyText={translate(`empty`)}
              />
              <ReferenceField reference="bsp" source="bsp" link="show">
                <LabeledField source="name" label="Bsp" emptyText="-" />
              </ReferenceField>
            </TextFieldsCard>
          </Grid>
        </SimpleShowLayout>
        <Box pt={4} />
        <TabbedShowLayout>
          <Tab label={translate(`${tFP}.tabs.up.name`)} path="">
            <Up />
          </Tab>

          <Tab
            label={translate(`${tFP}.tabs.attachments.name`)}
            path="attachments"
          >
            <Attachments />
          </Tab>
          <Tab label={translate(`${tFP}.tabs.uvam.name`)} path="uvam">
            <Uvam />
          </Tab>
          <Tab
            label={translate(`${tFP}.tabs.baselineManagement.name`)}
            path="baseline-management"
          >
            <BaselineManagement />
          </Tab>
        </TabbedShowLayout>
      </>
    </Show>
  );
};
