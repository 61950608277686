import { DataProviderQuery, UseQueryOptions, useQuery as raUseQuery, useQueryWithStore as raUseQueryWithStore, UseQueryValue, Identifier, QueryOptions, Record as RaRecord } from "react-admin";

export type OperationTypes = "getList" | "getOne" | "getMany" | "getManyReference"

interface UseQueryResult<T> extends UseQueryValue {
    data: T
}

interface Payload extends Record<OperationTypes, any> {
    getOne: { id: Identifier; }
    getMany: { ids: Identifier[]; }
    getList: {
        pagination: {
            page: number,
            perPage: number
        },
        sort: {
            field: string,
            order: string
        },
        filter: {}
    }
    getManyReference: {
        target: string, id: Identifier,
        pagination: {
            page: number,
            perPage: number
        },
        sort: {
            field: string,
            order: string
        },
        filter: {}
    }
}

interface QueryParams1<T1 extends OperationTypes> extends Omit<DataProviderQuery, 'type'> {
    payload: Payload[T1]
}


export function useQuery<T extends RaRecord = RaRecord, T1 extends OperationTypes = "getList" | "getMany" | "getManyReference">(type: T1, query: QueryParams1<T1>, options?: UseQueryOptions): UseQueryResult<T[] | undefined>
export function useQuery<T extends RaRecord = RaRecord, T1 extends OperationTypes = "getOne">(type: T1, query: QueryParams1<T1>, options?: UseQueryOptions): UseQueryResult<T | undefined>;
export function useQuery<T extends RaRecord = RaRecord>(type: any, query: QueryParams1<any>, options?: UseQueryOptions): UseQueryResult<T | undefined> {
    const { data, total, loading, error } = raUseQuery({ ...query, type }, options);
    return { data, total, loading, error } as UseQueryResult<T>
}


export function useQueryWithStore<T extends RaRecord = RaRecord, T1 extends OperationTypes = "getOne">(type: T1, query: QueryParams1<T1>, options?: QueryOptions): UseQueryResult<T | undefined>;
export function useQueryWithStore<T extends RaRecord = RaRecord, T1 extends OperationTypes = "getList" | "getMany" | "getManyReference">(type: T1, query: QueryParams1<T1>, options?: QueryOptions): UseQueryResult<T[] | undefined>
export function useQueryWithStore<T extends RaRecord = RaRecord>(type: any, query: QueryParams1<any>, options?: QueryOptions): UseQueryResult<T> {
    const { data, total, loading, error } = raUseQueryWithStore({ ...query, type }, options);
    return { data, total, loading, error } as UseQueryResult<T>
}
