import { Config } from './types';

const defaultKeycloak: Pick<
  Config['authConfig']['Keycloak'],
  'url' | 'realm'
> = {
  url: process.env.REACT_APP_KEYCLOAK_HOST + '/auth',
  realm: 'porthos',
};

const defaultData: Pick<Config['dataConfig'], 'api'> = {
  api: process.env.REACT_APP_BACKEND_HOST + '/api',
};

export const defaultConfig = {
  authConfig: {
    Keycloak: defaultKeycloak,
  },
  dataConfig: defaultData,
  showIpex: true,
  showUvam: false,
  isLeafReseller: false,
};
