import { Stack } from '@mui/material';
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  ResourceComponentProps,
  SelectInput,
  TextField,
  useGetList,
  useGetMany,
  useTranslate
} from 'react-admin';
import { EditDeleteButtons } from 'src/lib/react-admin_lib/EditDeleteButtons';

export const GridConnectionPointCustomContractList = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.grid-connection-point-custom-contracts';
  const customContractList = useGetList('custom-contracts');
  const counterpartIds = Object.values(customContractList?.data).map((element) => (element.counterpart));
  const counterpartList = useGetMany('companies', counterpartIds);
  const counterpartLabelMap: Record<string, string> = counterpartList.data.reduce((acc: Record<string, string>, item) => {
    if (item) {
      acc[item.id] = item.businessName;
    }
    return acc;
  }, {});
  const filters = [
    <ReferenceInput
      key="customContract"
      reference="custom-contracts"
      source="customContract"
      link="show"
      alwaysOn
      resettable
      variant="standard"
    >
      <SelectInput optionText={(record) => {
          const counterpart = counterpartLabelMap[record.counterpart];
          return counterpart ? `${record.id} - ${counterpart}` : `${record.id}`;
        }}
        style={{ width: '250px' }}
        optionValue="id"
      />
    </ReferenceInput>,
    <ReferenceInput
      key="gridConnectionPoint"
      label={translate(`${tFP}.fields.gridConnectionPoint`)}
      reference="grid-connection-points"
      source="gridConnectionPoint"
      link="show"
      alwaysOn
      resettable
      variant="standard"
    >
      <AutocompleteInput
        optionText="meteringPointId"
        optionValue="id"
        style={{ width: '250px' }}
      />
    </ReferenceInput>,
    <ReferenceInput
      key="product"
      reference="products"
      source="product"
      link="show"
      alwaysOn
      resettable
      variant="standard"
    >
      <SelectInput
        optionText={(record) => `${record.id} - ${record.productCode} - ${record.type}`}
        optionValue="id"
        style={{ width: '250px' }}
      />
    </ReferenceInput>,
  ];
  return (
    <List {...props} exporter={false} bulkActionButtons={false} filters={filters}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <ReferenceField
            label={translate(`${tFP}.fields.customContract`)}
            source="customContract"
            reference="custom-contracts"
            link={false}
          >
            <Stack spacing={2} direction="row" style={{ width: '250px' }}>
              <TextField
                source="id"
                emptyText="-"
                label={translate(`${tFP}.fields.customContract`)}
              />
              <ReferenceField
                source="counterpart"
                reference="companies"
                link={false}
              >
                <TextField
                  source="businessName"
                  emptyText="-"
                />
              </ReferenceField>
            </Stack>
        </ReferenceField>
        <DateField source="allocationDate" />
        <DateField source="startDate" />
        <DateField source="endDate" />
        <ReferenceField
          label={translate(`${tFP}.fields.gcpId`)}
          source="gridConnectionPoint"
          reference="grid-connection-points"
          link={false}
        >
          <TextField source="meteringPointId" />
        </ReferenceField>
        <ReferenceField
          label={translate(`${tFP}.fields.product`)}
          source="product"
          reference="products"
          link={false}
        >
          <Stack spacing={2} direction="row" style={{ width: '250px' }}>
            <TextField
              source="id"
              emptyText="-"
            />
            <TextField
              source="productCode"
              emptyText="-"
            />
            <TextField
              source="type"
              emptyText="-"
            />
          </Stack>
        </ReferenceField>
        <TextField source="powerMw" />
        <TextField source="price" />
        <FunctionField
          source="note"
          render={record => record?.note ? translate(`${tFP}.fields.yesNote`) : translate(`${tFP}.fields.noNote`)}
        />
        <EditDeleteButtons remove={true}/>
      </Datagrid>
    </List>
  );
};
