
import { Button } from '@material-ui/core';
import ContentAdd from '@material-ui/icons/Add';
import {
  BooleanField,
  Datagrid,
  DateField,
  Link,
  List,
  ReferenceField,
  ReferenceInput,
  ResourceComponentProps,
  SelectInput,
  TextField,
  useTranslate
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { EditDeleteButtons } from 'src/lib/react-admin_lib/EditDeleteButtons';

const ActionButtons = () => {
  const translate = useTranslate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const filter = JSON.parse(queryParams.get('filter') || '{}');
  const uvamId = filter.uvam;
  const tFP = 'resources.uvam-activation-statuses';
  return(
    <Button
        style={{
          marginTop: "20px",
          marginLeft: "8px"
        }}
        color="primary"
        component={Link}
        startIcon={<ContentAdd />}
        to={{
            pathname: '/uvam-activation-statuses/create',
            state: { record: { uvam: uvamId } },
        }}
    >
      {translate(`${tFP}.create`)}
    </Button>
  );
};

export const UvamActivationStatusList = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.uvam-activation-statuses';
  const filters = [
    <ReferenceInput
      reference="products"
      source="product"
      link="show"
      alwaysOn
      variant="standard"
    >
      <SelectInput
        optionText={(record) => `${record.id} - ${record.productCode} - ${record.type}`}
        optionValue="id"
        style={{ width: '200px' }}
      />
    </ReferenceInput>
  ];
  return (
    <List {...props} exporter={false} bulkActionButtons={false} actions={<ActionButtons />} filters={filters}>
      <Datagrid rowClick="show">
        <ReferenceField
          label={translate(`${tFP}.fields.productCode`)}
          source="product"
          reference="products"
          link={false}
        >
          <TextField
            source="productCode"
            emptyText="-"
            label={translate(`${tFP}.fields.productCode`)}
          />
        </ReferenceField>
        <DateField source="startDate" />
        <DateField source="endDate" />
        <BooleanField source="active" />
        <EditDeleteButtons remove={true}/>
      </Datagrid>
    </List>
  );
};
