import { Paper, Typography } from '@material-ui/core';
import { Title, useTranslate } from 'react-admin';
import { config } from '@config/config';
import Box from '@material-ui/core/Box';

export const Dashboard = () => {
  const translate = useTranslate();
  const tFP = 'resources.dashboard';
  return (
    <Box m={10}>
      <Title title="Dashboard" />
      <Paper>
        <Box
          p={10}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h5" color="textSecondary">
            {translate(`${tFP}.title`, { portalName: config.title })}
          </Typography>
          <Box p={1}></Box>
          <Typography align="center">
            {translate(`${tFP}.sub_title`)}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};
