import { Button } from '@material-ui/core';
import React from 'react';
import { FilterButton, sanitizeListRestProps, TopToolbar } from 'react-admin';
import ViewList from '@material-ui/icons/ViewList';
import ViewAgenda from '@material-ui/icons/ViewAgenda';

interface ListActionsProps {
  maxResults?: number;
  viewList: boolean;
  setViewList: React.Dispatch<React.SetStateAction<boolean>>;
  component?: React.ReactElement;
  filterAction?: boolean
}

export const SwitchCardViewAction = (props: ListActionsProps) => {
  const { viewList, setViewList, component, ...rest } = props;
  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {component && component}
      { props.filterAction && <FilterButton/>}
      <Button
        onClick={() => {
          setViewList(!viewList);
        }}
        color="primary"
      >
        {viewList ? <ViewList /> : <ViewAgenda />}
      </Button>
    </TopToolbar>
  );
};
