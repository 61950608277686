import React, { cloneElement } from 'react';
import {
  Divider,
  makeStyles,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import lodashGet from 'lodash/get';

export const MENU_WIDTH = 240;
export const CLOSED_MENU_WIDTH = 55;

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: '0.5em',
      marginBottom: '1em',
      [theme.breakpoints.only('xs')]: {
        marginTop: 0,
      },
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },

    alignItemsFlexStart: {
      justifyContent: ' inherit',
    },
    open: {
      width: lodashGet(theme, 'menu.width', MENU_WIDTH),
    },
    closed: {
      width: lodashGet(theme, 'menu.closedWidth', CLOSED_MENU_WIDTH),
    },
    icon: {
      minWidth: theme.spacing(5),
      color: theme.palette.text.secondary,
      alignSelf: 'center',
    },
    activeIcon: {
      color: theme.palette.text.primary,
      minWidth: theme.spacing(5),
    },
    active: {
      color: theme.palette.text.primary,
    },
    root: {
      color: theme.palette.text.secondary,
      paddingTop: '6px !important',
      paddingBottom: '6px !important',
      cursor: 'pointer',
    },
    nowrap: {
      whiteSpace: 'nowrap',
      margin: '0px !important',
    },
  }),
  { name: 'RaMenu' }
);

interface IMenuDropDown {
  name: string;
  leftIcon: React.ReactElement;
  children: JSX.Element | JSX.Element[];
}
export const MenuDropDown = (props: IMenuDropDown) => {
  const { name, leftIcon, children } = props;
  const [dropdown, setDropDown] = React.useState(false);
  const [active, setActive] = React.useState(false);
  const classes = useStyles();
  const location = useLocation();

  function handleClick() {
    setDropDown(!dropdown);
  }
  React.useLayoutEffect(() => {
    const activeRoute = React.Children.map(
      children,
      (value: any) => value.props.to
    ).includes(location.pathname);

    setActive(activeRoute);
    setDropDown(activeRoute);
  }, [location, children]);
  return (
    <>
      <ListItem className={classes.root} onClick={handleClick}>
        {leftIcon && (
          <ListItemIcon className={active ? classes.activeIcon : classes.icon}>
            {cloneElement(leftIcon, {
              titleAccess: name,
            })}
          </ListItemIcon>
        )}
        <ListItemText
          className={`${active ? classes.active : ''} ${classes.nowrap}`}
          primary={name}
        />
        {dropdown ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={dropdown} timeout="auto" unmountOnExit>
        <Divider />
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
};
