import React from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Typography, Grid } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/EditOutlined';

export const onfileupload = (
  e: any,
  setImageString: any,
  onChange: any,
  getFileName: any
) => {
  onChange(e.target.files[0]);
  setImageString(e.target.files[0]);
  getFileName(e.target.files[0].name);
};

const useStyles = makeStyles((theme) => ({
  image: {
    border: `2px dashed ${theme.palette.secondary.main}`,
    boxSizing: 'border-box',
    borderRadius: '16px',
  },
  checkIcon: {
    backgroundColor: `${theme.palette.success.main}`,
    margin: '13px',
  },
  editIcon: {
    backgroundColor: `${theme.palette.primary.main}`,
  },

  addIcon: {
    fontSize: '2rem',
    color: theme.palette.primary.dark,
  },
  imageInput: {
    opacity: 0,
    width: '100%',
    cursor: 'pointer',
    height: '100%',
    position: 'absolute',
  },
  checkIconColor: {
    color: '#ffffff',
  },
  root: (image) => ({
    display: image ? 'none' : 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '162px',
    border: `2px dashed ${theme.palette.secondary.main}`,
    boxSizing: 'border-box',
    borderRadius: '16px',
    background: theme.palette.secondary.light,
    marginTop: '5px',
  }),
}));

export const FileUploader = (props: any) => {
  const { onChange, value, withEditPicture, label, accept } = props;
  const [fileName, setFileName] = React.useState(value ? value.name : '');
  const [imageString, setImageString] = React.useState(value);
  const [editPicture, setEditPicture] = React.useState(withEditPicture || '');

  const classes = useStyles(imageString || editPicture ? true : false);

  const handleImageChange = (e: any) => {
    onfileupload(e, setImageString, onChange, setFileName);
  };

  const handleEditImage = () => {
    setImageString('');
    setEditPicture('');
    onChange('');
  };
  return (
    <>
      <Box
        display={imageString || editPicture ? 'flex' : 'none'}
        flexDirection="row"
        alignItems="center"
      >
        {editPicture && (
          <>
            <img
              width="236px"
              alt="todo-item"
              height="162px"
              className={classes.image}
              src={editPicture}
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <IconButton aria-label="edit" onClick={handleEditImage}>
                  <EditIcon color="primary" />
                </IconButton>
              </Box>
              <Typography>Edit Image</Typography>
            </Box>
          </>
        )}
        {imageString && (
          <Grid container>
            <Grid item xs={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Box
                  className={classes.checkIcon}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="24px"
                  width="24px"
                  borderRadius="50%"
                >
                  <Check className={classes.checkIconColor} />
                </Box>
                <Typography align="center">{fileName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Box display="flex" alignItems="center" justifyContent="center">
                  <IconButton
                    aria-label="edit"
                    color="primary"
                    onClick={handleEditImage}
                  >
                    <EditIcon color="primary" />
                  </IconButton>
                </Box>
                <Typography>Edit File</Typography>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
      <Box className={classes.root}>
        <Box className={classes.addIcon}>&#43;</Box>
        <Typography>{label}</Typography>
        <input
          type="file"
          className={classes.imageInput}
          accept={accept ? accept : 'application/vnd.ms-excel'}
          onChange={handleImageChange}
        />
      </Box>
    </>
  );
};
