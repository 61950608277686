import { makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import fileDownload from 'js-file-download';
import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberInput,
  Record,
  Record as RecordType,
  ReferenceField,
  ResourceComponentProps,
  TextField,
  TextInput,
  useLocale,
  useNotify,
  useTranslate,
} from 'react-admin';
import { useAxios } from '../../lib/custom-hooks/useAxios';
import { ButtonField } from '../../lib/react-admin_lib/button-field';

const useStyles = makeStyles({
  chipfieldColor: {
    color: '#ffffff',
  },
  fieldHeaderAlign: {
    textAlign: 'right',
  },
  fieldHeaderAlignLeft: {
    textAlign: 'left',
  },
});

export const InvoiceList = (props: ResourceComponentProps) => {
  const classes = useStyles();
  const notify = useNotify();
  const { refetch } = useAxios();
  const translate = useTranslate();
  const locale = useLocale();
  const tFP = 'resources.invoices';
  const handleFileDownload = async (record: RecordType) => {
    const res = await refetch({
      url: `/invoices/${record.id}/download`,
      method: 'get',
      responseType: 'arraybuffer',
    });
    if (res) {
      const { result, error } = res;
      if (result) {
        fileDownload(
          result.data,
          result.headers['content-disposition'].split('=')[1]
        );
      }
      if (error) {
        notify('error', 'error');
      }
    }
  };

  const invoiceFilters = [
    <TextInput
      label={translate(`${tFP}.fields.company`)}
      source="company.businessName"
      variant="standard"
      alwaysOn
    />,
    <NumberInput
      label={translate(`${tFP}.fields.year`)}
      source="year"
      variant="standard"
      alwaysOn
    />,
    <NumberInput
      label={translate(`${tFP}.fields.month`)}
      source="month"
      variant="standard"
      alwaysOn
    />,
  ];

  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={false}
      filters={invoiceFilters}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid>
        <DateField source="createdAt" />
        <TextField source="year" />
        <TextField source="month" />
        <ReferenceField
          reference="companies"
          textAlign="left"
          source="company"
          link="show"
        >
          <ChipField
            source="businessName"
            size="small"
            color="primary"
            className={classes.chipfieldColor}
            clickable={true}
          />
        </ReferenceField>
        <FunctionField
          label={translate(`${tFP}.fields.invoicedPlants`)}
          render={(record: Record | undefined) =>
            `${record?.invoicedPlants} ${translate(`${tFP}.of`)} ${
              record?.totalPlants
            }`
          }
        />
        <FunctionField
          textAlign="right"
          headerClassName={classes.fieldHeaderAlign}
          label={translate(`${tFP}.fields.totalMwh`)}
          render={(record: Record | undefined) =>
            `${new Intl.NumberFormat(locale).format(record?.totalMwh)}`
          }
        />
        <FunctionField
          align="right"
          textAlign="right"
          headerClassName={classes.fieldHeaderAlign}
          label={translate(`${tFP}.fields.totalAmount`)}
          render={(record: Record | undefined) =>
            `${new Intl.NumberFormat(locale, {
              style: 'currency',
              currency: 'EUR',
            }).format(record?.totalAmount)}`
          }
        />
        <ButtonField
          variant="contained"
          color="primary"
          startIcon={<GetAppIcon />}
          size="small"
          label={translate(`${tFP}.downlad_details`)}
          onClick={handleFileDownload}
        />
      </Datagrid>
    </List>
  );
};
