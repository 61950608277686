import React from 'react';
import { Moment } from 'moment';
import { Zone } from './useAverageIpexGraph';
import _ from 'lodash';
import { Market } from './useAverageIpexGraph';
import MomentUtils from '@date-io/moment';

type IpexPrice = {
  date: string;
  hour: number;
  pop: string;
  market: string;
  zone: string;
  price: number;
};

export type THourlyIpexResponse = {
  [key in Zone]: {
    [key in Market]: IpexPrice[];
  };
};

const hourlyIpexGraphInitialData = (
  currentZone: Zone,
  response?: THourlyIpexResponse
) => {
  const zoneList = _.sortBy(
    _.map(response ?? {}, function (value, key) {
      return { id: key, name: key };
    }),
    ['name', 'id']
  );

  const mgp = response?.[currentZone]?.[Market.Mgp];

  const buildDateTime = (ipexPrice: IpexPrice) => ({
    ...ipexPrice,
    dateTime: new MomentUtils()
      .parse(ipexPrice.date, 'yyyy-MM-DD')
      .add(ipexPrice.hour, 'hours'),
  });

  const dateXAxis = mgp?.map((ipexPrice) => buildDateTime(ipexPrice).dateTime);

  const buildMarketLineChartData = (data: IpexPrice[]) => {
    const extendedData = data.map((ipexPrice) => buildDateTime(ipexPrice));

    let nextIndex = 0;
    const priceData = [];

    for (const date of dateXAxis!) {
      if (
        nextIndex >= data.length ||
        date.isBefore(extendedData[nextIndex].dateTime)
      ) {
        priceData.push(null);
      } else {
        priceData.push(
          Number.parseFloat(extendedData[nextIndex].price.toFixed(2))
        );
        nextIndex++;
      }
    }

    return priceData;
  };

  const marketList = Object.fromEntries(
    Object.entries(response?.[currentZone] ?? {}).map(([market, data]) => [
      market,
      buildMarketLineChartData(data),
    ])
  );

  Object.assign(marketList, {
    datetime: dateXAxis?.map((date) =>
      new MomentUtils().format(date, 'yyyy-MM-DD H')
    ),
  });

  const requiredResult = dateXAxis?.map((value: Moment, index: number) => {
    return Object.keys(marketList)
      .map((value) => {
        return { [value]: marketList[value][index] };
      })
      .reduce((acc: any, o: any) => Object.assign(acc, o));
  });

  return {
    data: requiredResult,
    zoneList,
  };
};
export const useHourlyIpexGraph = (
  response: THourlyIpexResponse,
  initialZone: Zone
) => {
  const [data, setData] = React.useState<
    | {
        [x: string]: number | null;
      }[]
    | undefined
  >();

  const [hourlyIpexCurrentZone, sethourlyIpexCurrentZone] =
    React.useState(initialZone);
  const [hourlyIpexZoneList, setHourlyIpexZoneList] = React.useState<
    {
      id: string;
      name: string;
    }[]
  >();
  const handleHourlyIpexCurrentZoneChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: Zone;
    }>
  ) => {
    sethourlyIpexCurrentZone(event.target.value);
  };

  React.useEffect(() => {
    const loadUpdatedData = () => {
      const { data: initialData, zoneList } = hourlyIpexGraphInitialData(
        hourlyIpexCurrentZone,
        response
      );
      setData(initialData);
      setHourlyIpexZoneList(zoneList);
    };
    loadUpdatedData();
  }, [response, hourlyIpexCurrentZone]);

  return {
    data,
    hourlyIpexZoneList,
    hourlyIpexCurrentZone,
    handleHourlyIpexCurrentZoneChange,
  };
};
