import {
  ReferenceField,
  ResourceComponentProps,
  Show,
  SimpleShowLayout,
  TextField,
  ChipField,
  DateField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  chipfieldColor: {
    color: '#ffffff',
  },
});

export const BspShow = (props: ResourceComponentProps) => {
  const classes = useStyles();
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="eic" />
        <TextField source="username" label="Equigy Username" />
        <TextField source="orgClientId" />

        <ReferenceField reference="companies" source="company" linkType="show">
          <ChipField
            source="businessName"
            size="small"
            color="primary"
            className={classes.chipfieldColor}
            clickable={true}
          />
        </ReferenceField>
        <DateField source="createdAt" />
      </SimpleShowLayout>
    </Show>
  );
};
