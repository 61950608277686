import { Box } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import ListIcon from '@material-ui/icons/List';
import SaveIcon from '@material-ui/icons/Save';
import { Stack } from '@mui/material';
import React from 'react';
import {
  BooleanField,
  Button,
  DateField,
  Labeled,
  NumberInput,
  ReferenceField,
  ReferenceManyField,
  ResourceComponentProps,
  SimpleShowLayout,
  SingleFieldList,
  useRecordContext,
  useTranslate
} from 'react-admin';
import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { TextFieldsCard } from 'src/lib/mui_lib/text-fields-card';
import { LabeledField } from 'src/lib/react-admin_lib/labeled-field';
import dataProvider from 'src/providers/dataProvider';

export const BaselineManagement = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const gcp = useRecordContext();
  const [ editable, setEditable ] = React.useState(false);
  const [ edited, setEdited ] = React.useState(false);
  const tFP = 'resources.grid-connection-points.tabs.baselineManagement.fields';
  const filter = gcp?.id ? JSON.stringify({ gridConnectionPoint: gcp.id }) : '';
  const handleSubmit = async (values: any) => {
    await dataProvider.update('grid-connection-points', {
      id: gcp.id,
      data: { ...gcp, exergyId: values.exergyId },
      previousData: gcp
    });
    setEditable(false);
    setEdited(false);
  };
  return (
    <Box mt="2" mb={2}>
      <SimpleShowLayout>
        <Stack spacing={1}>
          {/* Exergt Id */}
          <Stack spacing={1} direction="row" alignItems="start">
            <Form 
              redirect="show"
              onSubmit={(values) => {
                handleSubmit(values);
              }} 
              initialValues={{ exergyId: gcp?.exergyId }}>
                {({ handleSubmit }) => (
                  <>
                    <TextFieldsCard title={translate(`${tFP}.exergy`)}>
                      <NumberInput
                        source="exergyId"
                        emptyText="-"
                        label={translate(`${tFP}.exergyId`)}
                        onChange={() => setEdited(true)}
                        disabled={!editable}
                      />
                    </TextFieldsCard>
                    <Button 
                      onClick={() => setEditable(true)}
                      label={translate('resources.grid-connection-points.edit')}
                      startIcon={<EditIcon />}
                      size="small"
                      variant="text"
                    />
                    <Button
                      onClick={handleSubmit}
                      label={translate('resources.grid-connection-points.save')}
                      startIcon={<SaveIcon />}
                      size="small"
                      variant="text"
                      disabled={!edited}
                    />
                  </>
                )}
            </Form>
          </Stack>
          {/* Force Availability */}
          <Stack spacing={1} direction="row" alignItems="start">
            <TextFieldsCard title={translate(`${tFP}.forceAvailability`)}>
              <Labeled
                label={translate(`${tFP}.forcedActiveProduct`)}
                fullWidth={true}
              >
                <Stack spacing={1} direction="row">
                  <ReferenceManyField
                      reference="grid-connection-point-force-availabilities" 
                      target="gridConnectionPoint"
                      filter={{ active: true }}   
                  >
                    <SingleFieldList linkType={'show'}>
                      <ReferenceField
                        source="product"
                        reference="products"
                        link={false}
                      >
                        <Stack spacing={1} direction="row">
                            <LabeledField
                              source="productCode"
                              emptyText="-"
                              label={translate(`${tFP}.productCode`)}
                            />
                            <LabeledField
                              source="type"
                              emptyText="-"
                              label={translate(`${tFP}.productType`)}
                            />
                        </Stack>
                      </ReferenceField>
                    </SingleFieldList>
                  </ReferenceManyField>
                  <ReferenceManyField
                    reference="grid-connection-point-force-availabilities" 
                    target="gridConnectionPoint"
                    filter={{ active: true }}   
                  >
                    <SingleFieldList linkType={'show'}>
                      <Stack spacing={1} direction="row">
                        <Box display="flex" width="190px">
                          <Labeled label={translate(`${tFP}.startDate`)}>
                            <DateField
                              source="startDate"
                              emptyText="-"
                            />
                          </Labeled>
                        </Box>
                        <Box display="flex" width="190px">
                          <Labeled label={translate(`${tFP}.endDate`)}>
                            <DateField
                              source="endDate"
                              emptyText="-"
                            />
                          </Labeled>
                        </Box>
                        <Box display="flex" width="190px">
                          <Labeled label={translate(`${tFP}.availability`)}>
                            <BooleanField source="availability" />
                          </Labeled>
                        </Box>
                      </Stack>
                    </SingleFieldList>
                  </ReferenceManyField>
                </Stack>
              </Labeled>
            </TextFieldsCard>      
            <Button
              color="primary"
              component={Link}
              to={{
                pathname: '/grid-connection-point-force-availabilities',
                search: `filter=${filter}`,
              }}
              endIcon={<ListIcon />}
              size="small"
              variant="text"
              label={translate(`${tFP}.showAll`)}
            ></Button>
          </Stack>

          {/* Abilitation without Product */}
          <Stack spacing={1} direction="row" alignItems="start">
            <TextFieldsCard title={translate(`${tFP}.abilitationNoProduct`)}>
              <Labeled
                label={translate(`${tFP}.abilitationNoProductDetails`)}
                fullWidth={true}
              >
                <Stack spacing={1} direction="row">
                  <ReferenceManyField
                      reference="grid-connection-point-abilitation-no-product" 
                      target="gridConnectionPoint"
                      filter={{ active: true }}   
                  >
                    <SingleFieldList linkType={'show'}>
                      <Stack spacing={1} direction="row">
                        <Box display="flex" width="190px">
                          <Labeled label={translate(`${tFP}.startDate`)}>
                            <DateField
                              source="startDate"
                              emptyText="-"
                            />
                          </Labeled>
                        </Box>
                        <Box display="flex" width="190px">
                          <Labeled label={translate(`${tFP}.endDate`)}>
                            <DateField
                              source="endDate"
                              emptyText="-"
                            />
                          </Labeled>
                        </Box>
                      </Stack>
                    </SingleFieldList>
                  </ReferenceManyField>
                </Stack>
              </Labeled>
            </TextFieldsCard>
            <Button
              color="primary"
              component={Link}
              to={{
                pathname: '/grid-connection-point-abilitation-no-product',
                search: `filter=${filter}`,
              }}
              endIcon={<ListIcon />}
              size="small"
              variant="text"
              label={translate(`${tFP}.showAll`)}
            />
          </Stack>

          {/* Force forecast */}
          <Stack spacing={1} direction="row" alignItems="start">
            <TextFieldsCard title={translate(`${tFP}.forceForecastUpm`)}>
              <Labeled
                label={translate(`${tFP}.forceForecastUpmDetails`)}
                fullWidth={true}
              >
                <Stack spacing={1} direction="row">
                  <ReferenceManyField
                      reference="gcp-force-forecast-upm" 
                      target="gridConnectionPoint"
                      filter={{ active: true }}   
                  >
                    <SingleFieldList linkType={'show'}>
                      <Stack spacing={1} direction="row">
                        <Box display="flex" width="190px">
                          <Labeled label={translate(`${tFP}.startDate`)}>
                            <DateField
                              source="startDate"
                              emptyText="-"
                            />
                          </Labeled>
                        </Box>
                        <Box display="flex" width="190px">
                          <Labeled label={translate(`${tFP}.endDate`)}>
                            <DateField
                              source="endDate"
                              emptyText="-"
                            />
                          </Labeled>
                        </Box>
                      </Stack>
                    </SingleFieldList>                    
                  </ReferenceManyField>
                </Stack>
              </Labeled>
            </TextFieldsCard>
            <Button
              color="primary"
              component={Link}
              to={{
                pathname: '/gcp-force-forecast-upm',
                search: `filter=${filter}`,
              }}
              endIcon={<ListIcon />}
              size="small"
              variant="text"
              label={translate(`${tFP}.showAll`)}
            />
          </Stack>

         </Stack>
      </SimpleShowLayout>
    </Box> 
  );
};
