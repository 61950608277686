import { Box } from '@material-ui/core';
import { Add, Edit } from '@material-ui/icons';
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  Record,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import { GenericFilterComponent } from '../../../lib/custom-useful-components/GenericFilterComponent';
import { Empty } from '../../../lib/mui_lib/empty';
import { ButtonField } from '../../../lib/react-admin_lib/button-field';
import { EditableStatusView } from '../../../resources/grid-connection-points/status-management-components/EditableStatusView';
enum CPaEditableStatus {
  Open = 'Open',
  Rejected = 'Rejected',
}
export const ConnectionParameters = () => {
  const translate = useTranslate();
  const tFP = 'resources.uvam.tabs.connection_parameters';
  const history = useHistory();

  const record = useRecordContext();
  const types = record.uvamConnections.map((item: any) => item.type);
  const isEditable = Object.values(CPaEditableStatus).includes(
    record.currentStatus
  );
  const handleOnClickAddNewCpa = (record: Record) => {
    history.push({
      pathname: '/uvam-connections/create',
      state: {
        UvamId: record.id,
        type:
          types.length > 0
            ? types.includes('COMANDI')
              ? 'COMANDI'
              : 'MISURA'
            : '',
      },
    });
  };
  return (
    <>
      <Box mb={2}>
        <Box display="flex" justifyContent="right">
          <EditableStatusView editableStatus={CPaEditableStatus}>
            <ButtonField
              label="Create"
              onClick={handleOnClickAddNewCpa}
              disabled={types.includes('COMANDI') && types.includes('MISURA')}
            >
              <Add />
            </ButtonField>
          </EditableStatusView>
        </Box>

        <GenericFilterComponent
          filter={() => record.uvamConnections?.length > 0}
          empty={<Empty resource={translate(`${tFP}.name`)} />}
        >
          <ArrayField source="uvamConnections">
            <Datagrid>
              <DateField
                source="createdAt"
                label={translate(`${tFP}.fields.createdAt`)}
              />
              <TextField
                source="type"
                label={translate(`${tFP}.fields.type`)}
              />
              <TextField
                source="manufacturer"
                label={translate(`${tFP}.fields.manufacturer`)}
              />
              <TextField
                source="ipAddress"
                label={translate(`${tFP}.fields.ipAddress`)}
              />
              <TextField
                source="fullScaleMin"
                label={translate(`${tFP}.fields.fullScaleMin`)}
              />
              <TextField
                source="fullScaleMax"
                label={translate(`${tFP}.fields.fullScaleMax`)}
              />

              {isEditable && (
                <FunctionField
                  source={translate(`resources.uvam.edit`)}
                  render={(r: Record | undefined) => (
                    <ButtonField
                      label={translate(`resources.uvam.edit`)}
                      onClick={function (id: any): void {
                        history.push({
                          pathname: '/uvam-connections/' + r!.id,
                          state: { UvamId: record!.id },
                        });
                      }}
                    >
                      <Edit fontSize="inherit" />
                    </ButtonField>
                  )}
                />
              )}
            </Datagrid>
          </ArrayField>
        </GenericFilterComponent>
      </Box>
    </>
  );
};
