import { makeStyles } from '@material-ui/core';

import {
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  Record,
  ResourceComponentProps,
  TextField,
  TextInput,
  useLocale,
  useTranslate
} from 'react-admin';
import { EditDeleteButtons } from 'src/lib/react-admin_lib/EditDeleteButtons';

const useStyles = makeStyles({
  chipfieldColor: {
    color: '#ffffff',
  },
  fieldHeaderAlign: {
    textAlign: 'right',
  },
  fieldHeaderAlignLeft: {
    textAlign: 'left',
  },
});

export const ProductList = (props: ResourceComponentProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  const locale = useLocale();
  const tFP = 'resources.products';
  const filters = [
    <TextInput
      placeholder={translate(`${tFP}.fields.type`)}
      style={{ width: '200px' }}
      source="type"
      alwaysOn
      resettable
      variant="standard"
    />,
    <TextInput
      placeholder={translate(`${tFP}.fields.productCode`)}
      source="productCode"
      style={{ width: '200px' }}
      alwaysOn
      resettable
      variant="standard"
    />
  ];
  return (
    <List {...props} exporter={false} bulkActionButtons={false} filters={filters}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <DateField source="startDate" />
        <TextField source="type" />
        <TextField source="startTime" />
        <TextField source="endTime" />
        <BooleanField source="holiday" />
        <FunctionField
          align="right"
          textAlign="right"
          headerClassName={classes.fieldHeaderAlign}
          label={translate(`${tFP}.fields.strikePrice`)}
          render={(record: Record | undefined) =>
            `${new Intl.NumberFormat(locale, {
              style: 'currency',
              currency: 'EUR',
            }).format(record?.strikePrice)}`
          }
        />
        <DateField source="endDate" />
        <TextField source="productCode" />
        <EditDeleteButtons remove={true}/>
      </Datagrid>
    </List>
  );
};
