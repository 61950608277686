import { Box } from '@material-ui/core';
import {
  FieldProps,
  Labeled,
  TextField,
  TextInput as RaTextInput,
  TextInputProps,
} from 'react-admin';

export const LabeledField = (props: FieldProps) => {
  return (
    <Box display="flex" width="190px">
      <Labeled label={props.label}>
        <TextField {...props} />
      </Labeled>
    </Box>
  );
};

export const TextInput = (props: TextInputProps) => {
  return (
    <RaTextInput style={{ width: '305px' }} {...props} variant="standard" />
  );
};
