import {
  ReferenceField,
  ResourceComponentProps,
  Show,
  SimpleShowLayout,
  TextField,
  ChipField,
  DateField,
  useLocale,
  useTranslate,
  FunctionField,
  Record,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  chipfieldColor: {
    color: '#ffffff',
  },
});

export const PlantShow = (props: ResourceComponentProps) => {
  const classes = useStyles();
  const locale = useLocale();
  const translate = useTranslate();
  const tFP = 'resources.plants';
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <DateField source="createdAt" />
        <TextField source="upCode" />
        <TextField source="sapr" />
        <TextField source="name" />
        <TextField source="type" />
        <ReferenceField reference="companies" source="currentOwner" link="show">
          <ChipField
            source="businessName"
            size="small"
            color="primary"
            clickable={true}
            className={classes.chipfieldColor}
          />
        </ReferenceField>
        <TextField source="address.address" />
        <TextField source="address.city.name" />
        <TextField source="address.city.province.name" />
        <FunctionField
          textAlign="right"
          label={translate(`${tFP}.fields.currentValues.power`)}
          render={(record: Record | undefined) =>
            `${new Intl.NumberFormat(locale).format(
              record?.currentValues.power
            )}`
          }
        />
        <TextField source="currentValues.voltageType" />
      </SimpleShowLayout>
    </Show>
  );
};
