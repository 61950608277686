import {
  Create,
  required,
  ResourceComponentProps,
  SimpleForm,
  TextInput,
  useTranslate,
  number,
  minValue,
  maxValue,
  SelectInput,
  CheckboxGroupInput,
  Toolbar,
  SaveButton,
  ToolbarProps,
  NumberInput,
  regex,
  ReferenceInput,
} from 'react-admin';
import { CreateScWebOperator } from './uvam-resource-tabs/CreateScWebOperator';

const PostCreateToolbar = (props: ToolbarProps) => {
  const handleTransform = (data: any) => {
    const markets = data.marketTypes.map((market: any) => ({
      marketType: market,
      startDate: new Date(),
    }));
    return {
      ...data,
      marketTypes: markets,
      bsp: data.bsp && {
        id: data.bsp,
      },
      scWebOperator: data.scWebOperator && {
        id: data.scWebOperator,
      },
    };
  };
  return (
    <Toolbar {...props}>
      <SaveButton transform={handleTransform} />
    </Toolbar>
  );
};

export const UvamCreate = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.uvam';

  return (
    <Create {...props}>
      <SimpleForm
        redirect="show"
        variant="standard"
        toolbar={<PostCreateToolbar />}
      >
        <TextInput
          source="upCode"
          validate={[
            required(),
            regex(/UP\w*$/, translate(`${tFP}.fields.upCode_error`)),
          ]}
        />
        <TextInput
          label={translate(`${tFP}.fields.name`)}
          source="name"
          validate={required()}
        />
        <NumberInput
          source="minimumQualifiedPower"
          validate={[required(), number(), maxValue(0)]}
        />
        <NumberInput
          source="maximumQualifiedPower"
          validate={[required(), number(), minValue(0)]}
        />
        <CheckboxGroupInput
          label={translate(`${tFP}.fields.marketTypes`)}
          source="marketTypes"
          choices={[
            {
              marketType: 'A',
              name: translate(`${tFP}.marketTypes.A`),
            },
            {
              marketType: 'B',
              name: translate(`${tFP}.marketTypes.B`),
            },
            {
              marketType: 'C',
              name: translate(`${tFP}.marketTypes.C`),
            },
            {
              marketType: 'D',
              name: translate(`${tFP}.marketTypes.D`),
            },
            {
              marketType: 'E',
              name: translate(`${tFP}.marketTypes.E`),
            },
            {
              marketType: 'F',
              name: translate(`${tFP}.marketTypes.F`),
            },
            {
              marketType: 'G',
              name: translate(`${tFP}.marketTypes.G`),
            },
            {
              marketType: 'H',
              name: translate(`${tFP}.marketTypes.H`),
            },
          ]}
          optionValue="marketType"
        />
        <SelectInput
          variant="standard"
          label={translate(`${tFP}.fields.perimeter`)}
          source="perimeter"
          choices={[
            'NORD OVEST TO',
            'NORD OVEST GE',
            'NORD OVEST BS',
            'NORD OVEST MI',
            'NORD EST VE',
            'NORD EST BO',
            'DORSALE TIRRENICA RM',
            'DORSALE TIRRENICA NA',
            'DORSALE ADRIATICA PE',
            'DORSALE ADRIATICA BA',
            'SICILIA',
            'SARDEGNA',
            'DORSALE ADRIATICA AN',
            'DORSALE TIRRENICA FI',
            'DORSALE UMBRA PG',
            'DORSALE UMBRA TR',
            'DORSALE ADRIATICA TA',
            'DORSALE TIRRENICA CZ',
          ].map((perimeter) => {
            return {
              id: perimeter,
              name: perimeter,
            };
          })}
          validate={required()}
        />

        <ReferenceInput
          validate={required()}
          label="Bsp"
          source="bsp"
          allowEmpty={false}
          filterToQuery={(q) => ({ filter: { name: q } })}
          reference="bsp"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput
          validate={required()}
          label="ScWebOperator"
          source="scWebOperator"
          allowEmpty={false}
          filterToQuery={(q) => ({ filter: { name: q } })}
          reference="sc-web-operator"
        >
          <SelectInput optionText="name" create={<CreateScWebOperator />} />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
