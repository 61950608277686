import { makeStyles } from '@material-ui/core';
import React from 'react';
import {
  Layout,
  LayoutProps,
  Sidebar,
  SidebarProps,
  resolveBrowserLocale,
  useSetLocale,
} from 'react-admin';
import { useLocalStorage } from '../lib/custom-hooks/useLocalStorage';
import MyAppBar from './AppBar';
import Menu from './Menu';

const useSidebarStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100vh - 3em)',
  },
  fixed: {
    position: 'fixed',
    height: 'calc(100vh - 3em)',
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.paper,
    // hide scrollbar
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  drawerPaper: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    marginRight: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      marginTop: 0,
      height: '100vh',
      position: 'inherit',
      backgroundColor: theme.palette.background.default,
    },
    [theme.breakpoints.up('md')]: {
      border: 'none',
    },
    height: '100%',
    zIndex: 100,
    overflowX: 'hidden',
  },
}));

type MySidebarProps = Omit<SidebarProps, 'classes'>;
const MySidebar = (props: MySidebarProps) => {
  const classes = useSidebarStyles();
  return <Sidebar {...props} classes={classes} />;
};

export const CustomLayout = (props: LayoutProps) => {
  const [language] = useLocalStorage('locale', resolveBrowserLocale());
  const setLocale = useSetLocale();
  React.useEffect(() => {
    setLocale(language);
  }, [setLocale, language]);
  return (
    <Layout {...props} sidebar={MySidebar} menu={Menu} appBar={MyAppBar} />
  );
};
