import MomentUtils from '@date-io/moment';
import { Button, Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DatePicker } from '@material-ui/pickers';
import fileDownload from 'js-file-download';
import moment, { Moment } from 'moment';
import React, { Fragment } from 'react';
import { Loading, Title, useNotify, useTranslate } from 'react-admin';
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { useDarkTheme } from '../lib/custom-hooks/store-dark-theme';
import { Zone } from '../lib/custom-hooks/useAverageIpexGraph';
import { useAxios } from '../lib/custom-hooks/useAxios';
import { useHourlyIpexGraph } from '../lib/custom-hooks/useHourlyIpexGraph';
import { useStoreGraphs } from '../lib/custom-hooks/useStoreGraphs';
import { useWindowSize } from '../lib/custom-hooks/useWindowSize';
import { SelectField } from '../lib/mui_lib/select';
const initialZone = Zone.Pun;
const minDate = moment().subtract(4, 'years');
const initialStartDate = moment().subtract(20, 'days');
const initialEndDate = moment();
const formatDate = (date: Moment, format: string) => {
  return new MomentUtils().format(date, format);
};
export const HoursIpexGraph = () => {
  const { hourlyIpexGraph, setHourlyIpexGraphStore } = useStoreGraphs();
  const [loading, setLoading] = React.useState(false);
  const { refetch } = useAxios();
  const [xlscsvDownloadLoading, setXlsCsvDownloadLoading] =
    React.useState(false);
  const notify = useNotify();
  const { height } = useWindowSize();
  const {
    data: initialData,
    hourlyIpexZoneList: zoneList,
    hourlyIpexCurrentZone: zone,
    handleHourlyIpexCurrentZoneChange: handleZoneChange,
  } = useHourlyIpexGraph(hourlyIpexGraph, initialZone);
  const translate = useTranslate();
  const tFP = 'resources.reports.hourly_ipex';
  const { toggleDark } = useDarkTheme();
  const [startDate, setStartDate] = React.useState(initialStartDate);
  const [endDate, setEndDate] = React.useState(initialEndDate);
  const handleHourlyIpexStartDateChange = (e: any) => {
    setStartDate(e);
  };
  const handleHourlyIpexEndDateChange = (e: any) => {
    setEndDate(e);
  };

  const keyArray = initialData ? Object.keys(initialData[0]).sort() : [];
  const stroke = [
    '#7e57c2',
    '#66bb6a',
    '#29b6f6',
    '#f39c12',
    '#f1c40f',
    '#16a085',
    '#b39ddb',
    '#e74c3c',
  ];
  const [barProps, setBarProps] = React.useState(
    keyArray.reduce(
      (a: any) => {
        a = false;
        return a;
      },
      { hover: null }
    )
  );
  const selectLine = (e: any) => {
    setBarProps({
      ...barProps,
      [e.dataKey]: !barProps[e.dataKey],
      hover: null,
    });
  };

  const handleXlsCsvDownload = async (type: 'XLS' | 'CSV') => {
    setXlsCsvDownloadLoading(true);
    const res = await refetch({
      url: `/reports/ipex-price/download-chart?format=${type}&chartStart=${formatDate(
        startDate,
        'YYYY-MM-D'
      )}&chartEnd=${formatDate(endDate, 'YYYY-MM-D')}`,
      method: 'get',
      headers: { contentType: 'application/octet-stream' },
      responseType: 'arraybuffer',
    });
    if (res) {
      const { result, error, loading } = res;
      if (result?.data)
        fileDownload(
          result?.data,
          result?.headers?.['content-disposition'].split('=')[1]
        );
      setXlsCsvDownloadLoading(loading);
      if (error) notify('error', 'error');
    }
  };

  React.useEffect(() => {
    const getHourlyIpexResponse = async () => {
      setLoading(true);
      const res = await refetch({
        url: `/reports/ipex-price/chart-data?chartStart=${formatDate(
          startDate,
          'yyyy-MM-DD'
        )}&chartEnd=${formatDate(endDate, 'yyyy-MM-DD')}`,
        method: 'get',
      });
      if (res) {
        const { result, error, loading } = res;
        if (result?.data) setHourlyIpexGraphStore(result?.data);
        setLoading(loading);
        if (error) notify('error', 'error');
      }
    };
    getHourlyIpexResponse();
  }, [notify, refetch, startDate, endDate, setHourlyIpexGraphStore]);

  if (loading && !hourlyIpexGraph) return <Loading />;

  return (
    <Box>
      <Title title={translate(`${tFP}.title`)} />
      <CardContent>{translate(`${tFP}.title`)}</CardContent>
      <Card>
        <Paper elevation={0}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <ResponsiveContainer
              width="98%"
              height={height > 1000 ? height / 2.5 : 350}
            >
              <LineChart
                width={1000}
                height={700}
                data={initialData}
                margin={{
                  top: 20,
                  right: 10,
                  left: 0,
                  bottom: 80,
                }}
              >
                <CartesianGrid
                  strokeDasharray="3 3"
                  stroke={toggleDark ? '#4d4d4d' : '#c6c6c6'}
                />
                <Legend
                  verticalAlign="top"
                  align="center"
                  onClick={selectLine}
                  wrapperStyle={{ position: 'relative' }}
                />
                <XAxis
                  dataKey="datetime"
                  angle={-70}
                  textAnchor="end"
                  minTickGap={-75}
                  tick={
                    toggleDark
                      ? {
                          stroke: '#fff',
                          strokeWidth: 0.5,
                          fontSize: 13,
                          fontWeight: 'lighter',
                        }
                      : {
                          stroke: '#666',
                          strokeWidth: 0.5,
                          fontWeight: 'lighter',
                          fontSize: 13,
                        }
                  }
                  interval="preserveStartEnd"
                />
                <YAxis
                  tick={
                    toggleDark
                      ? {
                          stroke: '#fff',
                          strokeWidth: 0.5,
                          fontSize: 13,
                          fontWeight: 'lighter',
                        }
                      : {
                          stroke: '#666',
                          strokeWidth: 0.5,
                          fontWeight: 'lighter',
                          fontSize: 13,
                        }
                  }
                  height={700}
                  label={
                    toggleDark
                      ? {
                          stroke: 'rgba(255, 255, 255, 0.9)',
                          fill: '#fff',
                          strokeWidth: 0.5,
                          fontSize: 15,
                          fontWeight: 'lighter',
                          value: 'Euro/MWh',
                          angle: -90,
                          position: 'insideLeft',
                        }
                      : {
                          stroke: '#666',
                          strokeWidth: 0.5,
                          fontWeight: 'lighter',
                          fontSize: 15,
                          value: 'Euro/MWh',
                          angle: -90,
                          position: 'insideLeft',
                        }
                  }
                />
                <Tooltip
                  contentStyle={
                    toggleDark
                      ? {
                          color: 'rgba(255, 255, 255, 0.7)',
                          backgroundColor: '#1E272E',
                          borderColor: '#1E272E',
                        }
                      : {
                          backgroundColor: '#F3F3F4',
                          borderColor: '#F3F3F4',
                        }
                  }
                />
                {keyArray.map((value: any, index: number) => {
                  if (value !== 'datetime') {
                    return (
                      <Line
                        key={index}
                        dataKey={value}
                        animationDuration={500}
                        animationEasing="ease-out"
                        stroke={stroke[index]}
                        hide={barProps[value] === true}
                        type="monotone"
                        dot={false}
                        strokeWidth={2}
                      />
                    );
                  } else {
                    return <Fragment key={index} />;
                  }
                })}
              </LineChart>
            </ResponsiveContainer>
            <Box display="flex" flexDirection="row" mt={8} mb={2}>
              <Grid
                container
                alignItems="center"
                justify="space-around"
                spacing={3}
              >
                <Grid item xs={6}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box pr={2}>
                      <DatePicker
                        value={startDate}
                        inputVariant="standard"
                        label={translate(`${tFP}.initial_date`)}
                        format="yyyy-MM-DD"
                        minDate={minDate}
                        maxDate={endDate}
                        onChange={handleHourlyIpexStartDateChange}
                      />
                    </Box>

                    <DatePicker
                      value={endDate}
                      inputVariant="standard"
                      label={translate(`${tFP}.final_date`)}
                      format="yyyy-MM-DD"
                      minDate={startDate}
                      maxDate={initialEndDate}
                      onChange={handleHourlyIpexEndDateChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    ml={10}
                  >
                    <SelectField
                      list={zoneList ? zoneList : []}
                      labelName={translate(`${tFP}.zone`)}
                      value={zone as any}
                      onChange={handleZoneChange}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Typography>{translate(`${tFP}.download_data`)}</Typography>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                mt={1}
              >
                <Box mr={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<GetAppIcon />}
                    onClick={() => handleXlsCsvDownload('CSV')}
                    disabled={xlscsvDownloadLoading}
                  >
                    {translate(`${tFP}.download_csv`)}
                  </Button>
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<GetAppIcon />}
                  onClick={() => handleXlsCsvDownload('XLS')}
                  disabled={xlscsvDownloadLoading}
                >
                  {translate(`${tFP}.download_xls`)}
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Card>
    </Box>
  );
};
