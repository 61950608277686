import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import RemoveSharpIcon from '@material-ui/icons/RemoveSharp';
import _, { isNil } from 'lodash';
import React from 'react';
import { Identifier, useNotify, useTranslate } from 'react-admin';
import { useAxios } from '../../lib/custom-hooks/useAxios';
import Modal from '../../lib/mui_lib/modal';

interface ExcelWarningsPopupProps {
  name: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fileId: number;
  plantId: string | number | false;
  type: string;
  tFP: string;
  all: any;
  plants: {
    id: Identifier;
    name: string;
  }[];
}

interface Cell {
  id: number;
  createdAt: string;
  updatedAt: string;
  date: string;
  hour: number;
  value: number;
  file: number;
  status:
    | 'ok'
    | 'max-power'
    | 'negative'
    | 'market-constraint'
    | 'max-availability';
}

interface JsonArrayToMatrixProps {
  row: keyof Cell;
  column: keyof Cell;
  array: Cell[];
}
const useStyles = makeStyles((theme) => ({
  tableContainer: {
    [theme.breakpoints.up('md')]: {
      height: '67vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '67vh',
      paddingTop: '-3em',
    },
  },
  table: {
    border: `1px solid ${theme.palette.background.default} `,
  },
  cell: {
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
    textAlign: 'right',
  },
  cellOk: {
    backgroundColor: theme.palette.success.light,
  },
  cellMaxPower: {
    backgroundColor: theme.palette.warning.light,
  },
  cellNegative: {
    backgroundColor: theme.palette.warning.dark,
  },
  cellMarketConstraint: {
    backgroundColor: theme.palette.error.light,
  },
  cellEmpty: {
    backgroundColor: theme.palette.background.paper,
  },
  cellInvalid: {
    backgroundColor: theme.palette.error.dark,
  },
}));
const jsonToMatrix = (props: JsonArrayToMatrixProps) => {
  const { row, column, array } = props;
  const groupByDate = array.reduce(
    (acc: { [key: string]: Cell[] }, obj: Cell) => {
      if (isNil(acc[obj[column]])) {
        acc[obj[column]] = [];
      }
      acc[obj[column]].push(obj);
      return acc;
    },
    {}
  );

  const result: {
    [key: string]: {
      [key: number]: Cell;
    };
  } = Object.fromEntries(
    Object.entries(groupByDate).map(([key, value]: [string, Cell[]]) => [
      key,
      value.reduce(
        (acc, cell) => Object.assign(acc, { [cell[row]]: cell }),
        {}
      ),
    ])
  );

  return Object.entries(result)
    .sort(([key1], [key2]) => (key1 < key2 ? -1 : 1))
    .map(([key, value]) => ({
      date: key,
      values: _.range(1, 25).map((hour) => value[hour]),
    }));
};

export const ExcelWarningsPopup = (props: ExcelWarningsPopupProps) => {
  const { name, open, setOpen, fileId, plantId, tFP, plants } = props;
  const [json, setJson] = React.useState<ReturnType<typeof jsonToMatrix>>([]);
  const { refetch } = useAxios();
  const [loading, setLoading] = React.useState(false);
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const cellStatus = {
    ok: [
      `${translate(`${tFP}.excel_warning_pop_up.status-ok`)}`,
      `${classes.cellOk} ${classes.cell}`,
    ],
    'max-power': [
      `${translate(`${tFP}.excel_warning_pop_up.status-max-power`)}`,
      `${classes.cellMaxPower} ${classes.cell}`,
    ],
    negative: [
      `${translate(`${tFP}.excel_warning_pop_up.status-negative`)}`,
      `${classes.cellNegative} ${classes.cell}`,
    ],
    'market-constraint': [
      `${translate(`${tFP}.excel_warning_pop_up.status-market-constraint`)}`,
      `${classes.cellMarketConstraint} ${classes.cell}`,
    ],
    'max-availability': [
      `${translate(`${tFP}.excel_warning_pop_up.status-max-availability`)}`,
      `${classes.cellMaxPower} ${classes.cell}`,
    ],
    empty: [
      `${translate(`${tFP}.excel_warning_pop_up.status-empty`)}`,
      `${classes.cellEmpty} ${classes.cell}`,
    ],
    invalid: [
      `${translate(`${tFP}.excel_warning_pop_up.status-invalid`)}`,
      `${classes.cellInvalid} ${classes.cell}`,
    ],
  } as const;
  const isValidResponse = (r: Cell[]) =>
    Object.keys(r[0]).includes('date' && 'hour' && 'value');

  React.useEffect(() => {
    const getExcelData = async () => {
      setLoading(true);
      const res = await refetch({
        url: `/plants/${plantId}/customer-uploaded-files/${fileId}`,
        method: 'get',
      });
      if (res) {
        const { result, error } = res;
        if (result?.data && isValidResponse(result.data)) {
          setJson(
            jsonToMatrix({ row: 'hour', column: 'date', array: result.data })
          );
          setLoading(false);
        }
        if (error) {
          setLoading(false);
          notify('error', 'error');
        }
      }
    };
    getExcelData();
  }, [notify, refetch, fileId, plantId]);

  return (
    <Modal
      name={name}
      open={open}
      setOpen={setOpen}
      withOutButton={true}
      size="large"
      loading={loading}
    >
      <>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          width="100%"
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="subtitle1" color="textSecondary">
              {translate(`${tFP}.excel_warning_pop_up.type`)}
            </Typography>
            <Typography>:&nbsp;{translate(`${tFP}.${props.type}`)}</Typography>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="subtitle1" color="textSecondary">
              {translate(`${tFP}.excel_warning_pop_up.plant`)}
            </Typography>
            <Typography>
              :&nbsp;
              {plants.find((plant) => plant && plant.id === plantId)?.name}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="subtitle1" color="textSecondary">
              {translate(`${tFP}.excel_warning_pop_up.unit`)}
            </Typography>
            <Typography>
              :&nbsp; {props.type === 'forecast' ? 'MWh' : '%'}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          pt={1}
          width="100%"
        ></Box>

        <TableContainer className={classes.tableContainer}>
          <Table
            size="small"
            stickyHeader
            className={classes.table}
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                {_.range(1, 25).map((hour) => (
                  <TableCell>H-{hour}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {json.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    className={classes.cell}
                    component="th"
                    scope="row"
                  >
                    {row.date}
                  </TableCell>
                  {row.values.map((cell, index) =>
                    cell ? (
                      <Tooltip title={cellStatus[cell.status][0]}>
                        <TableCell
                          className={cellStatus[cell.status][1]}
                          key={index}
                        >
                          {props.type === 'forecast'
                            ? cell?.value?.toFixed(3)
                            : `${(cell?.value * 100)?.toFixed(2)}%`}
                        </TableCell>
                      </Tooltip>
                    ) : (
                      <Tooltip title={cellStatus['empty'][0]}>
                        <TableCell
                          className={cellStatus['empty'][1]}
                          key={index}
                        ></TableCell>
                      </Tooltip>
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          width="100%"
          pt={2}
        >
          <Box display="flex" flexDirection="row">
            <RemoveSharpIcon className={cellStatus['ok'][1]} />
            <Typography>&nbsp;{cellStatus['ok'][0]}</Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <RemoveSharpIcon className={cellStatus['market-constraint'][1]} />
            <Typography>&nbsp;{cellStatus['market-constraint'][0]}</Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <RemoveSharpIcon className={cellStatus['negative'][1]} />
            <Typography>&nbsp;{cellStatus['negative'][0]}</Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <RemoveSharpIcon className={cellStatus['empty'][1]} />
            <Typography>&nbsp;{cellStatus['empty'][0]}</Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <RemoveSharpIcon className={cellStatus['invalid'][1]} />
            <Typography>&nbsp;{cellStatus['invalid'][0]}</Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <RemoveSharpIcon
              className={
                props.type === 'forecast'
                  ? cellStatus['max-power'][1]
                  : cellStatus['max-availability'][1]
              }
            />
            <Typography>
              &nbsp;
              {props.type === 'forecast'
                ? cellStatus['max-power'][0]
                : cellStatus['max-availability'][0]}
            </Typography>
          </Box>
        </Box>
      </>
    </Modal>
  );
};
