import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { useLocation } from 'react-router-dom';

interface MenuItemIconProps {
  to: string;
  icon: React.ReactElement;
  exact?: boolean;
}
const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: '0.5em',
      marginBottom: '1em',
      [theme.breakpoints.only('xs')]: {
        marginTop: 0,
      },
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },

    alignItemsFlexStart: {
      justifyContent: ' inherit',
    },
    icon: {
      minWidth: theme.spacing(5),
      color: theme.palette.text.secondary,
      alignSelf: 'center',
    },
    activeIcon: {
      color: theme.palette.text.primary,
      minWidth: theme.spacing(5),
    },
    root: {
      color: theme.palette.text.secondary,
      paddingTop: '6px !important',
      paddingBottom: '6px !important',
      cursor: 'pointer',
    },
    nowrap: {
      whiteSpace: 'nowrap',
      margin: '0px !important',
    },
  }),
  { name: 'RaMenu' }
);
export const MenuItemIcon = (props: MenuItemIconProps) => {
  const { icon: LeftIcon, to, exact } = props;
  const [active, setActive] = React.useState(false);
  const location = useLocation();
  const classes = useStyles();
  React.useEffect(() => {
    let active = location.pathname.includes(to);
    if (exact) {
      active = to === location.pathname;
    }
    setActive(active);
  }, [location, to, exact]);
  return (
    <>
      <Box className={active ? classes.activeIcon : classes.icon}>
        {LeftIcon}
      </Box>
    </>
  );
};
