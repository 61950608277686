import React from 'react';

interface IGenericFilterViewProps {
  filter: () => boolean;
  component: React.ReactElement | React.ReactElement[] | false;
}

export const GenericFilterView = ({
  filter,
  component,
}: IGenericFilterViewProps): IGenericFilterViewProps['component'] => {
  return filter() && component;
};
