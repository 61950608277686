import { config } from '@config/config';

import Keycloak from 'keycloak-js';

import { KeycloakInstance } from 'keycloak-js';
import { AuthProvider } from 'react-admin';

export function KeycloakAuthProvider(keycloak: KeycloakInstance) {
  const provider: AuthProvider = {
    login: async () => {
      console.warn(
        'keycloakAuthProvider.login called. It should never be called as authentication is managed before react-admin initialisation.'
      );
    },

    checkError: async (error: any) => {
      console.error('keycloakAuthProvider.checkError', error);
      return Promise.resolve();
    },

    checkAuth: async () => {
      return new Promise<void>((resolve, reject) => {
        if (keycloak.authenticated) {
          resolve();
        } else {
          reject();
        }
      });
    },

    logout: async () => {
      console.log('keycloakAuthProvider.logout called');
      return keycloak.logout();
    },

    getIdentity: async () => {
      await keycloak.loadUserInfo();
      const info: any = keycloak.userInfo;
      const profile = await keycloak.loadUserProfile();
      let output = {
        id: info.sub || profile.id || undefined,
        fullName: info.name || profile.username || info.preferred_username,
        avatar: undefined,
        ...info,
        profile: { ...profile },
      };
      sessionStorage.setItem('identity', JSON.stringify(output));
      return output;
    },

    getPermissions: (roles: any[]) => {
      let objPermissions: any = {};
      if (Array.isArray(roles)) {
        roles.forEach((role) => {
          objPermissions[role] = keycloak.hasResourceRole(role);
        });
      } else {
        const hasRole = (role: string): boolean => {
          return keycloak.hasRealmRole(role) || keycloak.hasResourceRole(role);
        };
        return Promise.resolve(hasRole);
      }
      return Promise.resolve(objPermissions);
    },

    /* Custom functions */

    getToken: () => {
      return keycloak.token;
    },

    getRoles: () => {
      return keycloak.tokenParsed;
    },

    refreshToken: () => {
      return keycloak.updateToken(keycloak?.tokenParsed?.exp ?? 10).then(() => {
        return keycloak.token;
      });
    },
  };

  return provider;
}

export const keycloak = Keycloak(config.authConfig.Keycloak);

const authProvider = KeycloakAuthProvider(keycloak);

export default authProvider;
