import React from 'react';
import { config } from '@config/config';
import { GenericFilterView } from './GenericFilterView';

export const NonLeafResellerView = (
  component: React.ReactElement | React.ReactElement[] | false
): React.ReactElement | React.ReactElement[] | false => {
  return GenericFilterView({
    filter: () => !config.isLeafReseller,
    component,
  });
};
