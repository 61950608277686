import {
  Create,
  required,
  ResourceComponentProps,
  SimpleForm,
  TextInput,
  useTranslate,
  Toolbar,
  SaveButton,
  ToolbarProps,
  AutocompleteInput,
  ReferenceInput,
} from 'react-admin';

const PostCreateToolbar = (props: ToolbarProps) => {
  const handleTransform = (data: any) => {
   
    return data
  };
  return (
    <Toolbar {...props}>
      <SaveButton transform={handleTransform} />
    </Toolbar>
  );
};

export const BspCreate = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.uvam';

  return (
    <Create {...props}>
      <SimpleForm
        redirect="show"
        variant="standard"
        toolbar={<PostCreateToolbar />}
      >
        <TextInput
          label={translate(`${tFP}.fields.name`)}
          source="name"
          validate={required()}
        />
        <TextInput
          source="eic"
          validate={required()}
        />
        <TextInput
          source="tenantId"
          validate={required()}
        />
        <TextInput
          source="orgClientId"
          validate={required()}
        />
        <TextInput
          source="orgClientSecret"
          type={"password"}
          validate={required()}
        />
        <TextInput
          source="equigyUsername"
          validate={required()}
        />
        <TextInput
          source="equigyPassword"
          type={"password"}
          validate={required()}
        />
        <TextInput
          source="serviceAccountUsername"
          validate={required()}
        />
        <TextInput
          source="serviceAccountPassword"
          type={"password"}
          validate={required()}
        />

        <ReferenceInput label="Company" source="companyId" filterToQuery={(q) => ({businessName: `%${q}`})}  reference="companies">
            <AutocompleteInput optionText="businessName" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
