import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import get from 'lodash/get';
import { memo } from 'react';
import {
  sanitizeFieldRestProps,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { CustomProgressField } from '../../lib/react-admin_lib/custom-progress-field';
const useStyles = makeStyles((theme) => ({
  warning: {
    color: theme.palette.warning.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  default: {
    color: theme.palette.secondary.main,
  },
}));

export const CustomStatusOptionsField = memo<any>((props: any) => {
  const {
    className,
    classes: classesOverride,
    source,
    statusOptions,
    emptyText,
    ...rest
  } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const record = useRecordContext(props);
  const value = get(record, source);
  const classNames: any = {
    warning: classes.warning,
    error: classes.error,
    success: classes.success,
    default: classes.default,
  };
  const [label, type, Icon, disabled, onClick] =
    statusOptions[record[source]] ?? statusOptions['invalid-template'];
  const handleIconButtonClick = () => {
    onClick(record);
  };
  if (value == null && emptyText) {
    return (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        {emptyText}
      </Typography>
    );
  }

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title={translate(label)}>
        <span>
          <IconButton
            aria-label="upload picture"
            component="span"
            disabled={disabled}
            size="small"
            onClick={handleIconButtonClick}
          >
            <Icon
              classes={{ root: classNames[type] ?? classNames['default'] }}
            />
          </IconButton>
        </span>
      </Tooltip>
      &nbsp;
      {!disabled && (
        <CustomProgressField
          source="createdAt"
          record={record}
          {...sanitizeFieldRestProps(props)}
        />
      )}
    </Box>
  );
});
