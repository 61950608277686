import { config } from '@config/config';
import { fetchUtils, SortPayload } from 'react-admin';
import authProvider from './authProvider';

import { GetListParams, GetManyReferenceParams } from 'ra-core';
import jsonServerProvider from 'ra-data-json-server';
import { DataProvider } from 'react-admin';

export type SetSortParam = (sort: SortPayload) => { [key: string]: string } | {}

export function mapSpringSortParams(sort: SortPayload) {
    if (!sort.field) {
        return {}
    }

    return {
        sort: `${sort.field},${sort.order ?? 'ASC'}`
    }
} 

function JsonDataProvider(
  apiUrl: string,
  httpClient: typeof fetchUtils.fetchJson,
  setSortParam: SetSortParam = mapSpringSortParams
): DataProvider {
  const provider: any = {
    ...jsonServerProvider(apiUrl, httpClient),
    getList: (resource: string, params: GetListParams) => {
      const { page, perPage } = params.pagination;

      const query = {
        ...fetchUtils.flattenObject(params.filter),
        size: perPage,
        page: page - 1,
        ...setSortParam(params.sort),
      };
      const url = `${apiUrl}/${resource}?${fetchUtils.queryParameters(query)}`;

      return httpClient(url).then(({ headers, json }) => {
        if (!headers.has('x-total-count')) {
          throw new Error(
            'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
          );
        }
        return {
          data: json,
          total: parseInt(
            headers
              .get('x-total-count')!
              .split('/')!
              .pop()!,
            10
          ),
        };
      });
    },

    getManyReference: (resource: string, params: GetManyReferenceParams) => {
      const { page, perPage } = params.pagination;

      const query = {
        ...fetchUtils.flattenObject(params.filter),
        [params.target]: params.id,
        size: perPage,
        page: page - 1,
        ...setSortParam(params.sort),
      };
      const url = `${apiUrl}/${resource}?${fetchUtils.queryParameters(query)}`;

      return httpClient(url).then(({ headers, json }) => {
        if (!headers.has('x-total-count')) {
          throw new Error(
            'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
          );
        }
        return {
          data: json,
          total: parseInt(
            headers
              .get('x-total-count')!
              .split('/')!
              .pop()!,
            10
          ),
        };
      });
    },
  };

  return provider;
}


export const httpClient = async (apiUrl: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json', 'Access-Control-Allow-Origin': '*' });
  }
  const token = await authProvider.getToken();
  options.headers.set('Authorization', `Bearer ${token}`);

  return fetchUtils.fetchJson(apiUrl, options);
};

const dataProvider = JsonDataProvider(config.dataConfig.api, httpClient);

export default dataProvider;
