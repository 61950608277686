import React from 'react';
import _ from 'lodash';

export type TPlantGraphResponse = {
  plantId: number;
  plantName: string;
  data: {
    a: number | null | undefined;
    f: number | null | undefined;
    m: number | null | undefined;
    dt: string | null | undefined;
  }[];
}[];

const plantGraphInitialData = (
  response?: TPlantGraphResponse,
  selectedPlants?: string[]
) => {
  const aggregated =
    selectedPlants &&
    response
      ?.filter(({ plantName }) => selectedPlants.includes(plantName))
      .map((plant) => plant.data);

  const isNil = (v: number | null | undefined | string | {}): v is null | undefined  =>
    v === null || v === undefined;

  const zipped =
    aggregated &&
    _.zip(...aggregated).map((t) =>
      t.reduce((p, v) =>
        isNil(v)
          ? { m: null, a: null, f: null, dt: null }
          : {
              m: isNil(p?.m) && isNil(v?.m) ? null : p!.m! + v!.m!,
              a: isNil(p?.a) && isNil(v?.a) ? null : p!.a! + v!.a!,
              f: isNil(p?.f) && isNil(v?.f) ? null : p!.f! + v!.f!,
              dt: isNil(v?.dt) ? null : v!.dt,
            }
      )
    );

  return {
    data: zipped,
  };
};
export const usePlantGraph = (
  response?: TPlantGraphResponse,
  selectedPlants?: string[]
) => {
  const [data, setData] = React.useState<any[] | undefined>();

  React.useEffect(() => {
    const loadUpdatedData = () => {
      const { data } = plantGraphInitialData(response, selectedPlants);
      setData(data);
    };
    loadUpdatedData();
  }, [response, selectedPlants]);

  return { data };
};
