import { Stack } from '@mui/material';
import {
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  ReferenceField,
  ResourceComponentProps,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useTranslate
} from 'react-admin';

const ActionButtons = (props: any) => {
  const { basePath, data, resource } = props;
  const translate = useTranslate();
  const tFP = 'resources.grid-connection-point-custom-contracts';
  return(
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
      {data && typeof data.id !== 'undefined' && ( 
        <DeleteWithConfirmButton 
          basePath={basePath} 
          record={data} 
          resource={resource} 
          confirmContent={translate(`${tFP}.deleteConfirmMessage`)}/>
      )}
    </TopToolbar>
  );
};

export const GridConnectionPointCustomContractShow = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.grid-connection-point-custom-contracts';
  return (
    <Show {...props} actions={<ActionButtons />}>
      <SimpleShowLayout>
        <ReferenceField
          label={translate(`${tFP}.fields.customContract`)}
          source="customContract"
          reference="custom-contracts"
          link="show"
        >
          <Stack spacing={2} direction="row">
            <TextField
              source="id"
              emptyText="-"
              label={translate(`${tFP}.fields.customContract`)}
            />
            <ReferenceField
              source="counterpart"
              reference="companies"
              link={false}
            >
              <TextField
                source="businessName"
                emptyText="-"
              />
            </ReferenceField>
          </Stack>
        </ReferenceField>
        <DateField source="allocationDate" />
        <DateField source="startDate" />
        <DateField source="endDate" />
        <ReferenceField
          label={translate(`${tFP}.fields.gcpId`)}
          source="gridConnectionPoint"
          reference="grid-connection-points"
          link="show"
        >
          <TextField source="meteringPointId" />
        </ReferenceField>
        <ReferenceField
            label={translate(`${tFP}.fields.product`)}
            source="product"
            reference="products"
            link={false}
          >
            <Stack spacing={2} direction="row">
              <TextField
                source="id"
                emptyText="-"
              />
              <TextField
                source="productCode"
                emptyText="-"
              />
              <TextField
                source="type"
                emptyText="-"
              />
            </Stack>
        </ReferenceField>
        <TextField source="powerMw" />
        <TextField source="price" />
        <TextField source="note" />
      </SimpleShowLayout>
    </Show>
  );
};
