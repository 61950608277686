import { resolveBrowserLocale, TranslationMessages } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishDefaultMessages from 'ra-language-english';
const italianDefaultMessages = require('ra-language-italian');

const itMessages = require('../lang/it-messages.json');
const enMessages = require('../lang/en-messages.json');

const messages: { [key: string]: TranslationMessages } = {
  it: { ...italianDefaultMessages, ...itMessages },
  en: { ...englishDefaultMessages, ...enMessages },
};

const availableLocales = ['it', 'en'];

const i18nProvider = polyglotI18nProvider(
  (locale) =>
    availableLocales.includes(locale) ? messages[locale] : messages.en,
  resolveBrowserLocale()
);

export default i18nProvider;
