import { config } from '@config/config';
import React from 'react';
import { Config } from '../../config/types';
import { TAverageIpexResponse } from './useAverageIpexGraph';
import { THourlyIpexResponse } from './useHourlyIpexGraph';
import { useLocalStorage } from './useLocalStorage';

export interface IStore extends Config {
  darkMode: boolean;
  averageIpexGraph?: TAverageIpexResponse;
  hourlyIpexGraph?: THourlyIpexResponse;
}
export const StoreContext = React.createContext({} as any);

export const StoreProvider = (props: any) => {
  const [darkmode] = useLocalStorage('darkmode', false);
  const [averageIpexGraph] = React.useState();
  const [hourlyIpexGraph] = React.useState();
  const initialStore = {
    ...config,
    darkMode: darkmode,
    averageIpexGraph,
    hourlyIpexGraph,
  } as IStore;
  const [store, storeSet] = React.useState(initialStore);

  const contextValue = React.useMemo(() => {
    return [store, storeSet];
  }, [store, storeSet]);

  return (
    <StoreContext.Provider value={contextValue}>
      {props.children}
    </StoreContext.Provider>
  );
};
