import React from 'react';
import { TextField as MuiTextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslate } from 'react-admin';
interface ITextFieldValidation {
  label: string;
  name: string;
  type?: string;
  onChange: ({ value, error }: { value: string; error: boolean }) => void;
  requried?: boolean;
  valid?: string;
}
export const TextFieldValdation = (props: ITextFieldValidation) => {
  const { label, name, type, requried, onChange } = props;
  const translate = useTranslate();
  const tFP = 'resources.validations';
  const validationSchema = yup.object({
    [name]:
      type === 'email' && requried
        ? yup
            .string()
            .email(translate(`${tFP}.valid`))
            .required(translate(`${tFP}.requried`))
        : yup.string(),
  });
  const formik = useFormik({
    initialValues: {
      [name]: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {},
  });
  React.useEffect(() => {
    onChange({
      value: formik.values[name],
      error: formik.touched[name] ? Boolean(formik.errors[name]) : false,
    });
  }, [formik.values, formik.touched, formik.errors, onChange, name]);

  return (
    <MuiTextField
      size="small"
      label={label}
      id={name}
      name={name}
      type={type}
      inputProps={{ style: { textTransform: 'lowercase' } }}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values[name]}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};
