import { Box } from '@material-ui/core';
import { DeleteButton, EditButton, Record, ShowButton } from 'react-admin';
import { EditableStatus } from '../../resources/grid-connection-points/status-management-components/EditableStatusView';

export const ShowEditButtons = ({
  basePath,
  record,
  editableStatus,
  remove,
}: {
  basePath?: string;
  record?: Record;
  editableStatus?: EditableStatus[];
  remove?: boolean;
}) => {
  return (
    <Box display="flex" justifyContent="right">
      {editableStatus?.includes(record!.currentStatus) && (
        <EditButton basePath={basePath} record={record} />
      )}
      <ShowButton basePath={basePath} record={record} />
      {editableStatus?.includes(record!.currentStatus) && remove && (
        <DeleteButton basePath={basePath} record={record} />
      )}
    </Box>
  );
};
