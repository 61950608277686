import { Box } from '@material-ui/core';
import { DeleteWithConfirmButton, EditButton, Record, useTranslate } from 'react-admin';

export const EditDeleteButtons = ({
  basePath,
  record,
  remove,
}: {
  basePath?: string;
  record?: Record;
  remove?: boolean;
}) => {
  const translate = useTranslate();
  return (
    <Box display="flex" justifyContent="right">
        <EditButton basePath={basePath} record={record} />
      {remove && (
        <DeleteWithConfirmButton basePath={basePath} record={record} confirmContent={translate('deleteConfirmMessage')}/>
      )}
    </Box>
  );
};
