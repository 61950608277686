import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useInput, useTranslate } from 'react-admin';

interface IRadioSelectFieldProps {
  label: string;
  source: string;
  choices: { value: string; label: string }[];
  validate?: any;
}

export const RadioSelectField = (props: IRadioSelectFieldProps) => {
  const {
    input,
    meta: { touched, error },
  } = useInput(props);
  const translate = useTranslate();
  return (
    <Box pt={2}>
      <FormControl component="fieldset" error={!!(touched && error)}>
        <FormLabel component="legend">{props.label}</FormLabel>
        <RadioGroup aria-label="gender" {...input}>
          {props.choices.map((radio) => {
            return (
              <FormControlLabel
                value={radio.value}
                control={<Radio color="primary" />}
                label={radio.label}
              />
            );
          })}
          <FormHelperText>{translate(touched && error)}</FormHelperText>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
