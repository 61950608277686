import { Box } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import ListIcon from '@material-ui/icons/List';
import SaveIcon from '@material-ui/icons/Save';
import { Stack } from '@mui/material';
import React from 'react';
import {
  BooleanField,
  Button,
  DateField,
  Labeled,
  NumberInput,
  ReferenceField,
  ReferenceManyField,
  ResourceComponentProps,
  SimpleShowLayout,
  SingleFieldList,
  useRecordContext,
  useTranslate
} from 'react-admin';
import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { TextFieldsCard } from 'src/lib/mui_lib/text-fields-card';
import { LabeledField } from 'src/lib/react-admin_lib/labeled-field';
import dataProvider from 'src/providers/dataProvider';

export const BaselineManagement = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const uvam = useRecordContext();
  const [ editable, setEditable ] = React.useState(false);
  const [ edited, setEdited ] = React.useState(false);
  const tFP = 'resources.uvam.tabs.baselineManagement.fields';
  const filter = uvam?.id ? JSON.stringify({ uvam: uvam.id }) : '';
  const handleSubmit = async (values: any) => {
    await dataProvider.update('uvam', {
      id: uvam.id,
      data: { ...uvam, exergyId: values.exergyId },
      previousData: uvam
    });
    setEditable(false);
    setEdited(false);
  };
  return (
    <Box mt="2" mb={2}>
      <SimpleShowLayout>
        <Stack spacing={1}>
          {/* Exergt Id */}
          <Stack spacing={1} direction="row" alignItems="start">
            <Form 
              redirect="show"
              onSubmit={(values) => {
                handleSubmit(values);
              }} 
              initialValues={{ exergyId: uvam?.exergyId }}>
                {({ handleSubmit }) => (
                  <>
                    <TextFieldsCard title={translate(`${tFP}.exergy`)}>
                      <NumberInput
                        source="exergyId"
                        emptyText="-"
                        label={translate(`${tFP}.exergyId`)}
                        onChange={() => setEdited(true)}
                        disabled={!editable}
                      />
                    </TextFieldsCard>
                    <Button 
                      onClick={() => setEditable(true)}
                      label={translate('resources.uvam.edit')}
                      startIcon={<EditIcon />}
                      size="small"
                      variant="text"
                    />
                    <Button
                      onClick={handleSubmit}
                      label={translate('resources.uvam.save')}
                      startIcon={<SaveIcon />}
                      size="small"
                      variant="text"
                      disabled={!edited}
                    />
                  </>
                )}
            </Form>
          </Stack>
          {/* Activation status */}
          <Stack spacing={1} direction="row" alignItems="start">
            <TextFieldsCard title={translate(`${tFP}.activationStatus`)}>
              <Labeled
                label={translate(`${tFP}.activeProduct`)}
                fullWidth={true}
              >
                <Stack spacing={1} direction="row">
                  <ReferenceManyField
                      reference="uvam-activation-statuses" 
                      target="uvam"
                      filter={{ active: true }}  
                  >
                    <SingleFieldList linkType={'show'}>
                        <ReferenceField
                          source="product"
                          reference="products"
                          link={false}
                        >
                          <Stack spacing={1} direction="row">
                                <LabeledField
                                  source="productCode"
                                  emptyText="-"
                                  label={translate(`${tFP}.productCode`)}
                                />
                                <LabeledField
                                  source="type"
                                  emptyText="-"
                                  label={translate(`${tFP}.productType`)}
                                />
                          </Stack>
                        </ReferenceField>
                    </SingleFieldList>
                  </ReferenceManyField>
                  <ReferenceManyField
                    reference="uvam-activation-statuses" 
                    target="uvam"
                    filter={{ active: true }}   
                  >
                    <SingleFieldList linkType={'show'}>
                      <Stack spacing={1} direction="row">
                        <Box display="flex" width="190px">
                          <Labeled label={translate(`${tFP}.startDate`)}>
                            <DateField
                              source="startDate"
                              emptyText="-"
                            />
                          </Labeled>
                        </Box>
                        <Box display="flex" width="190px">
                          <Labeled label={translate(`${tFP}.endDate`)}>
                            <DateField
                              source="endDate"
                              emptyText="-"
                            />
                          </Labeled>
                        </Box>
                        <Box display="flex" width="190px">
                          <Labeled label={translate(`${tFP}.active`)}>
                            <BooleanField source="active" />
                          </Labeled>
                        </Box>
                      </Stack>
                    </SingleFieldList>
                  </ReferenceManyField>
                </Stack>
              </Labeled>
            </TextFieldsCard>
            <Button
              color="primary"
              component={Link}
              to={{
                pathname: '/uvam-activation-statuses',
                search: `filter=${filter}`,
              }}
              endIcon={<ListIcon />}
              size="small"
              variant="text"
              label={translate(`${tFP}.showAll`)}
            ></Button>
          </Stack>

          {/* Force availability */}
          <Stack spacing={1} direction="row" alignItems="start">
            <TextFieldsCard title={translate(`${tFP}.forceAvailability`)}>
              <Labeled
                label={translate(`${tFP}.forcedActiveProduct`)}
                fullWidth={true}
              >
                <Stack spacing={1} direction="row">
                  <ReferenceManyField
                      reference="uvam-force-availabilities" 
                      target="uvam"
                      filter={{ active: true }}   
                  >
                    <SingleFieldList linkType={'show'}>
                      <ReferenceField
                        source="product"
                        reference="products"
                        link={false}
                      >
                        <Stack spacing={1} direction="row">
                          <LabeledField
                            source="productCode"
                            emptyText="-"
                            label={translate(`${tFP}.productCode`)}
                          />
                          
                          <LabeledField
                            source="type"
                            emptyText="-"
                            label={translate(`${tFP}.productType`)}
                          />
                        </Stack>
                      </ReferenceField>
                    </SingleFieldList>
                  </ReferenceManyField>
                  <ReferenceManyField
                    reference="uvam-force-availabilities" 
                    target="uvam"
                    filter={{ active: true }}   
                  >
                    <SingleFieldList linkType={'show'}>
                      <Stack spacing={1} direction="row">
                        <Box display="flex" width="190px">
                          <Labeled label={translate(`${tFP}.startDate`)}>
                            <DateField
                              source="startDate"
                              emptyText="-"
                            />
                          </Labeled>
                        </Box>
                        <Box display="flex" width="190px">
                          <Labeled label={translate(`${tFP}.endDate`)}>
                            <DateField
                              source="endDate"
                              emptyText="-"
                            />
                          </Labeled>
                        </Box>
                        <Box display="flex" width="190px">
                          <Labeled label={translate(`${tFP}.availability`)}>
                            <BooleanField source="availability" />
                          </Labeled>
                        </Box>
                      </Stack>
                    </SingleFieldList>
                  </ReferenceManyField>                  
                </Stack>
              </Labeled>
            </TextFieldsCard>
            <Button
                  color="primary"
                  component={Link}
                  to={{
                    pathname: '/uvam-force-availabilities',
                    search: `filter=${filter}`,
                  }}
                  endIcon={<ListIcon />}
                  size="small"
                  variant="text"
                  label={translate(`${tFP}.showAll`)}
            ></Button>
          </Stack>
        </Stack>
      </SimpleShowLayout>
    </Box>
  );
};
