import MomentUtils from '@date-io/moment';
import { Button, Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { format } from 'date-fns';
import React, { Fragment } from 'react';
import { Loading, Title, useNotify, useTranslate } from 'react-admin';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import GetAppIcon from '@material-ui/icons/GetApp';
import fileDownload from 'js-file-download';
import { useDarkTheme } from '../lib/custom-hooks/store-dark-theme';
import {
    Market,
    TimeSlotGroup,
    useAverageIpexGraph,
    Zone,
} from '../lib/custom-hooks/useAverageIpexGraph';
import { useAxios } from '../lib/custom-hooks/useAxios';
import { useStoreGraphs } from '../lib/custom-hooks/useStoreGraphs';
import { useWindowSize } from '../lib/custom-hooks/useWindowSize';
import { SelectField } from '../lib/mui_lib/select';

const initialTimeSlot = TimeSlotGroup.Baseload;
const initialMarket = Market.Mgp;
const initialZone = Zone.Pun;

export const AverageIpexGraph = () => {
  const { toggleDark } = useDarkTheme();
  const { averageIpexGraph, setAverageIpexGraphStore } = useStoreGraphs();
  const [loading, setLoading] = React.useState(false);
  const [xlsDownloadLoading, setXlsDownloadLoading] = React.useState(false);
  const { refetch } = useAxios();
  const { height } = useWindowSize();
  const notify = useNotify();

  const {
    data,
    minDate,
    maxDate,
    startDate,
    endDate,
    handleIpexAverageDateInitialChange,
    handleIpexAverageDateFinalChange,
    ipexAveragetimeSlotsList,
    ipexAveragecurrentTimeSlot,
    ipexAveragezoneList,
    ipexAveragecurrentZone,
    ipexAveragemarketList,
    ipexAveragecurrentMarket,
    handleIpexAverageZoneChange,
    handleIpexAverageCurrentTimeSlotChange,
    handleIpexAverageCurrentMarketChange,
  } = useAverageIpexGraph(
    averageIpexGraph,
    initialTimeSlot,
    initialMarket,
    initialZone
  );
  const translate = useTranslate();
  const tFP = 'resources.reports.average_ipex';
  const keyArray = data && data[0] ? Object.keys(data[0]) : [];
  const stroke = [
    '#ef5350',
    '#78909c',
    '#ab47bc',
    '#7e57c2',
    '#66bb6a',
    '#29b6f6',
    '#bdbdbd',
    '#b39ddb',
  ];
  const [barProps, setBarProps] = React.useState(
    keyArray.reduce(
      (a: any) => {
        a = false;
        return a;
      },
      { hover: null }
    )
  );

  const handleXlsCSVDownload = async (type: 'XLS' | 'CSV') => {
    setXlsDownloadLoading(true);
    const res = await refetch({
      url: `/reports/ipex-syn/download-chart?format=${type}&chartStart=${format(
        startDate,
        'YYYY-MM'
      )}&chartEnd=${format(endDate, 'YYYY-MM')}`,
      method: 'get',
      headers: { contentType: 'application/octet-stream' },
      responseType: 'arraybuffer',
    });
    if (res) {
      const { result, error, loading } = res;
      if (result?.data)
        fileDownload(
          result?.data,
          result?.headers?.['content-disposition'].split('=')[1]
        );
      setXlsDownloadLoading(loading);
      if (error) notify('error', 'error');
    }
  };

  const selectBar = (e: any) => {
    setBarProps({
      ...barProps,
      [e.dataKey]: !barProps[e.dataKey],
      hover: null,
    });
  };

  React.useEffect(() => {
    const getAverageIpexResponse = async () => {
      setLoading(true);
      const res = await refetch({
        url: '/reports/ipex-syn/chart-data',
        method: 'get',
      });
      if (res) {
        const { result, error, loading } = res;
        if (result?.data) setAverageIpexGraphStore(result?.data);
        setLoading(loading);
        if (error) notify('error', 'error');
      }
    };
    getAverageIpexResponse();
  }, [notify, refetch, setAverageIpexGraphStore]);

  if (loading && !averageIpexGraph) return <Loading />;

  return (
    <Box>
      <Title title={translate(`${tFP}.title`)} />
      <CardContent>{translate(`${tFP}.title`)}</CardContent>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Card>
          <Paper elevation={0}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mt={2}
            >
              <ResponsiveContainer
                width="98%"
                height={height > 1000 ? height / 2.5 : 350}
              >
                <BarChart
                  width={1000}
                  height={700}
                  data={data}
                  margin={{
                    top: 20,
                    right: 10,
                    left: 0,
                    bottom: 80,
                  }}
                >
                  <Legend
                    height={30}
                    layout="horizontal"
                    verticalAlign="top"
                    align="center"
                    onClick={selectBar}
                    wrapperStyle={{ position: 'relative' }}
                  />
                  );
                  <CartesianGrid
                    strokeDasharray="3 3"
                    stroke={toggleDark ? '#4d4d4d' : '#c6c6c6'}
                  />
                  <XAxis
                    dataKey="yearmonth"
                    tick={
                      toggleDark
                        ? {
                            stroke: '#fff',
                            strokeWidth: 0.5,
                            fontSize: 13,
                            fontWeight: 'lighter',
                          }
                        : {
                            stroke: '#666',
                            strokeWidth: 0.5,
                            fontWeight: 'lighter',
                            fontSize: 13,
                          }
                    }
                  />
                  <YAxis
                    tick={
                      toggleDark
                        ? {
                            stroke: '#fff',
                            strokeWidth: 0.5,
                            fontSize: 13,
                            fontWeight: 'lighter',
                          }
                        : {
                            stroke: '#666',
                            strokeWidth: 0.5,
                            fontWeight: 'lighter',
                            fontSize: 13,
                          }
                    }
                    interval="preserveStartEnd"
                    label={
                      toggleDark
                        ? {
                            stroke: '#fff',
                            fill: '#fff',
                            strokeWidth: 0.5,
                            fontSize: 15,
                            fontWeight: 'lighter',
                            value: 'Euro/MWh',
                            angle: -90,
                            position: 'insideLeft',
                          }
                        : {
                            stroke: '#666',
                            strokeWidth: 0.5,
                            fontWeight: 'lighter',
                            fontSize: 15,
                            value: 'Euro/MWh',
                            angle: -90,
                            position: 'insideLeft',
                          }
                    }
                    height={700}
                  />
                  <Tooltip
                    formatter={(val: number) => val.toFixed(2)}
                    contentStyle={
                      toggleDark
                        ? {
                            color: 'rgba(255, 255, 255, 0.7)',
                            backgroundColor: '#1E272E',
                            borderColor: '#1E272E',
                          }
                        : {
                            backgroundColor: '#F3F3F4',
                            borderColor: '#F3F3F4',
                          }
                    }
                  />
                  {keyArray.map((value: any, index: number) => {
                    if (
                      value !== 'month' &&
                      value !== 'year' &&
                      value !== 'yearmonth'
                    ) {
                      return (
                        <Bar
                          key={index}
                          dataKey={value}
                          fill={stroke[index]}
                          hide={barProps[value] === true}
                        />
                      );
                    } else {
                      return <Fragment key={index} />;
                    }
                  })}
                </BarChart>
              </ResponsiveContainer>

              <Box display="flex" flexDirection="row" mt={8} mb={2}>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  spacing={3}
                >
                  <Grid item xs={6}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box pr={2}>
                        <DatePicker
                          value={startDate}
                          inputVariant="standard"
                          label={translate(`${tFP}.initial_date`)}
                          format="YYYY/MM"
                          minDate={minDate}
                          maxDate={endDate}
                          views={['year', 'month']}
                          onChange={handleIpexAverageDateInitialChange}
                        />
                      </Box>
                      <DatePicker
                        value={endDate}
                        inputVariant="standard"
                        label={translate(`${tFP}.final_date`)}
                        format="yyyy/MM"
                        views={['year', 'month']}
                        minDate={startDate}
                        maxDate={maxDate}
                        onChange={handleIpexAverageDateFinalChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="center"
                      ml={10}
                    >
                      <Box pr={2}>
                        <SelectField
                          list={
                            ipexAveragemarketList ? ipexAveragemarketList : []
                          }
                          labelName={translate(`${tFP}.market`)}
                          value={ipexAveragecurrentMarket}
                          onChange={handleIpexAverageCurrentMarketChange}
                        />
                      </Box>
                      <Box pr={2}>
                        <SelectField
                          list={ipexAveragezoneList ? ipexAveragezoneList : []}
                          labelName={translate(`${tFP}.zone`)}
                          value={ipexAveragecurrentZone}
                          onChange={handleIpexAverageZoneChange}
                        />
                      </Box>
                      <SelectField
                        list={
                          ipexAveragetimeSlotsList
                            ? ipexAveragetimeSlotsList
                            : []
                        }
                        labelName={translate(`${tFP}.timeslot`)}
                        value={ipexAveragecurrentTimeSlot}
                        onChange={handleIpexAverageCurrentTimeSlotChange}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-between"
                mb={5}
              >
                <Typography>{translate(`${tFP}.download_data`)}</Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={1}
                >
                  <Box mr={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<GetAppIcon />}
                      onClick={() => handleXlsCSVDownload('CSV')}
                      disabled={xlsDownloadLoading}
                    >
                      {translate(`${tFP}.download_csv`)}
                    </Button>
                  </Box>

                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<GetAppIcon />}
                    onClick={() => handleXlsCSVDownload('XLS')}
                    disabled={xlsDownloadLoading}
                  >
                    {translate(`${tFP}.download_xls`)}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Card>
      </MuiPickersUtilsProvider>
    </Box>
  );
};
