import React from 'react';
import {
  Edit,
  maxValue,
  minValue,
  number,
  NumberInput,
  required,
  ResourceComponentProps,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useTranslate,
} from 'react-admin';
import { useHistory, useLocation } from 'react-router-dom';
interface LocationState {
  GcpID: number;
}
const UserEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
)
export const GenerationUnitEdit = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.generation-unit';
  const location = useLocation<LocationState>();
  const history = useHistory();
  const GcpID = location?.state?.GcpID;
  const redirect = GcpID ? `/grid-connection-points/${GcpID}/show` : false;

  React.useEffect(() => {
    !GcpID && history.push('/grid-connection-points');
  }, [GcpID, history]);

  return (
    <Edit {...props} mutationMode='pessimistic' transform={(data: any) => ({
      ...data,
      gridConnectionPointId: null,
      gridConnectionPoints: null
    })}>
      <SimpleForm
        redirect={redirect}
        toolbar={<UserEditToolbar />}
        variant="standard"
      >
        <TextInput
          label={translate(`${tFP}.fields.resourceId`)}
          source="resourceId"
          validate={required()}
          disabled
        />
        <TextInput
          label={translate(`${tFP}.fields.resourceName`)}
          source="resourceName"
        />
        <NumberInput
          label={translate(`${tFP}.fields.maximumFlexDown`)}
          source="maximumFlexDown"
          validate={[required(), number(), maxValue(0)]}
        />
        <NumberInput
          label={translate(`${tFP}.fields.maximumFlexUp`)}
          source="maximumFlexUp"
          validate={[required(), number(), minValue(0)]}
        />
      </SimpleForm>
    </Edit>
  );
};
