import { config } from '@config/config';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  TextField as MuiTextField,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import React, { useLayoutEffect } from 'react';
import {
  BulkActionProps,
  ChipField,
  Datagrid,
  FieldProps,
  FunctionField,
  Identifier,
  List,
  Pagination,
  Record,
  ReferenceArrayField,
  ReferenceField,
  ResourceComponentProps,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  useGetIdentity,
  useListContext,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
  useUnselectAll,
} from 'react-admin';
import { useAxios } from '../../lib/custom-hooks/useAxios';
import { useLocalStorage } from '../../lib/custom-hooks/useLocalStorage';
import Modal from '../../lib/mui_lib/modal';
import { SwitchCardViewAction } from '../../lib/react-admin_lib/switch-card-view-actions';
import { httpClient } from '../../providers/dataProvider';

const useStyles = makeStyles((theme) => ({
  chipfieldColor: {
    color: '#ffffff',
  },
  sucessColor: {
    color: theme.palette.success.main,
  },
  errorColor: {
    color: theme.palette.error.main,
  },
  warningColor: {
    color: theme.palette.warning.main,
  },
}));

const tFP = 'resources.contracts';

const BlukAcceptModal = (props: {
  name: string;
  selectedIds?: Identifier[];
}) => {
  const { name, selectedIds } = props;
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const unselectall = useUnselectAll();
  const notify = useNotify();
  const classes = useStyles();
  const [modalOpen, setModalOpen] = React.useState(false);
  const refresh = useRefresh();
  const [loading, setLoading] = React.useState(true);
  const [loadingAccepted, setLoadingAccepted] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [radioValue, setValue] = React.useState('myEmail');
  const { refetch } = useAxios();
  const [result, setResult] = React.useState<{
    ok: number;
    conflict: number;
    noManager: number;
  }>();
  const [state, setState] = React.useState({
    notification: false,
  });

  const selectedIdsString =
    selectedIds && selectedIds.length > 0 && selectedIds?.join('&id=');
  const handleGetBulkAcceptSelected = async () => {
    setModalOpen(true);
    setEmail(identity?.email);
    setLoading(true);
    const res = await refetch({
      url: `/contracts/bulkAccept/preview?id=${selectedIdsString}`,
      method: 'get'
    });
    if (res) {
      const { result, error, loading } = res;
      if (result) {
        setLoading(loading);
        setResult(result.data);
      }
      if (error) {
        setLoading(loading);
        notify('error', 'error');
        setModalOpen(false);
        unselectall('contracts');
        refresh();
      }
    }
  };
  const handleGetBulkAccept = async () => {
    setModalOpen(true);
    setEmail(identity?.email);
    setLoading(true);
    const res = await refetch({
      url: '/contracts/bulkAccept/preview',
      method: 'get'
    });
    if (res) {
      const { result, loading, error } = res;
      setResult(result?.data);
      setLoading(loading);
      if (error) {
        notify('error', 'error');
        setModalOpen(false);
      }
    }
  };
  const handlePostBulkAccept = async () => {
    const formData = new FormData();
    const emailString =
      !!result?.noManager && email && `defaultManager=${email}`;
    const notification = state.notification && `skipNotification`;
    const requriedString = [emailString, notification].join('&');
    setLoadingAccepted(true);
    const res = await refetch({
      url: `/contracts/bulkAccept?${requriedString}`,
      method: 'post',
        headers: {
          'content-type': 'multipart/form-data',
        },
      data: formData
    });
    if (res) {
      const { result, loading, error } = res;
      if (result) {
        setModalOpen(false);
        setLoadingAccepted(loading);
        refresh();
        notify(
          `${tFP}.accept_bulk_contract_popup.contracts_accepted`,
          'success'
        );
      }
      if (error) {
        notify('error', 'error');
        setModalOpen(false);
        setLoadingAccepted(loading);
        refresh();
      }
    }
  };
  const handlePostBulkAcceptSelected = async () => {
    const formData = new FormData();
    const emailString =
      !!result?.noManager && email && `defaultManager=${email}`;
    const notification = state.notification && `skipNotification`;
    const requriedString = [
      `id=${selectedIdsString}`,
      emailString,
      notification,
    ].join('&');
    setLoadingAccepted(true);

    const res = await refetch({
      url: `/contracts/bulkAccept?${requriedString}`,
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: formData
    });
    if (res) {
      const { result, loading, error } = res;
      if (result) {
        setModalOpen(false);
        setLoadingAccepted(loading);
        unselectall('contracts');
        refresh();
        notify(
          `${tFP}.accept_bulk_contract_popup.contracts_accepted`,
          'success'
        );
      }
      if (error) {
        notify('error', 'error');
        setModalOpen(false);
        unselectall('contracts');
        setLoadingAccepted(loading);
        refresh();
      }
    }
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  const handleCheckSkipNotificationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  React.useEffect(() => {
    if (radioValue === 'myEmail') setEmail(identity?.email);
    if (radioValue !== 'myEmail') setEmail('');
  }, [identity, radioValue]);
  return (
    <>
      <Button
        size="small"
        color="primary"
        variant="contained"
        onClick={
          selectedIds && selectedIds.length > 0
            ? handleGetBulkAcceptSelected
            : handleGetBulkAccept
        }
        startIcon={<DoneIcon />}
      >
        {name}
      </Button>
      <Modal
        size="small"
        color="primary"
        withOutButton={true}
        name={name}
        startIcon={<DoneIcon />}
        open={modalOpen}
        setOpen={setModalOpen}
        loading={loading}
      >
        <Box mt={2}>
          {!!result?.ok && (
            <Box display="flex" flexDirection="row">
              <Typography className={classes.sucessColor}>
                {result.ok}&nbsp;
              </Typography>
              <Typography>
                {translate(
                  `${tFP}.accept_bulk_contract_popup.contracts_can_accepted`,
                  result.ok
                )}
              </Typography>
            </Box>
          )}
          {!!result?.conflict && (
            <Box display="flex" flexDirection="row">
              <Typography className={classes.errorColor}>
                {result.conflict}&nbsp;
              </Typography>
              <Typography>
                {translate(
                  `${tFP}.accept_bulk_contract_popup.contracts_conflicts`,
                  result.conflict
                )}
              </Typography>
            </Box>
          )}
          {!!result?.noManager && (
            <>
              <Box mt={2} mb={2}>
                <Divider />
              </Box>

              <Box display="flex" flexDirection="row">
                <Typography className={classes.warningColor}>
                  {result.noManager}&nbsp;
                </Typography>
                <Typography>
                  {translate(
                    `${tFP}.accept_bulk_contract_popup.contracts_no_default_manager`,
                    result.noManager
                  )}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row">
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={radioValue}
                  onChange={handleRadioChange}
                  row
                >
                  <FormControlLabel
                    value="skip"
                    control={<Radio color="primary" />}
                    label={translate(`${tFP}.accept_bulk_contract_popup.skip`)}
                  />
                  <FormControlLabel
                    value="myEmail"
                    control={<Radio color="primary" />}
                    label={translate(
                      `${tFP}.accept_bulk_contract_popup.use_my_mail`
                    )}
                  />
                  <FormControlLabel
                    value="anotherEmail"
                    control={<Radio color="primary" />}
                    label={translate(
                      `${tFP}.accept_bulk_contract_popup.use_another_email`
                    )}
                  />
                </RadioGroup>
              </Box>
              {radioValue !== 'skip' && (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="start"
                >
                  <MuiTextField
                    id="standard-number"
                    label="Email"
                    type="email"
                    value={email}
                    disabled={radioValue !== 'anotherEmail'}
                    onChange={handleEmailChange}
                  />

                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        color="primary"
                        checked={state.notification}
                        onChange={handleCheckSkipNotificationChange}
                        name="notification"
                      />
                    }
                    label={translate(
                      `${tFP}.accept_bulk_contract_popup.skip_email_notification`
                    )}
                    labelPlacement="end"
                  />
                </Box>
              )}
            </>
          )}

          <Box mt={2} mb={2}>
            <Divider />
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={
                selectedIds && selectedIds.length > 0
                  ? handlePostBulkAcceptSelected
                  : handlePostBulkAccept
              }
              startIcon={
                loadingAccepted ? <CircularProgress size={15} /> : <DoneIcon />
              }
              disabled={
                (!!result?.ok ||
                  !!result?.conflict ||
                  !!result?.noManager ||
                  !loadingAccepted) &&
                !loadingAccepted
                  ? false
                  : true
              }
            >
              {}
              {name}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const PostBulkActionButtons = (props: BulkActionProps) => {
  const translate = useTranslate();
  return (
    <>
      <BlukAcceptModal
        name={translate(`${tFP}.accept_selected_contracts`)}
        selectedIds={props.selectedIds && props.selectedIds}
      />
    </>
  );
};

const AcceptButtonField = ({ source }: FieldProps) => {
  const record = useRecordContext();
  const [isOtherUser, setIsOtherUser] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleOtherUser = () => {
    setIsOtherUser(true);
  };
  const handlecancel = () => {
    setIsOtherUser(false);
  };
  const handleAccept = async () => {
    setLoading(true);
    httpClient(`${config.dataConfig.api}/contracts/${record.id}/accept`, {
      method: 'POST',
    })
      .then((data) => {
        setLoading(false);
        refresh();
        notify('Contract approved', 'success');
      })
      .catch((error) => {
        setLoading(false);
        notify(`Contractt approval error: ${error.message}`, 'error');
      });
  };
  const handelModalCloseIcon = () => {
    setEmail('');
  };
  const handleOtherUserAccept = () => {
    setLoading(true);
    const emailString = email && `?manager=${email}`;
    httpClient(
      `${config.dataConfig.api}/contracts/${record.id}/accept${
        emailString && emailString
      }`,
      {
        method: 'POST',
      }
    )
      .then(() => {
        setLoading(false);
        refresh();
        notify('Contract approved', 'success');
      })
      .catch((error) => {
        setLoading(false);
        notify(`Contractt approval error: ${error.message}`, 'error');
      });
  };

  return record && source && record[source] === false ? (
    <Box
      display="flex"
      alignSelf="center"
      justifyContent="start"
      alignItems="center"
    >
      <Modal
        size="small"
        color="primary"
        buttonSize="small"
        name={translate(`${tFP}.accept_contract_popup.accept`)}
        startIcon={<DoneIcon />}
        open={modalOpen}
        setOpen={setModalOpen}
        onCloseIcon={handelModalCloseIcon}
      >
        <>
          <Typography variant="h6">
            {translate(`${tFP}.accept_contract_popup.sel_manager`)}
          </Typography>

          {isOtherUser ? (
            <>
              <Box mb={2}>
                <MuiTextField
                  id="standard-number"
                  value={email}
                  onChange={handleEmailChange}
                  label="Email"
                  type="email"
                />
              </Box>
              <Grid container>
                <Grid item xs={6}>
                  <Box
                    display="flex"
                    alignSelf="center"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      onClick={handlecancel}
                      size="small"
                      color="primary"
                    >
                      {translate(`${tFP}.accept_contract_popup.cancel`)}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    display="flex"
                    alignSelf="center"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <Button
                        size="small"
                        color="primary"
                        onClick={handleOtherUserAccept}
                        disabled={!email}
                        startIcon={<DoneIcon />}
                      >
                        {translate(`${tFP}.accept_contract_popup.accept`)}
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Box mt={2} mb={2}>
                <Typography color="textSecondary">
                  {translate(`${tFP}.accept_contract_popup.select_manager_des`)}
                  <b>
                    {' '}
                    {record.salesperson.email ||
                      record.operator.email ||
                      record.administrator.email}
                  </b>
                </Typography>
              </Box>
              <Grid container>
                <Grid item xs={6}>
                  <Box
                    display="flex"
                    alignSelf="center"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      onClick={handleOtherUser}
                    >
                      {translate(`${tFP}.accept_contract_popup.add_other_user`)}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    display="flex"
                    alignSelf="center"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <Button
                        size="small"
                        color="primary"
                        onClick={handleAccept}
                        startIcon={<DoneIcon />}
                        disabled={loading}
                        style={{ margin: 0 }}
                      >
                        {translate(`${tFP}.accept_contract_popup.accept`)}
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </>
      </Modal>
    </Box>
  ) : (
    <></>
  );
};

const ClearfiltersOnFocus = (props: any) => {
  const { children } = props;
  const { setFilters } = useListContext();
  const resetFilter = React.useRef(() => {
    setFilters({}, {}, false);
  });

  useLayoutEffect(() => {
    resetFilter.current();
  }, []);
  return children;
};

export const ContractList = (props: ResourceComponentProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [contractsListView, setContractsListView] = useLocalStorage(
    'contractsListView',
    true
  );
  const contractFilters = [
    <TextInput
      label={translate(`${tFP}.fields.company`)}
      source="company"
      variant="standard"
      alwaysOn
    />,
  ];
  const newContractFilters = [
    <TextInput
      label={translate(`${tFP}.fields.company`)}
      source="company"
      variant="standard"
      alwaysOn
    />,
    <TextInput
      label={translate(`${tFP}.fields.email`)}
      source="email"
      variant="standard"
      alwaysOn
    />,
  ];
  return (
    <Paper elevation={0} style={{ marginTop: 14 }}>
      <TabbedShowLayout>
        <Tab label={translate(`${tFP}.tabs.contracts`)}>
          <List
            {...props}
            title={translate(`${tFP}.tabs.contracts`)}
            filter={{ aligned: true }}
            filters={contractFilters}
            exporter={false}
            bulkActionButtons={false}
          >
            <ClearfiltersOnFocus>
              <Datagrid>
                <ReferenceField
                  reference="companies"
                  source="company"
                  link="show"
                >
                  <ChipField
                    source="businessName"
                    size="small"
                    color="primary"
                    className={classes.chipfieldColor}
                    clickable={true}
                  />
                </ReferenceField>
                <TextField source="startDate" />
                <TextField source="endDate" />
                <ReferenceArrayField
                  reference="plants"
                  source="plants"
                  sort={{ field: 'upCode', order: 'ASC' }}
                >
                  <SingleFieldList linkType="show">
                    <Box>
                      <ChipField
                        source="upCode"
                        size="small"
                        color="primary"
                        clickable={true}
                      />
                    </Box>
                  </SingleFieldList>
                </ReferenceArrayField>
              </Datagrid>
            </ClearfiltersOnFocus>
          </List>
        </Tab>
        <Tab label={translate(`${tFP}.tabs.new_contracts`)}>
          <List
            {...props}
            title={translate(`${tFP}.tabs.new_contracts`)}
            filter={{ aligned: false }}
            filters={newContractFilters}
            component={contractsListView ? undefined : Box}
            bulkActionButtons={<PostBulkActionButtons />}
            actions={
              <SwitchCardViewAction
                viewList={contractsListView}
                setViewList={setContractsListView}
                component={
                  // <Button color="primary">
                  //   {translate(`${tFP}.accept_all_contracts`)}
                  // </Button>
                  <BlukAcceptModal
                    name={translate(`${tFP}.accept_all_contracts`)}
                  />
                }
              />
            }
          >
            <ClearfiltersOnFocus>
              {contractsListView ? (
                <NewContractsListView />
              ) : (
                <NewContractsGridView {...props} />
              )}
            </ClearfiltersOnFocus>
          </List>
        </Tab>
      </TabbedShowLayout>
    </Paper>
  );
};

const NewContractsListView = () => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Datagrid hasBulkActions={true}>
      <ReferenceField reference="companies" source="company" link="show">
        <ChipField
          source="businessName"
          size="small"
          color="primary"
          className={classes.chipfieldColor}
          clickable={true}
        />
      </ReferenceField>
      <FunctionField
        label={translate(`${tFP}.fields.email`)}
        render={(r: Record | undefined) =>
          r!.operator.email || r!.salesperson.email || r!.administrator.email
        }
      />
      <TextField source="startDate" />
      <TextField source="endDate" />
      <ReferenceArrayField
        reference="plants"
        source="plants"
        sort={{ field: 'upCode', order: 'ASC' }}
      >
        <SingleFieldList linkType="show">
          <Box>
            <ChipField
              source="upCode"
              size="small"
              color="primary"
              clickable={true}
            />
          </Box>
        </SingleFieldList>
      </ReferenceArrayField>
      <AcceptButtonField source="aligned" />
    </Datagrid>
  );
};

const NewContractsGridView = (props: ResourceComponentProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <SingleFieldList
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
      linkType={false}
    >
      <>
        <Box m={1}>
          <Card>
            <CardContent>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography color="textSecondary" variant="subtitle1">
                    {translate(`${tFP}.fields.company`)}: &nbsp;
                  </Typography>
                  <Typography color="textPrimary">
                    <ReferenceField
                      reference="companies"
                      source="company"
                      link="show"
                    >
                      <ChipField
                        source="businessName"
                        size="small"
                        color="primary"
                        className={classes.chipfieldColor}
                        clickable={true}
                      />
                    </ReferenceField>
                  </Typography>
                </Box>
                <AcceptButtonField source="aligned" />
              </Box>
              <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography color="textSecondary" variant="subtitle1">
                    {translate(`${tFP}.fields.email`)}: &nbsp;
                  </Typography>
                  <Typography color="textPrimary">
                    {<TextField source="salesperson.email" label="Email" /> || (
                        <TextField source="operator.email" label="Email" />
                      ) || (
                        <TextField source="administrator.email" label="Email" />
                      )}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography color="textSecondary" variant="subtitle1">
                    {translate(`${tFP}.fields.startDate`)}: &nbsp;
                  </Typography>
                  <Typography color="textPrimary">
                    <TextField source="startDate" />
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography color="textSecondary" variant="subtitle1">
                    {translate(`${tFP}.fields.endDate`)}: &nbsp;
                  </Typography>
                  <Typography color="textPrimary">
                    <TextField source="endDate" />
                  </Typography>
                </Box>
              </Box>

              <Typography color="textSecondary" variant="subtitle1">
                {translate(`${tFP}.fields.plants`)}:
              </Typography>
              <ReferenceArrayField
                reference="plants"
                source="plants"
                sort={{ field: 'upCode', order: 'ASC' }}
                addLabel={false}
                perPage={5}
                pagination={<Pagination />}
              >
                <Datagrid rowClick="show">
                  <TextField source="upCode" />
                  <TextField source="sapr" />
                  <TextField source="type" />
                  <TextField label="City" source="address.city.name" />
                </Datagrid>
              </ReferenceArrayField>
            </CardContent>
          </Card>
        </Box>
        <Divider />
      </>
    </SingleFieldList>
  );
};
