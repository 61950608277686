import * as React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Inbox from '@material-ui/icons/Inbox';
import { useTranslate, useResourceContext, useGetResourceLabel } from 'ra-core';

export const Empty = (props: EmptyProps) => {
  const resource = useResourceContext(props);
  const classes = useStyles(props);
  const translate = useTranslate();

  const getResourceLabel = useGetResourceLabel();
  const resourceName = translate(`resources.${resource}.forcedCaseName`, {
    smart_count: 0,
    _: getResourceLabel(resource, 0),
  });

  const emptyMessage = translate('ra.page.empty', { name: resourceName });

  return (
    <>
      <div className={classes.message}>
        <Inbox className={classes.icon} />
        <Typography variant="body1" paragraph>
          {translate(`resources.${resource}.empty`, {
            _: emptyMessage,
          })}
        </Typography>
      </div>
    </>
  );
};

export interface EmptyProps {
  resource?: string;
}

const useStyles = makeStyles(
  (theme) => ({
    message: {
      textAlign: 'center',
      opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
      margin: '0 1em',
      color:
        theme.palette.type === 'light' ? 'inherit' : theme.palette.text.primary,
    },
    icon: {
      width: '1.5em',
      height: '1.5em',
    },
    toolbar: {
      textAlign: 'center',
      marginTop: '2em',
    },
  }),
  { name: 'RaEmpty' }
);
