import React from 'react';
import {
  Edit,
  number,
  NumberInput,
  required,
  ResourceComponentProps,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useTranslate,
} from 'react-admin';
import { useHistory, useLocation } from 'react-router-dom';
interface LocationState {
  UvamId: number;
}
const UserEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);
export const UvamConnectionParametersEdit = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.uvam-connection-parameters';
  const location = useLocation<LocationState>();
  const history = useHistory();
  const UvamId = location?.state?.UvamId;
  const redirect = UvamId
    ? `/uvam/${UvamId}/show/connection-parameters`
    : false;

  React.useEffect(() => {
    !UvamId && history.push('/uvam');
  }, [UvamId, history]);

  return (
    <Edit mutationMode="pessimistic" {...props}>
      <SimpleForm
        redirect={redirect}
        toolbar={<UserEditToolbar />}
        variant="standard"
      >
        <SelectInput
          variant="standard"
          label={translate(`${tFP}.fields.type`)}
          source="type"
          choices={['COMANDI', 'MISURA'].map((direction) => {
            return {
              id: direction,
              name: translate(`${tFP}.type.${direction}`),
            };
          })}
          validate={required()}
          disabled
        />
        <TextInput
          source="ipAddress"
          label={translate(`${tFP}.fields.ipAddress`)}
          validate={required()}
        />
        <NumberInput
          source="fullScaleMin"
          label={translate(`${tFP}.fields.fullScaleMin`)}
          validate={[required(), number()]}
        />
        <NumberInput
          source="fullScaleMax"
          label={translate(`${tFP}.fields.fullScaleMax`)}
          validate={[required(), number()]}
        />
      </SimpleForm>
    </Edit>
  );
};
