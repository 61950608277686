import { makeStyles } from '@material-ui/core';

import {
  ChipField,
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ResourceComponentProps,
  TextField,
} from 'react-admin';

const useStyles = makeStyles({
  chipfieldColor: {
    color: '#ffffff',
  },
  fieldHeaderAlign: {
    textAlign: 'right',
  },
  fieldHeaderAlignLeft: {
    textAlign: 'left',
  },
});

export const BspList = (props: ResourceComponentProps) => {
  const classes = useStyles();

  return (
    <List {...props} exporter={false} bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="name" />
        <TextField source="eic" />
        <TextField source="username" />
        <TextField source="orgClientId" />
        <ReferenceField reference="companies" source="company" linkType="show">
          <ChipField
            source="businessName"
            size="small"
            color="primary"
            className={classes.chipfieldColor}
            clickable={true}
          />
        </ReferenceField>
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
};
