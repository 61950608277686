import React from 'react';
import { StoreContext, IStore } from './store';
import { TAverageIpexResponse } from './useAverageIpexGraph';
import { THourlyIpexResponse } from './useHourlyIpexGraph';

export const useStoreGraphs = () => {
  const [store, storeSet] = React.useContext(StoreContext);
  const [averageIpexGraph, setAverageIpexGraph] = React.useState(
    store.averageIpexGraph
  );
  const [hourlyIpexGraph, setHourlyIpexGraph] = React.useState(
    store.hourlyIpexGraph
  );

  const setAverageIpexGraphStore = React.useCallback(
    (response: TAverageIpexResponse) => {
      setAverageIpexGraph(response);
      storeSet((prevState: IStore) => ({
        ...prevState,
        averageIpexGraph: response,
      }));
    },
    [storeSet]
  );
  const setHourlyIpexGraphStore = React.useCallback(
    (res: THourlyIpexResponse) => {
      setHourlyIpexGraph(res);
      storeSet((prevState: IStore) => ({
        ...prevState,
        hourlyIpexGraph: res,
      }));
    },
    [storeSet]
  );

  return {
    averageIpexGraph,
    setAverageIpexGraphStore,
    hourlyIpexGraph,
    setHourlyIpexGraphStore,
  };
};
