import { config } from '@config/config';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import React from 'react';
import authProvider from '../../providers/authProvider';

interface FetchState {
  loading: boolean;
  error: unknown;
  result: AxiosResponse;
  config?: AxiosRequestConfig;
}

interface useFetchReturn {
  loading: boolean;
  error: unknown;
  result: AxiosResponse;
  refetch: (
    config: AxiosRequestConfig
  ) => Promise<
    | { result: AxiosResponse; error: string; loading: boolean }
    | { result: AxiosResponse | undefined; error: unknown; loading: boolean }
    | undefined
  >;
}
const instance = axios.create({
  baseURL: config.dataConfig.api,
});

export const useAxios = (
  config?: AxiosRequestConfig,
  initState?: unknown
): useFetchReturn => {
  const [state, stateSet] = React.useState({
    loading: true,
    error: '',
    result: initState,
  } as FetchState);

  const handleFetch = React.useCallback(async (config: AxiosRequestConfig) => {
    instance.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${await authProvider.getToken()}`;
    stateSet((prevState: { result: AxiosResponse; error: any }) => {
      return {
        result: prevState.result,
        error: prevState.error,
        loading: true,
      };
    });
    try {
      const result = await instance.request(config);
      stateSet({
        result: result,
        error: '',
        loading: false,
      });
      if (result)
        return {
          result: result,
          error: '',
          loading: false,
        };
    } catch (error: any) {
      stateSet((prevState: { result: AxiosResponse }) => {
        return {
          result: prevState.result,
          error: error.response.data,
          loading: false,
        };
      });
      if (error)
        return {
          result: undefined,
          error: error.response.data,
          loading: false,
        };
    }
  }, []);

  React.useEffect(() => {
    const Fetch = async () => {
      instance.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${await authProvider.getToken()}`;
      stateSet((prevState: { result: AxiosResponse; error: unknown }) => {
        return {
          result: prevState.result,
          error: prevState.error,
          loading: true,
        };
      });
      try {
        const result = await instance.request(config || {});
        stateSet({
          result: result,
          error: '',
          loading: false,
        });
      } catch (error: any) {
        stateSet((prevState: { result: AxiosResponse }) => {
          return {
            result: prevState.result,
            error: error.response.data,
            loading: false,
          };
        });
      }
    };
    if (config) Fetch();
  }, [config]);

  return { ...state, refetch: handleFetch };
};
