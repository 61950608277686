import {
  BooleanField,
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  ReferenceField,
  ResourceComponentProps,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useTranslate
} from 'react-admin';

const ActionButtons = (props: any) => {
  const { basePath, data, resource } = props;
  const translate = useTranslate();
  const tFP = 'resources.uvam-activation-statuses';
  return(
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
      {data && typeof data.id !== 'undefined' && ( 
        <DeleteWithConfirmButton 
          basePath={basePath} 
          record={data} 
          resource={resource} 
          redirect={`/uvam/${data.uvam}/show`}
          confirmContent={translate(`${tFP}.deleteConfirmMessage`)}/>
      )}
    </TopToolbar>
  );
};

export const UvamActivationStatusShow = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.uvam-activation-statuses';
  return (
    <Show {...props} actions={<ActionButtons />}>
      <SimpleShowLayout>
      <ReferenceField
          label={translate(`${tFP}.fields.uvamName`)}
          source="uvam"
          reference="uvam"
          link={false}
        >
          <TextField
            source="name"
            emptyText="-"
            label={translate(`${tFP}.fields.uvamName`)}
          />
        </ReferenceField>
        <ReferenceField
          label={translate(`${tFP}.fields.productCode`)}
          source="product"
          reference="products"
          link={false}
        >
          <TextField
            source="productCode"
            emptyText="-"
            label={translate(`${tFP}.fields.productCode`)}
          />
        </ReferenceField>
        <DateField source="startDate" />
        <DateField source="endDate" />
        <BooleanField source="active" />
      </SimpleShowLayout>
    </Show>
  );
};
