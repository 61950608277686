import { Box } from '@material-ui/core';
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  ReferenceField,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { GenericFilterComponent } from '../../../lib/custom-useful-components/GenericFilterComponent';
import { Empty } from '../../../lib/mui_lib/empty';

export const Uvam = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const tFP = 'resources.uvam';
  return (
    <Box mb={2}>
      <GenericFilterComponent
        filter={() => record.uvams.length > 0}
        empty={<Empty resource="Uvam" />}
      >
        <ArrayField source="uvams">
          <Datagrid>
            <ReferenceField
              label={translate(`${tFP}.fields.upCode`)}
              source="uvam"
              reference="uvam"
              link="show"
            >
              <Box>
                <ChipField source="upCode" size="small" color="primary" />
              </Box>
            </ReferenceField>
            <ReferenceField
              label={translate(`${tFP}.fields.name`)}
              source="uvam"
              reference="uvam"
              link={false}
            >
              <TextField
                source="name"
                label={translate(`${tFP}.fields.name`)}
              />
            </ReferenceField>
            <ReferenceField
              label={translate(`${tFP}.fields.exergyId`)}
              source="uvam"
              reference="uvam"
              link={false}
            >
              <TextField
                source="exergyId"
                label={translate(`${tFP}.fields.exergyId`)}
              />
            </ReferenceField>
            <ReferenceField source="uvam" reference="uvam" link={false}>
              <TextField
                source="minimumQualifiedPower"
                label={translate(`${tFP}.fields.minimumQualifiedPower`)}
              />
            </ReferenceField>
            <ReferenceField
              label={translate(`${tFP}.fields.maximumQualifiedPower`)}
              source="uvam"
              reference="uvam"
              link={false}
            >
              <TextField
                source="maximumQualifiedPower"
                label={translate(`${tFP}.fields.maximumQualifiedPower`)}
              />
            </ReferenceField>
            <DateField
              source="startDate"
              label={translate(`${tFP}.fields.startDate`)}
            />
            <DateField
              source="endDate"
              emptyText={translate(`empty`)}
              label={translate(`${tFP}.fields.endDate`)}
            />
          </Datagrid>
        </ArrayField>
      </GenericFilterComponent>
    </Box>
  );
};
