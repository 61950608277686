import {
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  ReferenceField,
  ResourceComponentProps,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useTranslate
} from 'react-admin';

const ActionButtons = (props: any) => {
  const { basePath, data, resource } = props;
  const translate = useTranslate();
  const tFP = 'resources.custom-contracts';
  return(
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
      {data && typeof data.id !== 'undefined' && ( 
        <DeleteWithConfirmButton 
          basePath={basePath} 
          record={data} 
          resource={resource} 
          // redirect={`/grid-connection-points/${data.gridConnectionPoint}/show`}
          confirmContent={translate(`${tFP}.deleteConfirmMessage`)}/>
      )}
    </TopToolbar>
  );
};

export const CustomContractShow = (props: ResourceComponentProps) => {
  const translate = useTranslate();
  const tFP = 'resources.custom-contracts';
  return (
    <Show {...props} actions={<ActionButtons />}>
      <SimpleShowLayout>
        {/* <Grid container spacing={6}> */}
          <TextField source="id" />
          <ReferenceField
              label={translate(`${tFP}.fields.legalEntity`)}
              source="legalEntity"
              reference="companies"
              link={false}
            >
              <TextField
                source="businessName"
                emptyText="-"
                label={translate(`${tFP}.fields.legalEntity`)}
              />
          </ReferenceField>
          <ReferenceField
              label={translate(`${tFP}.fields.counterpart`)}
              source="counterpart"
              reference="companies"
              link={false}
            >
              <TextField
                source="businessName"
                emptyText="-"
                label={translate(`${tFP}.fields.counterpart`)}
              />
          </ReferenceField>
          <DateField source="contractDate" />
          <DateField source="startDate" />
          <DateField source="endDate" />
          <TextField source="mark" />
          <TextField source="paymentId" />
          <TextField source="renewalId" />
          <TextField source="upmLoan" />
          <TextField source="penalty" />
          <TextField source="auctionSupplierFee" />
          <TextField source="spotSupplierFee" />
          <ReferenceField
              label={translate(`${tFP}.fields.mediator`)}
              source="mediator"
              reference="companies"
              link={false}
            >
              <TextField
                source="businessName"
                emptyText="-"
                label={translate(`${tFP}.fields.mediator`)}
              />
          </ReferenceField>
          <TextField source="mediatorFee" />
        {/* </Grid> */}
      </SimpleShowLayout>
    </Show>
  );
};
