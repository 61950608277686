import React from 'react';
import {
  Create,
  number,
  required,
  ResourceComponentProps,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  useTranslate,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { useHistory, useLocation } from 'react-router-dom';
import { TextInput } from '../../lib/react-admin_lib/labeled-field';
interface LocationState {
  UvamId: number;
  type: 'MISURA' | 'COMANDI';
}

const UserEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

const HideFieldComandi = (props: any) => {
  const { values } = useFormState();
  const hide = values.type === 'COMANDI';

  return hide ? <div /> : props.children;
};
export const UvamConnectionParametersCreate = (
  props: ResourceComponentProps
) => {
  const translate = useTranslate();
  const tFP = 'resources.uvam-connection-parameters';
  const location = useLocation<LocationState>();
  const history = useHistory();
  const UvamId = location?.state?.UvamId;
  const type = location?.state?.type;
  console.log('type', type);
  const typeChoices = () => {
    if (type && type === 'MISURA') {
      return ['COMANDI'];
    } else if (type && type === 'COMANDI') {
      return ['MISURA'];
    } else {
      return ['COMANDI', 'MISURA'];
    }
  };
  const redirect = UvamId
    ? `/uvam/${UvamId}/show/connection-parameters`
    : false;

  React.useEffect(() => {
    !UvamId && history.push('/uvam');
  }, [UvamId, history]);

  return (
    <Create {...props}>
      <SimpleForm
        redirect={redirect}
        initialValues={{
          uvamId: UvamId,
          manufacturer: 'RPP',
        }}
        toolbar={<UserEditToolbar />}
        variant="standard"
      >
        <SelectInput
          variant="standard"
          label={translate(`${tFP}.fields.type`)}
          source="type"
          style={{ width: '305px' }}
          choices={typeChoices().map((direction) => {
            return {
              id: direction,
              name: translate(`${tFP}.type.${direction}`),
            };
          })}
          validate={required()}
        />
        <TextInput
          source="ipAddress"
          label={translate(`${tFP}.fields.ipAddress`)}
          validate={required()}
        />
        <HideFieldComandi>
          <TextInput
            source="fullScaleMin"
            label={translate(`${tFP}.fields.fullScaleMin`)}
            validate={[number()]}
          />
        </HideFieldComandi>
        <HideFieldComandi>
          <TextInput
            source="fullScaleMax"
            label={translate(`${tFP}.fields.fullScaleMax`)}
            validate={[number()]}
          />
        </HideFieldComandi>
      </SimpleForm>
    </Create>
  );
};
