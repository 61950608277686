import { useRecordContext } from 'react-admin';
import { GenericFilterComponent } from '../../../lib/custom-useful-components/GenericFilterComponent';

export enum EditableStatus {
  None = 'None',
  Open = 'Open',
  Rejected = 'Rejected',
}

//TODO: this is not user friendly 
export const EditableStatusView = ({
  children,
  editableStatus,
}: {
  children: React.ReactElement;
  editableStatus?: { [key: string]: string }; // why not accepting a an array here
}) => {
  const record = useRecordContext();
  const isEditable = Object.values(
    editableStatus ? editableStatus : EditableStatus
  ).includes(record.currentStatus);
  return (
    <GenericFilterComponent filter={() => isEditable}>
      {children}
    </GenericFilterComponent>
  );
};
