import { Button, ButtonProps, useRecordContext } from 'react-admin';

interface ButtonFieldProps extends ButtonProps {
  onClick: (id: any) => void;
  emptyText?: string;
}
export const ButtonField = (props: ButtonFieldProps) => {
  const record = useRecordContext();
  const { onClick, ...rest } = props;
  const handleOnClick = () => {
    onClick(record);
  };
  return <Button onClick={handleOnClick} {...rest} />;
};
