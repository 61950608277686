import { Grid, Typography } from '@material-ui/core';
import React from 'react';

interface TextFieldCardProps {
  title: string;
  children: React.ReactNode;
  edit?: React.ReactNode;
}

export const TextFieldsCard = (props: TextFieldCardProps) => {
  return (
    <Grid item xs={12} md={6} xl={4}>
      <Typography variant="subtitle1" color="textSecondary">
        {props.title}
      </Typography>

      <Grid container direction="row" spacing={2}>
        {props.children && React.Children.count(props.children) > 0
          ? React.Children.map(props.children, (child: React.ReactNode) => {
              return <Grid item>{child}</Grid>;
            })
          : React.Children.only(props.children)}
      </Grid>
    </Grid>
  );
};
